<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label>Cost Center</label>
      <input type="text" class="form-control" formControlName="cost_center" [readonly]="action === 'Delete'">
    </div>

    <div class="form-group">
      <label>Cost Center Name</label>
      <input type="text" class="form-control" formControlName="cost_center_name" [readonly]="action === 'Delete'">
    </div>

    <div class="form-group">
      <label>BU Head</label>
      <input type="text" class="form-control" formControlName="bu_head" [readonly]="action === 'Delete'">
    </div>

    <div class="form-group">
      <label>BU Head Email</label>
      <input type="text" class="form-control" formControlName="bu_head_email" [readonly]="action === 'Delete'">
    </div>

    <div class="form-group">
      <label>BU Position</label>
      <input type="text" class="form-control" formControlName="bu_position" [readonly]="action === 'Delete'">
    </div>

    <div class="form-group">
      <label>BU Mobile</label>
      <input type="text" class="form-control" formControlName="bu_mobile" [readonly]="action === 'Delete'">
    </div>

    <div class="form-group">
      <label>Valid ID Number</label>
      <input type="text" class="form-control" formControlName="valid_id" [readonly]="action === 'Delete'">
    </div>

    <div class="form-group">
      <label>Location and Date of Expiry</label>
      <input type="text" class="form-control" formControlName="id_details" [readonly]="action === 'Delete'">
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
    <button type="submit" #submitBtn class="btn"
            [ngClass]="{'btn-danger': action === 'Delete', 'btn-warning': action !== 'Delete'}">{{ action }}</button>
  </div>
</form>
