import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { split } from 'lodash';
import { ProfileService } from '../../core/services/profile.service';
import { UserQuery } from '../../core/state/user/user.query';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-upload-cv',
  templateUrl: './upload-cv.component.html',
  styleUrls: ['./upload-cv.component.scss']
})
export class UploadCvComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('customLabel') customLabel: ElementRef;
  @ViewChild('uploadBtn') uploadBtn: ElementRef;
  resume: any;
  cv: string;
  uploadSuccess: boolean;
  resumePdf = true;

  constructor(private renderer: Renderer2,
              private userQuery: UserQuery,
              private profileService: ProfileService) { }

  ngOnInit(): void {
    this.userQuery.select().subscribe($user => {
      this.cv = split($user.account?.profile?.cv_path, '/', 3)[2];
    });
  }

  onFileChange(event): void {
    const fileName = this.fileInput.nativeElement.files[0].name;
    this.renderer.setProperty(this.customLabel.nativeElement, 'innerText', fileName);

    if (event.target.files.length > 0) {
      this.resume = event.target.files[0];
      this.resumePdf = event.target.files[0].type === 'application/pdf';
    }
  }

  prepareSave(): any {
    const input = new FormData();
    input.append('id', this.userQuery.getValue().account.id.toString());
    input.append('cv', this.resume);
    return input;
  }

  uploadFile(): void {
    this.renderer.setProperty(this.uploadBtn.nativeElement, 'disabled', 'true');
    this.profileService.uploadCV(this.prepareSave()).subscribe(
      res => {
        this.renderer.removeAttribute(this.uploadBtn.nativeElement, 'disabled');
        this.uploadSuccess = true;
        setTimeout(() => {this.uploadSuccess = false;}, 2000);
      },
      error => {
        this.renderer.removeAttribute(this.uploadBtn.nativeElement, 'disabled');
      }
    );
  }

}
