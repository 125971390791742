import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CostCentersStore, CostCentersState } from './cost-centers.store';

@Injectable({ providedIn: 'root' })
export class CostCentersQuery extends QueryEntity<CostCentersState> {

  constructor(protected store: CostCentersStore) {
    super(store);
  }

}
