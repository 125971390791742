import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgFormsManager } from '@ngneat/forms-manager';
import { Question } from '../../core/state/questions/question.model';
import { ANS_BOOL, ANS_MULTIPLE, QUESTION_TYPE } from '../../core/constants/Question';
import { Skill } from '../../core/state/skills/skill.model';
import { ProfileService } from '../../core/services/profile.service';
import { SkillsQuery } from '../../core/state/skills/skills.query';
import { QuestionsQuery } from '../../core/state/questions/questions.query';
import { UserService } from '../../core/state/user/user.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  @ViewChild('submitBtn') submitBtn: ElementRef;
  quiz: FormGroup;
  basicQuestions: Question[];
  functionalQuestions: Question[];
  type = QUESTION_TYPE;
  ansBool = ANS_BOOL;
  ansMultiple = ANS_MULTIPLE;
  sets: Skill[];

  constructor(private fb: FormBuilder,
              private formsManager: NgFormsManager,
              private profileService: ProfileService,
              private renderer: Renderer2,
              private route: ActivatedRoute,
              private router: Router,
              private skillsQuery: SkillsQuery,
              private questionsQuery: QuestionsQuery,
              private userService: UserService) { }

  ngOnInit(): void {
    this.quiz = this.fb.group({
      accountId: [null],
      basicAnswers: this.fb.group({
        1: [null, Validators.required],
        2: [null, Validators.required],
        3: [null, Validators.required],
        4: [null, Validators.required],
        5: [null, Validators.required]
      }),
      funcAnswers: this.fb.group({
        setId: [''],
        1: [null],
        2: [null],
        3: [null]
      })
    });

    this.formsManager.upsert('quiz', this.quiz);

    this.basicQuestions = this.questionsQuery.getAll({
      filterBy: entity => entity.skill_id === null
    });

    this.sets = this.skillsQuery.getAll({
      filterBy: entity => entity.enable,
      sortBy: 'name'
    });
  }

  selectSet(): void {
    this.functionalQuestions = this.questionsQuery.getAll({
      filterBy: entity => entity.skill_id === this.quiz.get('funcAnswers').get('setId').value
    });
  }

  submit(): void {
    const userId = this.route.snapshot.paramMap.get('userId');

    this.renderer.setProperty(this.submitBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.submitBtn.nativeElement, 'innerText', 'Submitting...');

    if (userId) {
      this.quiz.patchValue({accountId: userId});
      this.userService.reapply(this.quiz.value).subscribe(
        res => {
          this.router.navigate(['']);
        });

    } else {
      const steps = ['register', 'quiz'];
      const formValue = steps.reduce((acc, step) => {
        return {...acc, ...this.formsManager.getControl(step).value};
      }, {});

      this.userService.register(formValue).subscribe(
        res => {
          const input = new FormData();
          input.append('id', res.account.id.toString());
          input.append('cv', this.userService.getFile());
          this.profileService.uploadCV(input).subscribe();
          this.router.navigate(['']);
        });
    }
  }

}
