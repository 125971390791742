import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReceiptsStore } from './receipts.store';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ReceiptsService {

  constructor(private receiptsStore: ReceiptsStore,
              private http: HttpClient) { }

  get(period): any {
    return this.http.get(`${environment.api}ar/${period}`).pipe(tap(
      (res: any) => {
        this.receiptsStore.set(res.ars);
      }));
  }

  download(id): any {
    return this.http.get(`${environment.api}download/ar/${id}`).pipe(map(
      (res: any) => res.file));
  }

  uploadOR(or): any {
    const header = new HttpHeaders().set('enctype', 'multipart/form-data');
    return this.http.post(`${environment.api}upload/or`, or, {headers: header});
  }

  uploadBirForm(form): any {
    const header = new HttpHeaders().set('enctype', 'multipart/form-data');
    return this.http.post(`${environment.api}upload/bir`, form, {headers: header});
  }

}
