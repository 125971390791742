import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FreelancersStore, FreelancersState } from './freelancers.store';

@Injectable({ providedIn: 'root' })
export class FreelancersQuery extends QueryEntity<FreelancersState> {

  constructor(protected store: FreelancersStore) {
    super(store);
  }

}
