import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { InterviewService } from '../../../core/services/interview.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-decline-modal',
  templateUrl: './decline-modal.component.html',
  styleUrls: ['./decline-modal.component.scss']
})
export class DeclineModalComponent implements OnInit {
  interviewId: number;

  constructor(public bsModalRef: BsModalRef,
              private interviewService: InterviewService) { }

  ngOnInit(): void {
  }

  decline(): void {
    this.interviewService.decline(this.interviewId).subscribe(
      res => {
        this.bsModalRef.hide();
      },
      error => {
        this.bsModalRef.hide();
      }
    );
  }

}
