<ul class="progressbar" *ngIf="user?.reapply_date === null">
  <li class="done">
    NEW TALENT
  </li>
  <li [ngClass]="{'done': user.profile?.ongoing_gigs.length > 0 || user.profile?.past_gigs.length > 0}">
    WORKING ON GIG
  </li>
  <li [ngClass]="{'done': user.profile?.past_gigs.length > 0}">
    FINISHED A GIG
  </li>
</ul>
