<div class="modal-header">
  <h5 class="modal-title">Manual Upload</h5>
  <select [(ngModel)]="milestone" class="ml-2 custom-select w-auto" (ngModelChange)="changeTerm()">
    <option [ngValue]="false">Regular</option>
    <option [ngValue]="true">Milestone</option>
  </select>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="upload()">
  <div class="modal-body">
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <ng-select [items]="freelancers | async"
                     bindLabel="search_string"
                     bindValue="id"
                     formControlName="freelancer_id"
                     placeholder="Select Freelancer">
            <ng-template ng-label-tmp let-item="item">
              {{item.first_name}} {{item.last_name}} | {{item.username}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.first_name}} {{item.last_name}} | {{item.username}}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <ng-select [items]="projects | async"
                     bindLabel="name"
                     bindValue="id"
                     formControlName="project_id"
                     placeholder="Select Gig"
                     (change)="getPosition()"></ng-select>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-4">
        <div class="form-group">
          <input type="text" readonly formControlName="position" class="form-control" placeholder="Position">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text" formControlName="agreed_rate" class="form-control" placeholder="Agreed Rate">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <select formControlName="payment_term" class="custom-select">
            <option [ngValue]="0" *ngIf="!milestone">hourly</option>
            <option [ngValue]="1" *ngIf="!milestone">fixed</option>
            <option [ngValue]="2" *ngIf="milestone">milestone</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <input type="text" placeholder="Select start date" class="form-control" formControlName="start_date"
                 bsDatepicker [bsConfig]="{showWeekNumbers: false}">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <input type="text" placeholder="Select end date" class="form-control" formControlName="end_date"
                 bsDatepicker [bsConfig]="{showWeekNumbers: false}">
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="input-group">
        <div class="custom-file">
          <input #fileInput type="file" class="custom-file-input" id="inputFile"
                 (change)="onFileChange($event)">
          <label #customLabel class="custom-file-label" for="inputFile">Upload Contract</label>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="milestone">
      <label>MILESTONES</label>
      <div formArrayName="milestones" *ngFor="let milestone of m.controls; let i = index;">
        <div [formGroupName]="i">
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" formControlName="milestone" class="form-control" placeholder="Milestone">
              </div>
            </div>
            <div class="col">
              <div class="form-group d-flex">
                <input type="text" formControlName="due" class="form-control flex-grow-1" placeholder="Payment Due">
              </div>
            </div>
            <div class="col">
              <div class="form-group d-flex">
                <input type="text" placeholder="Start Date" class="form-control" formControlName="start"
                       bsDatepicker [bsConfig]="{showWeekNumbers: false}">
              </div>
            </div>
            <div class="col">
              <div class="form-group d-flex">
                <input type="text" placeholder="End Date" class="form-control" formControlName="end"
                       bsDatepicker [bsConfig]="{showWeekNumbers: false}">
                <button type="button" class="btn btn-default delete-btn" (click)="i > 0 ? addMilestone(i) : null">
                  <img src="assets/img/delete.svg" *ngIf="i > 0">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-primary" (click)="addMilestone()">
        Add {{ milestone ? 'Milestone' : 'Activity' }}</button>
    </div>

  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
    <button type="submit" #submitBtn class="btn btn-warning">Upload</button>
  </div>
</form>
