import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../core/state/user/user.model';
import { FreelancersQuery } from '../../core/state/freelancers/freelancers.query';
import { InvoicesService } from '../../core/state/invoices/invoices.service';

export class Freelancer {
  id: number;
  name: string;
  milestone: number;
  milestoneDesc: string;
  start: string;
  end: string;
  due: string;
}

@Component({
  selector: 'app-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.scss']
})
export class MilestoneComponent implements OnInit {
  @ViewChild('generateBtn') generateBtn: ElementRef;
  freelancers: Observable<User[]>;
  payees: Freelancer[] = [];
  id: number;
  freelancer: User;
  milestones: any;
  milestone = 0;
  
  constructor(private freelancersQuery: FreelancersQuery,
              private invoicesService: InvoicesService,
              private renderer: Renderer2) { }
  
  ngOnInit(): void {
    this.freelancers = this.freelancersQuery.selectAll({sortBy: 'username'});
  }
  
  showContract(): void {
    if (this.id) {
      this.freelancer = this.freelancersQuery.getEntity(this.id);
      this.milestones = this.freelancer.profile?.last_contract?.milestone ?
        JSON.parse(this.freelancer.profile?.last_contract?.milestone) : null;
    } else {
      this.freelancer = null;
      this.milestones = null;
    }
  }
  
  add(): void {
    const freelancer = new Freelancer();
    freelancer.id = this.id;
    freelancer.name = `${this.freelancer.first_name} ${this.freelancer.last_name}`;
    freelancer.milestone = this.milestone;
    freelancer.milestoneDesc = this.milestones[this.milestone].milestone;
    freelancer.start = this.milestones[this.milestone].start;
    freelancer.end = this.milestones[this.milestone].end;
    freelancer.due = this.milestones[this.milestone].due;
    this.payees.push(freelancer);
  }
  
  delete(index): void {
    this.payees.splice(index, 1);
  }
  
  generate(): void {
    this.renderer.setProperty(this.generateBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generating...');
    this.invoicesService.generateMilestone({payees: this.payees}).subscribe(res => {
      window.open(res, '_self');
      this.renderer.removeAttribute(this.generateBtn.nativeElement, 'disabled');
      this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generate Invoice(s) and RTP');
    });
  }
  
}
