import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Contract } from '../../../core/state/contracts/contract.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ContractsService } from '../../../core/state/contracts/contracts.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-contract-modal',
  templateUrl: './contract-delete.component.html',
  styleUrls: ['./contract-delete.component.scss']
})
export class ContractDeleteComponent implements OnInit {
  @ViewChild('submitBtn') submitBtn: ElementRef;
  contractToDelete: Contract;

  constructor(public bsModalRef: BsModalRef,
              private contractsService: ContractsService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  delete(): void {
    this.renderer.setProperty(this.submitBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.submitBtn.nativeElement, 'innerText', 'Deleting...');
    this.contractsService.delete(this.contractToDelete.id).subscribe(
      res => { this.bsModalRef.hide(); });
  }

}
