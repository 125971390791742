import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { InvoicesQuery } from '../../core/state/invoices/invoices.query';
import { RtpsService } from '../../core/state/rtps/rtps.service';
import { Invoice } from '../../core/state/invoices/invoice.model';
import { INVOICE_PERIOD } from '../../core/constants/InvoicePeriod';
import { find, pull } from 'lodash';

@Component({
  selector: 'app-generate-rtp',
  templateUrl: './generate-rtp.component.html',
  styleUrls: ['./generate-rtp.component.scss']
})
export class GenerateRtpComponent implements OnInit {
  @ViewChild('generateBtn') generateBtn: ElementRef;
  invoices: Invoice[];
  invoicePeriods = INVOICE_PERIOD;
  years = [new Date().getFullYear() - 1, new Date().getFullYear()];
  year: number;
  month: string;
  invoicesIds = [];
  checkAll = false;

  constructor(private invoicesQuery: InvoicesQuery,
              private rtpsService: RtpsService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.year = this.years[1];
    const pastMonth = new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;
    this.month = this.invoicePeriods[pastMonth].value;
    this.getInvoices();
  }

  getInvoices(): void {
    const monthName = find(this.invoicePeriods, {value: this.month}).text;
    this.invoices = this.invoicesQuery.getAll({
      filterBy: entity => entity.period.includes(`${monthName} ${this.year}`)
    });
  }

  addID(id): void {
    this.invoicesIds.includes(id) ? pull(this.invoicesIds, id) : this.invoicesIds.push(id);
  }

  generate(): void {
    this.renderer.setProperty(this.generateBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generating...');
    
    const month = find(this.invoicePeriods, {value: this.month});
    this.rtpsService.request(this.invoicesIds, month, this.year).subscribe(
      res => {
        window.open(res, '_self');
        this.renderer.removeAttribute(this.generateBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generate RTP');
      });
  }

  selectAll(): void {
    if (this.checkAll) {
      this.invoices.forEach(invoice => {
        if (!this.invoicesIds.includes(invoice.id) && !invoice.paid) { this.invoicesIds.push(invoice.id); }
      });
    } else {
      this.invoicesIds = [];
    }
  }

}
