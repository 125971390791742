import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '../../core/state/user/user.model';
import { ProfileService } from '../../core/services/profile.service';
import { YEARS_OF_EXPERIENCE } from '../../core/constants/Experience';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-dev-account',
  templateUrl: './dev-account.component.html',
  styleUrls: ['./dev-account.component.scss']
})
export class DevAccountComponent implements OnInit {
  @ViewChild('saveBtn') saveBtn: ElementRef;
  @Input() user: User;
  form: FormGroup;
  yearsExp = YEARS_OF_EXPERIENCE;

  constructor(private fb: FormBuilder,
              private renderer: Renderer2,
              private profileService: ProfileService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [this.user.id],
      github: [this.user.profile.github],
      microsoft: [this.user.profile.microsoft],
      linkedin: [this.user.profile.linkedin],
      portfolio: [this.user.profile.portfolio],
      yrsExp: [this.user.profile.yrs_exp],
      hours: [this.user.profile.hours]
    });
  }

  update(): void {
    this.renderer.setProperty(this.saveBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Saving...');

    this.profileService.updateDevAcct(this.form.value).subscribe(
      res => {
        this.renderer.removeAttribute(this.saveBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Save');
      },
      error => {
        this.renderer.removeAttribute(this.saveBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Save');
      }
    );
  }

}
