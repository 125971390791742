import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RtpsStore } from './rtps.store';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class RtpsService {

  constructor(private rtpsStore: RtpsStore,
              private http: HttpClient) {
  }

  get(): any {
    return this.http.get(`${environment.api}rtps`).pipe(tap(
      (res: any) => {
        this.rtpsStore.set(res.rtps.data);
      }));
  }
  
  request($invoices, monthObj, $year): any {
    const params = {
      invoices: $invoices,
      month: monthObj,
      year: $year
    };

    return this.http.post(`${environment.api}request/pay`, params).pipe(tap(
      (res: any) => {
        this.rtpsStore.add(res.rtp);
      }),
      map((res: any) => res.file));
  }

  download(id): any {
    return this.http.get(`${environment.api}download/rtp/${id}`).pipe(map(
      (res: any) => res.file));
  }

  sendToBUHeads(id): any {
    return this.http.get(`${environment.api}send/rtp/bu/${id}`);
  }

}
