import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TalentPoolComponent } from './talent-pool/talent-pool.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InterviewPoolComponent } from './interview-pool/interview-pool.component';
import { AddPlatformComponent } from './gig-pool/add-platform/add-platform.component';
import { ManageQuizComponent } from './manage-quiz/manage-quiz.component';
import { EditQuestionComponent } from './manage-quiz/edit-question/edit-question.component';
import { ProfileLinkComponent } from './profile-link/profile-link.component';
import { GigPoolComponent } from './gig-pool/gig-pool.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GenerateContractComponent } from './generate-contract/generate-contract.component';
import { SettingsComponent } from './settings/settings.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenerateCsvComponent } from './generate-csv/generate-csv.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { PaymentInfoComponent } from './invoices/payment-info/payment-info.component';
import { RtpComponent } from './rtp/rtp.component';
import { GcashComponent } from './gcash/gcash.component';
import { ContractsComponent } from './contracts/contracts.component';
import { CostCentersComponent } from './cost-centers/cost-centers.component';
import { CostCenterModalComponent } from './cost-centers/cost-center-modal/cost-center-modal.component';
import { SignPageComponent } from './generate-contract/sign-page/sign-page.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { ContractDeleteComponent } from './contracts/contract-delete/contract-delete.component';
import { SendRtpModalComponent } from './rtp/send-rtp-modal/send-rtp-modal.component';
import { SignRtpComponent } from './rtp/sign-rtp/sign-rtp.component';
import { UnlinkProjectComponent } from './talent-pool/unlink-project/unlink-project.component';
import { AssignGigComponent } from './gig-pool/assign-gig/assign-gig.component';
import { UploadContractComponent } from './contracts/upload-contract/upload-contract.component';
import { ConfirmContractComponent } from './generate-contract/confirm-contract/confirm-contract.component';
import { FreelancerDataComponent } from './freelancer-data/freelancer-data.component';
import { GenerateRtpComponent } from './generate-rtp/generate-rtp.component';
import { SharedModule } from '../shared/shared.module';
import { OfferGigComponent } from './offer-gig/offer-gig.component';
import { UploadBirComponent } from './receipts/upload-bir/upload-bir.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { MilestoneComponent } from './milestone/milestone.component';

@NgModule({
  declarations: [
    AddPlatformComponent,
    EditQuestionComponent,
    InterviewPoolComponent,
    GigPoolComponent,
    ManageQuizComponent,
    ProfileLinkComponent,
    TalentPoolComponent,
    GenerateContractComponent,
    SettingsComponent,
    GenerateCsvComponent,
    InvoicesComponent,
    PaymentInfoComponent,
    RtpComponent,
    GcashComponent,
    ContractsComponent,
    CostCentersComponent,
    CostCenterModalComponent,
    SignPageComponent,
    ReceiptsComponent,
    ContractDeleteComponent,
    SendRtpModalComponent,
    SignRtpComponent,
    UnlinkProjectComponent,
    AssignGigComponent,
    UploadContractComponent,
    ConfirmContractComponent,
    FreelancerDataComponent,
    GenerateRtpComponent,
    OfferGigComponent,
    UploadBirComponent,
    ContractDetailsComponent,
    MilestoneComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgSelectModule,
    FontAwesomeModule,
    BsDatepickerModule.forRoot(),
    NgxPaginationModule,
    SharedModule,
    AlertModule.forRoot()
  ]
})
export class AdminModule {}
