import { Injectable } from '@angular/core';
import { UserStore } from '../state/user/user.store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient,
              private userStore: UserStore) { }

  updateInfo(user): any {
    return this.http.post(`${environment.api}update/info`, user).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  uploadCV(cv): any {
    const header = new HttpHeaders().set('enctype', 'multipart/form-data');

    return this.http.post(`${environment.api}upload`, cv, {headers: header}).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  changePassword(user): any {
    return this.http.post(`${environment.api}change/password`, user);
  }

  updateDevAcct(user): any {
    return this.http.post(`${environment.api}update/dev`, user).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  updatePayment(user): any {
    return this.http.post(`${environment.api}update/payment`, user).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  deleteUser(account): any {
    return this.http.post(`${environment.api}delete`, account);
  }
  
  downloadCV(id): any {
    return this.http.get(`${environment.api}download/${id}`).pipe(map(
      (res: any) => res.file));
  }

  downloadBirForm(id): any {
    return this.http.get(`${environment.api}download/bir/${id}`).pipe(map(
      (res: any) => res.file));
  }

}
