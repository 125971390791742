import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from '../../core/state/invoices/invoice.model';
import { INVOICE_PERIOD } from '../../core/constants/InvoicePeriod';
import { InvoicesQuery } from '../../core/state/invoices/invoices.query';
import { InvoicesService } from '../../core/state/invoices/invoices.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { Order } from '@datorama/akita';
import { fieldStates, State } from '../../core/constants/FieldState';
import { UserQuery } from '../../core/state/user/user.query';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  @ViewChild('generateBtn') generateBtn: ElementRef;
  bsModalRef: BsModalRef;
  invoices: Observable<Invoice[]>;
  invoicePeriods = INVOICE_PERIOD;
  years = [new Date().getFullYear() - 1, new Date().getFullYear()];
  year: number;
  month: string;
  menus: any;
  state = State;
  p = 1;

  constructor(private invoicesQuery: InvoicesQuery,
              private invoicesService: InvoicesService,
              private modalService: BsModalService,
              private renderer: Renderer2,
              private userQuery: UserQuery) {
    this.menus = fieldStates[this.userQuery.getValue().account.role];
    this.year = this.years[1];
    const pastMonth = new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;
    this.month = this.invoicePeriods[pastMonth].value;
  }

  ngOnInit(): void {
    this.invoices = this.invoicesQuery.selectAll({sortBy: 'created_at', sortByOrder: Order.DESC});
  }

  download(id): void {
    this.invoicesService.download(id).subscribe(res => {
      window.open(res, '_self');
    });
  }

  generateAll(): void {
    this.renderer.setProperty(this.generateBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generating...');

    const monthObj = this.invoicePeriods.find(month => month.value === this.month);
    console.log(
    this.invoicesService.generateAll(monthObj, this.year).subscribe(
      res => {
        window.open(res, '_self');
        this.renderer.removeAttribute(this.generateBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generate Invoice(s)');
      }, error => {
        this.renderer.removeAttribute(this.generateBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generate Invoice(s)');
      }))
  }

  openModal(): void {
    this.bsModalRef = this.modalService.show(PaymentInfoComponent);
  }

  markInvoice(id): void {
    this.invoicesService.markInvoice(id).subscribe();
  } 

}
