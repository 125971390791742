import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Project } from '../../../core/state/projects/project.model';
import { Subject } from 'rxjs';
import { CostCenter } from '../../../core/state/cost-centers/cost-center.model';
import { User } from '../../../core/state/user/user.model';
import { TERM } from '../../../core/constants/Term';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-confirm-contract',
  templateUrl: './confirm-contract.component.html',
  styleUrls: ['./confirm-contract.component.scss']
})
export class ConfirmContractComponent implements OnInit {
  freelancer: User;
  project: Project;
  formValue: any;
  costCenter: CostCenter;
  onClose: Subject<boolean>;
  term = TERM;
  
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  actualSend(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  testDoc(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
