import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { User } from '../../core/state/user/user.model';
import { Project } from '../../core/state/projects/project.model';
import { FreelancersQuery } from '../../core/state/freelancers/freelancers.query';
import { ProjectsQuery } from '../../core/state/projects/projects.query';
import { ContractsService } from '../../core/state/contracts/contracts.service';

@Component({
  selector: 'app-offer-gig',
  templateUrl: './offer-gig.component.html',
  styleUrls: ['./offer-gig.component.scss']
})
export class OfferGigComponent implements OnInit {
  @ViewChild('sendBtn') sendBtn: ElementRef;
  form: FormGroup;
  freelancers: Observable<User[]>;
  projects: Observable<Project[]>;
  services: FormArray;
  deliverables: FormArray;
  activities: FormArray;

  constructor(private contractsService: ContractsService,
              private fb: FormBuilder,
              private freelancersQuery: FreelancersQuery,
              private projectsQuery: ProjectsQuery,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      freelancer_id: [null, Validators.required],
      project_id: [null, Validators.required],
      rate: [null, Validators.required],
      payment_term: [0],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],

      services: this.fb.array([
        this.fb.control('', [Validators.required])
      ]),
      deliverables: this.fb.array([
        this.fb.control('', [Validators.required])
      ]),
      activities: this.fb.array([this.createActivity()])
    });

    this.freelancers = this.freelancersQuery.selectAll({sortBy: 'username'});
    this.projects = this.projectsQuery.selectByLead();
  }

  get s(): any { return this.form.get('services') as FormArray; }

  get d(): any { return this.form.get('deliverables') as FormArray; }

  get a(): any { return this.form.get('activities') as FormArray; }

  createActivity(): FormGroup {
    return this.fb.group({
      milestone: ['', Validators.required],
      date: ['', Validators.required]
    });
  }

  addService(index?): void {
    if (index || index === 0) {
      this.s.removeAt(index);
    } else {
      this.s.push(this.fb.control('', [Validators.required]));
    }
  }

  addDeliverable(index?): void {
    if (index || index === 0) {
      this.d.removeAt(index);
    } else {
      this.d.push(this.fb.control('', [Validators.required]));
    }
  }

  addActivity(index?): void {
    if (index || index === 0) {
      this.a.removeAt(index);
    } else {
      this.a.push(this.createActivity());
    }
  }

  send(): void {
    this.renderer.setProperty(this.sendBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.sendBtn.nativeElement, 'innerText', 'Sending...');

    this.contractsService.sendOffer(this.form.value).subscribe(resp => {
      this.form.reset();
      this.renderer.removeAttribute(this.sendBtn.nativeElement, 'disabled');
      this.renderer.setProperty(this.sendBtn.nativeElement, 'innerText', 'Send Offer Confirmation');
    });
  }

}
