import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Project } from '../../../core/state/projects/project.model';
import { FreelancersQuery } from '../../../core/state/freelancers/freelancers.query';
import { ProjectsQuery } from '../../../core/state/projects/projects.query';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContractsService } from '../../../core/state/contracts/contracts.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User } from '../../../core/state/user/user.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-upload-contract',
  templateUrl: './upload-contract.component.html',
  styleUrls: ['./upload-contract.component.scss']
})
export class UploadContractComponent implements OnInit {
  @ViewChild('submitBtn') submitBtn: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('customLabel') customLabel: ElementRef;
  form: FormGroup;
  freelancers: Observable<User[]>;
  projects: Observable<Project[]>;
  contract: any;
  milestone = false;
  
  constructor(public bsModalRef: BsModalRef,
              private contractsService: ContractsService,
              private fb: FormBuilder,
              private freelancersQuery: FreelancersQuery,
              private projectsQuery: ProjectsQuery,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      milestone: [false],
      freelancer_id: [null, Validators.required],
      project_id: [null, Validators.required],
      position: [''],
      agreed_rate: [null, Validators.required],
      payment_term: [0],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      milestones: this.fb.array([this.createMilestone()]),
    });

    this.freelancers = this.freelancersQuery.selectAll({sortBy: 'username'});
    this.projects = this.projectsQuery.selectByLead();
  }
  
  get m(): any { return this.form.get('milestones') as FormArray; }
  
  onFileChange(event): void {
    const fileName = this.fileInput.nativeElement.files[0].name;
    this.renderer.setProperty(this.customLabel.nativeElement, 'innerText', fileName);

    if (event.target.files.length > 0) {
      this.contract = event.target.files[0];
    }
  }

  getPosition(): void {
    if (this.form.get('project_id').value) {
      const gig = this.projectsQuery.getEntity(this.form.get('project_id').value);
      this.form.patchValue({position: gig.position});
    } else {
      this.form.patchValue({position: ''});
    }
  }
  
  createMilestone(): FormGroup {
    return this.fb.group({
      milestone: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      due: ['']
    });
  }
  
  addMilestone(index?): void {
    if (index || index === 0) {
      this.m.removeAt(index);
    } else {
      this.m.push(this.createMilestone());
    }
  }
  
  changeTerm(): void {
    this.form.patchValue({payment_term: this.milestone ? 2 : 0});
  }
  
  prepareSave(): any {
    const input = new FormData();
    input.append('milestone', this.milestone.toString());
    input.append('freelancer_id', this.form.get('freelancer_id').value);
    input.append('project_id', this.form.get('project_id').value);
    input.append('agreed_rate', this.form.get('agreed_rate').value);
    input.append('payment_term', this.form.get('payment_term').value);
    input.append('start_date', new Date(this.form.get('start_date').value).toISOString());
    input.append('end_date', new Date(this.form.get('end_date').value).toISOString());
    input.append('milestones', JSON.stringify(this.form.get('milestones').value));
    input.append('contract', this.contract);
    return input;
  }

  upload(): void {
    this.renderer.setProperty(this.submitBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.submitBtn.nativeElement, 'innerText', 'Uploading...');
    this.contractsService.upload(this.prepareSave()).subscribe(
      res => { this.bsModalRef.hide(); });
  }

}
