import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InterviewService } from '../../../core/services/interview.service';
import { UserQuery } from '../../../core/state/user/user.query';
import { ProjectsQuery } from '../../../core/state/projects/projects.query';
import { Project } from '../../../core/state/projects/project.model';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { TIMESLOT } from '../../../core/constants/Timeslot';
import { MicrosoftService } from '../../../core/services/microsoft.service';
import { ROLE } from '../../../core/constants/Role';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss']
})
export class ScheduleModalComponent implements OnInit {
  @ViewChild('sendBtn') sendBtn: ElementRef;
  @Output() openModal2 = new EventEmitter<boolean>();
  @Output() closeModal = new EventEmitter<number>();
  @Input() $candidateId: number;
  @Input() $title: string;
  @Input() nested: boolean;
  form: FormGroup;
  title: string;
  candidateId: number;
  isAdmin: boolean;
  time: any;
  projects: Observable<Project[]>;
  minDate = new Date();
  connected: boolean;
  timesToDisplay: any;
  loading: boolean;
  faSpinner = faSpinner;
  displayFreeTime: boolean;

  constructor(public bsModalRef: BsModalRef,
              private microsoftService: MicrosoftService,
              private fb: FormBuilder,
              private modalService: BsModalService,
              private interviewService: InterviewService,
              private projectsQuery: ProjectsQuery,
              private renderer: Renderer2,
              private userQuery: UserQuery) {
    this.connected = this.userQuery.getValue().account.microsoft_connect;
  }

  ngOnInit(): void {
    if (this.nested) {
      this.candidateId = this.$candidateId;
      this.title = this.$title;
    }

    this.form = this.fb.group({
      id: [this.candidateId],
      leadId: [this.userQuery.getValue().account.id],
      date: [null],
      time: [this.isAdmin ? '' : TIMESLOT[0].value],
      projectId: [null]
    });

    this.projects = this.projectsQuery.selectByLead();
    this.timesToDisplay = !this.isAdmin ? TIMESLOT : [];
    this.isAdmin = this.userQuery.getValue().account.role !== ROLE.DEVELOPER;
  }

  changeDate(event): void {
    if (event != null && this.isAdmin) {
      this.time = event;
      this.time.setHours(8);
      this.time.setMinutes(30);
      if (this.connected) { this.getFreeTime(); }
    }
  }

  send(): void {
    this.renderer.setProperty(this.sendBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.sendBtn.nativeElement, 'innerText', 'Sending...');

    if (this.isAdmin) {
      this.interviewService.sendInvite(this.form.value).subscribe(
        res => {
          this.bsModalRef.hide();
          this.close();
        });
    } else {
      this.interviewService.proposeNew(this.form.value).subscribe(
        res => {
          this.bsModalRef.hide();
          this.close();
        });
    }
  }

  createGig(): void {
    this.openModal2.emit(true);
  }

  getFreeTime(): void {
    this.loading = true;
    this.displayFreeTime = false;
    this.timesToDisplay = [];

    this.microsoftService.getFreeTime(this.time.toISOString()).subscribe(
      res => {
        // Check availability
        const view = res[0].availabilityView;
        view.split('').map((a, index) => {
          if ((a === '0' || view[index - 1] === '0') && index > 0) {
            this.timesToDisplay.push(TIMESLOT[index - 1]);
          }
        });

        this.loading = false;
        this.displayFreeTime = true;
        this.form.patchValue({time: this.timesToDisplay[0].value});
      });
  }

  close(): void {
    this.closeModal.emit(1);
  }

}
