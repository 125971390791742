import { Injectable } from '@angular/core';
import { Contract } from './contract.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface ContractsState extends EntityState<Contract> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'contracts' })
export class ContractsStore extends EntityStore<ContractsState> {

  constructor() {
    super();
  }

}

