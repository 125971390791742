<div class="d-flex h-100 justify-content-center align-items-center min-vh-100">
  <div class="mx-auto app-login-box col-md-8">

    <div class="logo text-center">
      <img src="assets/img/logo.png" alt="logo">
    </div>

    <div class="modal-dialog w-100 mx-auto">
      <div class="modal-content">
        <div class="modal-header">
          <div class="h5 modal-title">
            Forgot your Password?
            <h6 class="mt-1 mb-0 opacity-8"><span>Use the form below to recover it.</span></h6></div>
        </div>

        <div class="modal-body">
          <div class="form-group">
            <label>Input your email</label>
            <input email name="email" [(ngModel)]="username" #email="ngModel" class="form-control"
                   placeholder="Email address">
            <small tabindex="-1" *ngIf="emailNoExist" class="form-text text-muted font-weight-bold">
              Email does not exist.
            </small>
          </div>

          <div class="form-group">
            <app-otp [username]="username" [mobile]="null"
                     (verified)="verifyOtp($event)" (otp)="setOtp($event)"
                     (emailNoExist)="verifyEmail($event)"
                     [enable]="email.valid && email.value !== ''"></app-otp>
          </div>

          <div *ngIf="verifiedOtp">
            <app-edit-password [username]="username" [otp]="otp"></app-edit-password>
          </div>

          <div class="divider"></div>
          <h6 class="mb-0">
            <a class="text-primary" routerLink="/login">
              Sign in existing account</a>
          </h6>
        </div>

      </div>
    </div>

  </div>
</div>
