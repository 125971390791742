<div class="modal-header">
  <h5 class="modal-title">Delete Contract</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  Are you sure you want to delete<br>
  <strong>{{ contractToDelete.filename }}</strong> ?
</div>

<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
  <button type="submit" #submitBtn class="btn btn-danger" (click)="delete()">Delete</button>
</div>
