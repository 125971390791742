import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SendRtpModalComponent } from './send-rtp-modal/send-rtp-modal.component';
import { RtpsService } from '../../core/state/rtps/rtps.service';
import { RtpsQuery } from '../../core/state/rtps/rtps.query';
import { Rtp } from '../../core/state/rtps/rtp.model';
import { Order } from '@datorama/akita';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-rtp',
  templateUrl: './rtp.component.html',
  styleUrls: ['./rtp.component.scss']
})
export class RtpComponent implements OnInit {
  bsModalRef: BsModalRef;
  rtps: Observable<Rtp[]>;
  p = 1;

  constructor(private rtpsService: RtpsService,
              private rtpsQuery: RtpsQuery,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.rtps = this.rtpsQuery.selectAll({sortBy: 'created_at', sortByOrder: Order.DESC});
  }

  download(id): void {
    this.rtpsService.download(id).subscribe(res => {
      window.open(res, '_self');
    });
  }

  openModal(rtp): void {
    const initialState = {
      rtpToSend: rtp
    };
    this.bsModalRef = this.modalService.show(SendRtpModalComponent, {initialState});
  }

}
