import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '../../core/state/user/user.model';
import { SkillsQuery } from '../../core/state/skills/skills.query';
import { SkillsService } from '../../core/state/skills/skills.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {
  @ViewChild('saveBtn') saveBtn: ElementRef;
  @Input() user: User;
  form: FormGroup;
  canEdit: boolean;
  skills: any;

  constructor(private fb: FormBuilder,
              private renderer: Renderer2,
              private skillsQuery: SkillsQuery,
              private skillService: SkillsService) { }

  ngOnInit(): void {
    this.skills = this.skillsQuery.getAll({sortBy: 'name'});

    this.form = this.fb.group({
      id: [this.user.id],
      skills: [null]
    });
  }

  edit(): void {
    this.renderer.setProperty(this.saveBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Saving...');

    this.skillService.addSKill(this.form.value).subscribe(
      res => {
        this.renderer.removeAttribute(this.saveBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Add skill');
      },
      error => {
        this.renderer.removeAttribute(this.saveBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Add skill');
      }
    );
  }

  delete(id): void {
    this.skillService.deleteSkill(id).subscribe();
  }

}
