import { Component, OnInit } from '@angular/core';
import { UserQuery } from '../../core/state/user/user.query';
import { BirForm } from '../../core/state/user/user.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProfileService } from '../../core/services/profile.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-my-bir-forms',
  templateUrl: './my-bir-forms.component.html',
  styleUrls: ['./my-bir-forms.component.scss']
})
export class MyBirFormsComponent implements OnInit {
  forms: BirForm[];
  p = 1;

  constructor(private profileService: ProfileService,
              private userQuery: UserQuery) { }

  ngOnInit(): void {
    this.forms = this.userQuery.getValue().account.profile.bir_forms;
  }

  download(id): void {
    this.profileService.downloadBirForm(id).subscribe(res => {
      window.open(res, '_self');
    });
  }

}
