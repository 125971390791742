import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OtpService } from '../../core/services/otp.service';
import { UserService } from '../../core/state/user/user.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  @ViewChild('otpBtn') otpBtn: ElementRef;
  @ViewChild('otpInput') otpInput: ElementRef;
  @ViewChild('timerBtn') timerBtn: ElementRef;
  @Output() verified = new EventEmitter<boolean>();
  @Output() emailNoExist = new EventEmitter<boolean>();
  @Output() otp = new EventEmitter<string>();
  @Input() username: string;
  @Input() mobile: string;
  @Input() enable: boolean;
  form: FormGroup;
  showOtpInput: boolean;
  faSpinner = faSpinner;
  loading: boolean;
  otpMatch: boolean;
  otpMismatch: boolean;
  counter: number;
  timer: Observable<number> = null;

  constructor(private fb: FormBuilder,
              private renderer: Renderer2,
              private otpService: OtpService,
              public router: Router,
              private userService: UserService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      username: [''],
      mobile: [null],
      otp: [null]
    });
  }

  checkEmail(): void {
    this.userService.checkEmail(this.username).subscribe(
      res => {
        this.sendOtp();
        this.emailNoExist.emit(false);
      },
      error => {
        this.emailNoExist.emit(error.status === 404);
      }
    );
  }

  sendOtp(): void {
    this.form.patchValue({username: this.username});
    this.form.patchValue({mobile: this.mobile});
    this.counter = 60;

    this.showOtpInput = true;
    this.otpMatch = false;
    this.otpMismatch = false;

    if (this.mobile !== null) {
      this.otpService.sendMobileOtp(this.form.value).subscribe();
    } else {
      this.otpService.sendEmailOtp(this.form.value).subscribe();
    }

    this.timer = new Observable<number>(observer => {
      const interval = setInterval(() => {
        this.renderer.removeClass(this.timerBtn.nativeElement, 'd-none');
        this.counter--;
        if (this.counter < 1) {
          this.renderer.addClass(this.timerBtn.nativeElement, 'd-none');
          this.renderer.removeAttribute(this.otpBtn.nativeElement, 'disabled');
          this.renderer.setProperty(this.otpBtn.nativeElement, 'innerText', 'Resend One-time Pin');
          clearInterval(interval);
        }
        observer.next(this.counter);
      }, 1000);
    });

    this.renderer.setProperty(this.otpBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.otpBtn.nativeElement, 'innerText', 'One-time Pin Sent. Resend after 60sec.');
  }

  verifyOtp(): void {
    this.loading = true;
    this.otpService.verifyOtp(this.form.value).subscribe(
      res => {
        this.loading = false;
        this.otpMatch = true;
        this.otpMismatch = false;
        this.verified.emit(true);
        this.otp.emit(this.form.value.otp);
      }, error => {
        this.loading = false;
        this.otpMismatch = true;
        this.otpMatch = false;
        this.verified.emit(false);
      });
  }

}
