<div class="container">
  <div class="card main">
    <div class="card-body">

      <div class="d-flex justify-content-between">
        <h5 class="card-title">{{user?.first_name}} {{user?.last_name}}</h5>
        <a [href]="resumeUrl" target="_blank">
          <button class="btn btn-warning">View Resume</button>
        </a>
      </div>

      <p class="card-subtitle" *ngIf="user?.reapply_date === null">
        <span *ngIf="user?.profile?.ongoing_gigs.length === 0 && user?.profile?.past_gigs.length === 0">
          NEW TALENT
        </span>
        <span *ngIf="user?.profile?.ongoing_gigs.length > 0">
          WORKING ON GIG
        </span>
        <span *ngIf="user?.profile?.ongoing_gigs.length === 0 && user?.profile?.past_gigs.length > 0">
          FINISHED A GIG
        </span>
      </p>

      <div class="card mt-4 mb-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <label>Username</label>
              <p>{{user?.username}}</p>
            </div>
            <div class="col-md-6">
              <label>Mobile</label>
              <p>{{user?.profile.mobile}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <label>Years of Experience</label>
              <p>{{experience[user?.profile.yrs_exp]}}</p>
            </div>
            <div class="col-md-6">
              <label>Hours</label>
              <p>{{user?.profile?.hours === 0 ? 'Part time' : (user?.profile.hours === 1 ? 'Full time' : '-')}}</p>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col">
              <label>Remarks</label>
              <p>{{user?.profile?.hours_remarks}}</p>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col">
              <label>Platforms, Frameworks & Programming Languages</label>
              <p>
                <span *ngFor="let skill of user?.profile.skills" [ngClass]="{'font-weight-bold': skill.confirmed}">
                  {{skill.skill_name}}, </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-body">
          <p>
            <label class="w-30">Linkedin</label>
            {{user?.profile.linkedin}}
          </p>
          <p>
            <label class="w-30">Portfolio</label>
            {{user?.profile.portfolio}}
          </p>
          <p>
            <label class="w-30">Github</label>
            {{user?.profile.github}}
          </p>
          <p>
            <label class="w-30">Microsoft Account</label>
            {{user?.profile.microsoft}}
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <p>
            <label class="w-30">TIN</label>
            {{user?.profile.TIN}}
          </p>
          <p>
            <label class="w-30">GCASH</label>
            {{user?.profile.gcash}}
          </p>
          <p>
            <label class="w-30">Bank</label>
            {{user?.profile.bank}}
          </p>
          <p>
            <label class="w-30">Bank account name</label>
            {{user?.profile.bank_acct_name}}
          </p>
          <p>
            <label class="w-30">Bank account number</label>
            {{user?.profile.bank_acct_num}}
          </p>
        </div>
      </div>

    </div>
  </div>
</div>
