import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface UiState {
  nextPageUrl: string;
  lastPage: number;
  sidebarOpen: boolean;
}

export function createInitialState(): UiState {
  return {
    nextPageUrl: null,
    lastPage: null,
    sidebarOpen: false
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'ui'})
export class UiStore extends Store<UiState> {

  constructor() {
    super(createInitialState());
  }

}

