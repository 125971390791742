import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { User } from '../../../core/state/user/user.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FreelancersQuery } from '../../../core/state/freelancers/freelancers.query';
import { ReceiptsService } from '../../../core/state/receipts/receipts.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-upload-bir',
  templateUrl: './upload-bir.component.html',
  styleUrls: ['./upload-bir.component.scss']
})
export class UploadBirComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('customLabel') customLabel: ElementRef;
  @ViewChild('uploadBtn') uploadBtn: ElementRef;
  freelancers: User[];
  id: number;
  from: string;
  to: string;
  birForm: any;
  uploaded: boolean;

  constructor(public bsModalRef: BsModalRef,
              private freelancersQuery: FreelancersQuery,
              private receiptsService: ReceiptsService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.freelancers = this.freelancersQuery.getAll();
  }

  onFileChange(event): void {
    const fileName = this.fileInput.nativeElement.files[0].name;
    this.renderer.setProperty(this.customLabel.nativeElement, 'innerText', fileName);

    if (event.target.files.length > 0) {
      this.birForm = event.target.files[0];
    }
  }

  prepare(): any {
    const input = new FormData();
    input.append('id', this.id.toString());
    input.append('from', new Date(this.from).toISOString());
    input.append('to', new Date(this.to).toISOString());
    input.append('form', this.birForm);
    return input;
  }

  uploadBirForm(): void {
    this.uploaded = false;
    this.renderer.setProperty(this.uploadBtn.nativeElement, 'disabled', 'true');
    this.receiptsService.uploadBirForm(this.prepare()).subscribe(
      res => {
        this.renderer.removeAttribute(this.uploadBtn.nativeElement, 'disabled');
        this.uploaded = true;
      }, error => {
        this.renderer.removeAttribute(this.uploadBtn.nativeElement, 'disabled');
      });
  }

}
