<div class="app-page-title">
  <div>
    <div class="form-group d-inline-block">
      <select [(ngModel)]="year" class="custom-select w-auto mr-2">
        <option *ngFor="let year of years;" value="{{year}}">{{year}}</option>
      </select>

      <select [(ngModel)]="month" class="custom-select w-auto mr-2">
        <option *ngFor="let period of invoicePeriods" value="{{period.value}}">{{period.text}}</option>
      </select>
    </div>

    <div class="form-group d-inline-block">
      <button #generateBtn class="btn btn-warning mr-2" (click)="generateAll()">
        Generate Invoice(s)
      </button>
    </div>
  </div>

  <div class="form-group d-inline-block">
    <button class="btn btn-success" (click)="openModal()">Edit Freelancer Payment Info</button>
  </div>
</div>


<div class="card">
  <div class="card-header">
    Invoices
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col"></th>
        <th>Freelancer</th>
        <th>Period</th>
        <th>Project</th>
        <th>Paid</th>
        <th *ngIf="menus['markInvoices'] === state.VISIBLE">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let invoice of invoices | async | paginate: {itemsPerPage: 10, currentPage: p}">
        <th scope="row">
          <a class="mr-2" (click)="download(invoice.id)">
            <img src="assets/img/download.svg" tooltip="Download"></a>
        </th>
        <td>{{invoice.freelancer}}</td>
        <td>{{invoice.period}}</td>
        <td>{{invoice.project}}</td>
        <td>{{invoice.paid ? 'Yes' : 'No'}}</td>
        <td *ngIf="menus['markInvoices'] === state.VISIBLE">
          <button class="btn action-btn" (click)="markInvoice(invoice.id)"
                  [ngClass]="{'btn-success': !invoice.paid, 'btn-danger': invoice.paid}">
            {{!invoice.paid ? 'Pay' : 'Unpay'}}
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</div>
