export const INTERVIEW_STATUS = {
  INVITED: 0,
  RESCHEDULED: 1,
  CONFIRMED: 2,
  DONE: 3,
  NO_SHOW: 4,
  ENDORSED: 5,
  FAILED: 6,
  DECLINED: 7
};

export const INTERVIEW_STATUS_TEXT = {
  0: 'INVITED',
  1: 'RESCHEDULED',
  2: 'CONFIRMED',
  3: 'DONE',
  4: 'NO SHOW',
  7: 'DECLINED'
};
