<div *ngIf="user?.profile?.interview?.status == interviewStatus.INVITED">
  <div class="mb-2">
    <fa-icon [icon]="faCalendar"></fa-icon>
    You are invited to an interview on
    {{ user.profile.interview?.schedule | date: 'MMM d, y, h:mm a' }}
  </div>
  <div>
    <button #acceptBtn class="btn btn-warning mr-2" (click)="accept()">Accept</button>
    <button class="btn btn-primary mr-2" (click)="openModal()">Propose a new schedule</button>
    <button class="btn btn-danger" (click)="decline()">Decline</button>
  </div>
</div>

<div *ngIf="user?.profile?.interview?.status == interviewStatus.RESCHEDULED">
  <fa-icon [icon]="faCalendar"></fa-icon>
  You proposed a new interview schedule on
  {{ user.profile.interview?.schedule | date: 'MMM d, y, h:mm a' }}
</div>

<div *ngIf="user?.profile?.interview?.status == interviewStatus.CONFIRMED">
  <fa-icon [icon]="faCalendar"></fa-icon>
  Confirmed interview schedule on
  {{ user.profile.interview?.schedule | date: 'MMM d, y, h:mm a' }}
</div>

<div *ngIf="user?.reapply_date != null">
  <div>Reapply after 6 months</div>
  <div class="mb-2">Last Application: {{ user.account_verified_date | date: 'MMM d, y' }}</div>
  <button class="btn btn-warning" [disabled]="!allowed" (click)="reapply()">Reapply
  </button>
</div>
