<div class="app-header">
  <img src="assets/img/logo.png" height="30px">
</div>

<div class="container">
  <form [formGroup]="quiz" (ngSubmit)="submit()">
    <div class="card">
      <div class="card-body">
        <h6>Before you proceed, kindly answer the following questions.</h6>

        <div *ngFor="let question of basicQuestions; let i = index" formGroupName="basicAnswers">
          <div>{{i + 1}}. {{question.question}}</div>
          <div *ngIf="question.type === type.BOOLEAN; else multipleBlock" class="form-group">
            <div class="custom-radio custom-control">
              <input type="radio" id="yes{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                     [value]="ansBool.Yes">
              <label for="yes{{i}}" class="custom-control-label">Yes</label>
            </div>

            <div class="custom-radio custom-control">
              <input type="radio" id="no{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                     [value]="ansBool.No">
              <label for="no{{i}}" class="custom-control-label">No</label>
            </div>
          </div>

          <ng-template #multipleBlock>
            <div class="form-group">
              <div class="custom-radio custom-control">
                <input type="radio" id="a{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                       [value]="ansMultiple.A">
                <label for="a{{i}}" class="custom-control-label">{{question.choices.A}}</label>
              </div>
              <div class="custom-radio custom-control">
                <input type="radio" id="b{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                       [value]="ansMultiple.B">
                <label for="b{{i}}" class="custom-control-label">{{question.choices.B}}</label>
              </div>
              <div class="custom-radio custom-control">
                <input type="radio" id="c{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                       [value]="ansMultiple.C">
                <label for="c{{i}}" class="custom-control-label">{{question.choices.C}}</label>
              </div>
              <div class="custom-radio custom-control">
                <input type="radio" id="d{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                       [value]="ansMultiple.D">
                <label for="d{{i}}" class="custom-control-label">{{question.choices.D}}</label>
              </div>
            </div>
          </ng-template>
        </div>

        <div class="divider"></div>

        <div class="form-group" formGroupName="funcAnswers" *ngIf="sets.length > 0">
          <h6>Functional Quiz (Optional)</h6>
          <select class="custom-select form-group w-auto" formControlName="setId" (change)="selectSet()">
            <option value="" disabled selected>Select</option>
            <option *ngFor="let set of sets" [ngValue]="set.id">{{set.name}}</option>
          </select>

          <div *ngFor="let question of functionalQuestions; let i = index">
            <div>{{i + 1}}. {{question.question}}</div>
            <div *ngIf="question.type === type.BOOLEAN; else multipleBlock" class="form-group">
              <div class="custom-radio custom-control">
                <input type="radio" id="funcYes{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                       [value]="ansBool.Yes">
                <label for="funcYes{{i}}" class="custom-control-label">Yes</label>
              </div>

              <div class="custom-radio custom-control">
                <input type="radio" id="funcNo{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                       [value]="ansBool.No">
                <label for="funcNo{{i}}" class="custom-control-label">No</label>
              </div>
            </div>

            <ng-template #multipleBlock>
              <div class="form-group">
                <div class="custom-radio custom-control">
                  <input type="radio" id="a{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                         [value]="ansMultiple.A">
                  <label for="a{{i}}" class="custom-control-label">{{question.choices.A}}</label>
                </div>
                <div class="custom-radio custom-control">
                  <input type="radio" id="b{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                         [value]="ansMultiple.B">
                  <label for="b{{i}}" class="custom-control-label">{{question.choices.B}}</label>
                </div>
                <div class="custom-radio custom-control">
                  <input type="radio" id="c{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                         [value]="ansMultiple.C">
                  <label for="c{{i}}" class="custom-control-label">{{question.choices.C}}</label>
                </div>
                <div class="custom-radio custom-control">
                  <input type="radio" id="d{{i}}" class="custom-control-input" formControlName="{{i+1}}"
                         [value]="ansMultiple.D">
                  <label for="d{{i}}" class="custom-control-label">{{question.choices.D}}</label>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <button type="submit" #submitBtn class="btn btn-lg btn-warning" [disabled]="!quiz.valid">Submit</button>

      </div>
    </div>
  </form>
</div>
