<div class="modal-header">
  <h5 class="modal-title">Assign Gig to Freelancer</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="assign()">
  <div class="modal-body">
    <div class="form-group">
      <label>Select Freelancer</label>
      <ng-select [items]="freelancers"
                 bindLabel="search_string"
                 bindValue="id"
                 formControlName="freelancer_id"
                 [readonly]=readonly
                 placeholder="Select Freelancer">
        <ng-template ng-label-tmp let-item="item">
          {{item.first_name}} {{item.last_name}} | {{item.username}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.first_name}} {{item.last_name}} | {{item.username}}
        </ng-template>
      </ng-select>
    </div>

    <div class="form-group">
      <label>Select Gig</label>
      <ng-select [items]="projects"
                 bindLabel="name"
                 bindValue="id"
                 formControlName="project_id"
                 placeholder="Select Gig">
        <ng-template ng-label-tmp let-item="item">
          {{item.name}} | {{item.position}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.name}} | {{item.position}}
        </ng-template>
      </ng-select>
    </div>

    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Start Date</label>
          <input type="text" class="form-control" formControlName="start_date"
                 bsDatepicker [bsConfig]="{showWeekNumbers: false}">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>End Date</label>
          <input type="text" class="form-control" formControlName="end_date"
                 bsDatepicker [bsConfig]="{showWeekNumbers: false}">
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
    <button type="submit" #submitBtn class="btn btn-warning">Assign</button>
  </div>
</form>
