import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CostCentersStore } from './cost-centers.store';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class CostCentersService {

  constructor(private costCentersStore: CostCentersStore,
              private http: HttpClient) { }

  get(): any {
    return this.http.get(`${environment.api}cost-centers`).pipe(tap(
      (res: any) => { this.costCentersStore.set(res.costCenters); }));
  }

  create(data): any {
    return this.http.post(`${environment.api}create/cost-center`, data).pipe(tap(
      (res: any) => { this.costCentersStore.add(res.costCenter); }));
  }

  edit(data): any {
    return this.http.post(`${environment.api}edit/cost-center`, data).pipe(tap(
      (res: any) => { this.costCentersStore.replace(res.costCenter.id, res.costCenter); }));
  }

  delete(id): any {
    return this.http.get(`${environment.api}delete/cost-center/${id}`).pipe(tap(
      () => { this.costCentersStore.remove(id); }));
  }

  editRTPApprover(data): any {
    return this.http.post(`${environment.api}edit/rtp-approver`, data).pipe(tap(
      (res: any) => { this.costCentersStore.replace(res.rtpApprover.id, res.rtpApprover); }));
  }

}
