import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserQuery } from '../../app/core/state/user/user.query';
import { QuestionsService } from '../../app/core/state/questions/questions.service';
import { SkillsService } from '../../app/core/state/skills/skills.service';
import { Helper } from '../../app/core/utils/helper';

@Injectable()
export class CanActivateQuizGuard implements CanActivate {

  constructor(private userQuery: UserQuery,
              private questionsService: QuestionsService,
              private skillsService: SkillsService,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer: any) => {
      const canActivate = Helper.isNextStep;

      if (!canActivate) {
        return this.router.navigate(['']);
      }

      observer.next(canActivate);
      return observer.complete();
    });
  }
}
