<div class="app-page-title"
     *ngIf="user.profile.interview &&
     (user?.profile?.interview?.status < interviewStatus.DONE || user?.reapply_date != null)">
  <div class="page-title-heading">
    <div class="form-group">
      <app-interview-schedule></app-interview-schedule>
    </div>
  </div>
</div>

<app-personal-info [user]="user"></app-personal-info>

<app-skills [user]="user"></app-skills>

<app-dev-account [user]="user"></app-dev-account>

<app-upload-cv></app-upload-cv>
