<div class="app-page-title">
  <div class="page-title-heading">
    <div class="form-group">
      Need help?
      <div class="page-title-subheading">
        Email support at <a [href]="link">{{email}}</a>
      </div>
    </div>
  </div>
</div>
