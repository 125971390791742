import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Contract } from '../../core/state/contracts/contract.model';
import { ContractsService } from '../../core/state/contracts/contracts.service';
import { ContractsQuery } from '../../core/state/contracts/contracts.query';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ContractDeleteComponent } from './contract-delete/contract-delete.component';
import { UploadContractComponent } from './upload-contract/upload-contract.component';
import { fieldStates, State } from '../../core/constants/FieldState';
import { UserQuery } from '../../core/state/user/user.query';
import { Order } from '@datorama/akita';
import { ROLE } from '../../core/constants/Role';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {
  bsModalRef: BsModalRef;
  contracts: Observable<Contract[]>;
  testContracts: Observable<Contract[]>;
  fields: any;
  state = State;
  p1 = 1;
  p2 = 1;
  filters = [null, null];
  isFinance: boolean;
  itemsPerPage: number;

  constructor(private contractsService: ContractsService,
              private contractsQuery: ContractsQuery,
              private modalService: BsModalService,
              private userQuery: UserQuery) {
    this.fields = fieldStates[this.userQuery.getValue().account.role];
    this.isFinance = this.userQuery.getValue().account.role === ROLE.FINANCE;
    this.itemsPerPage = this.isFinance ? 10 : 5;
  }

  ngOnInit(): void {
    this.contracts = this.contractsQuery.selectAll({
      filterBy: entity => entity.test === null || !entity.test,
      sortBy: 'created_at', sortByOrder: Order.DESC
    });
    this.testContracts = this.contractsQuery.selectAll({
      filterBy: entity => entity.test,
      sortBy: 'created_at', sortByOrder: Order.DESC
    });
  }

  download(id): void {
    this.contractsService.download(id).subscribe(res => {
      window.open(res, '_self');
    });
  }

  delete(contract): void {
    const initialState = {
      contractToDelete: contract
    };
    this.bsModalRef = this.modalService.show(ContractDeleteComponent, {initialState});
  }

  upload(): void {
    this.bsModalRef = this.modalService.show(UploadContractComponent, {ignoreBackdropClick: true, class: 'modal-xl'});
  }

  sort(index): void {
    this.filters[index] = this.filters[index] === 'desc' ? 'asc' : 'desc';

    this.contracts = this.contractsQuery.selectAll({
      filterBy: entity => entity.test === null || !entity.test,
      sortBy: index === 0 ? 'freelancer' : 'end_date',
      sortByOrder: this.filters[index] === 'desc' ? Order.DESC : Order.ASC
    });
  }

}
