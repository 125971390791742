import { Injectable } from '@angular/core';
import { resetStores } from '@datorama/akita';
import { UserStore } from './user.store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  file: any;

  constructor(private http: HttpClient,
              private userStore: UserStore) { }

  login(user): any {
    return this.http.post(`${environment.api}login`, user).pipe(tap(
      (res: any) => {
        this.userStore.update({
          token: res.token,
          account: res.account
        });
      }));
  }

  register(user): any {
    return this.http.post(`${environment.api}register`, user).pipe(tap(
      (res: any) => {
        this.userStore.update({
          token: res.token,
          account: res.account
        });
      }));
  }

  get(): any {
    return this.http.get(`${environment.api}account`).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  logout(): void {
    resetStores();
    localStorage.clear();
  }

  checkEmail(email): any {
    return this.http.get(`${environment.api}check/${email}`);
  }

  resetPassword(user): any {
    return this.http.post(`${environment.api}reset/password`, user);
  }

  reapply(quiz): any {
    return this.http.post(`${environment.api}reapply`, quiz).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  storeResume(file): void {
    this.file = file;
  }

  getFile(): any {
    return this.file;
  }

}
