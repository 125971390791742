<div class="app-page-title">
  <div class="form-group d-inline-block">
    <button class="btn btn-warning mr-2" (click)="createGig()">Create Gig</button>
  </div>
  <div class="form-group d-inline-block">
    <button class="btn btn-warning mr-2" (click)="addPlatform()">Add Skill for Hiring</button>
  </div>
  <div class="form-group d-inline-block">
    <button class="btn btn-success" (click)="assignGig()" *ngIf="superAdmin">Assign Gig</button>
  </div>
</div>


<div class="card">
  <div class="card-header">
    Gig Pool
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col"></th>
        <th>Name</th>
        <th>Open Position</th>
        <th tooltip="Required Platforms, Frameworks and Programming Language">
          Required Skills
        </th>
        <th>Scope of Work</th>
        <th>Budget Range</th>
        <th>Cost Center</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let project of projects | async | paginate: {itemsPerPage: 10, currentPage: p}">
        <th scope="row">
          <a (click)="editGig(project)"><img src="assets/img/edit.svg" tooltip="Edit Gig"></a>
        </th>
        <td>{{project.name}}</td>
        <td>{{project.position}}</td>
        <td>
          <div *ngFor="let platform of project.platform">{{platform.name}}</div>
        </td>
        <td>{{project.scope}}</td>
        <td>&#8369;{{project.budget_low}}-{{project.budget_high}}/hr</td>
        <td>{{project.cost_center_name}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</div>
