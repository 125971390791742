export enum TYPE_QUESTION {
  'Multiple choice',
  'Yes or No'
}

export enum QUESTION_TYPE {
  MULTIPLE,
  BOOLEAN
}

export enum ANS_BOOL {
  No,
  Yes
}

export enum ANS_MULTIPLE {
  A = 1,
  B,
  C,
  D
}
