<div class="card">
  <div class="card-body">
    <form [formGroup]="form" (ngSubmit)="update()">
      <div class="form-group">
        <label>TIN</label>
        <input type="text" placeholder="TIN" class="form-control" formControlName="TIN">
        <small *ngIf="f.TIN.errors" tabindex="-1" class="form-text text-muted font-weight-bold">
          This is required
        </small>
      </div>

      <div class="form-group">
        <label>GCASH</label>
        <input type="text" placeholder="GCASH" class="form-control" formControlName="gcash">
        <small *ngIf="f.gcash.errors" tabindex="-1" class="form-text text-muted font-weight-bold">
          This is required
        </small>
      </div>

      <div class="form-group">
        <label>Bank</label>
        <input type="text" placeholder="Bank" class="form-control" formControlName="bank">
      </div>

      <div class="form-group">
        <label>Bank Account Name</label>
        <input type="text" placeholder="Account name" class="form-control" formControlName="accountName">
      </div>

      <div class="form-group">
        <label>Bank Account Number</label>
        <input type="text" placeholder="Account number" class="form-control" formControlName="accountNumber">
      </div>

      <button #saveBtn class="btn btn-warning" [disabled]="!form.valid">Save</button>
    </form>
  </div>
</div>
