<div class="app-header">
  <button type="button" class="hamburger mobile-menu outline-none" (click)="openSidebar()">
      <span class="hamburger-box">
          <span class="hamburger-inner"></span>
      </span>
  </button>

  <img src="assets/img/logo.png" height="30px" class="mobile-menu">

  <button class="btn btn-warning" (click)="logout()">Log out</button>
</div>
