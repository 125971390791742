<form [formGroup]="userForm" (ngSubmit)="updateFreelancerInfo()">
  <div class="app-page-title">
    <div class="col-md-6 p-0">
      <div class="form-group">
        <label>Select Freelancer to Update</label>
        <ng-select [items]="freelancers | async"
                   bindLabel="search_string"
                   bindValue="id"
                   placeholder="Select"
                   formControlName="id"
                   (change)="getInfo()">
          <ng-template ng-label-tmp let-item="item">
            {{item.first_name}} {{item.last_name}} | {{item.username}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            {{item.first_name}} {{item.last_name}} | {{item.username}}
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-header">
      Personal Info
    </div>

    <div class="card-body">
      <div class="form-row">
        <div class="col-md-4">
          <div class="form-group">
            <label>First name</label>
            <input type="text" class="form-control" formControlName="first_name">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Middle name</label>
            <input type="text" class="form-control" formControlName="middle_name">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Last name</label>
            <input type="text" class="form-control" formControlName="last_name">
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-8">
          <div class="form-group">
            <label>Address</label>
            <input type="text" class="form-control" formControlName="address">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Mobile</label>
            <input type="text" class="form-control" formControlName="mobile">
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Hours</label>
            <select formControlName="hours" class="custom-select">
              <option [ngValue]="0">Part time</option>
              <option [ngValue]="1">Full time</option>
            </select>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label>Hours Remarks</label>
            <input type="text" class="form-control" formControlName="hours_remarks">
          </div>
        </div>
      </div>
    </div>

    <div class="d-block card-footer text-right">
      <button #updatePersonal type="submit" class="btn btn-warning text-nowrap" [disabled]="!userForm.valid">
        Update Personal Info
      </button>
    </div>
  </div>
</form>


<form [formGroup]="paymentForm" (ngSubmit)="updateFreelancerPaymentInfo()">
  <div class="card mb-3">
    <div class="card-header">
      Payment Details
    </div>

    <div class="card-body">
      <div class="form-row">
        <div class="col-md-5">
          <div class="form-group">
            <label>GCash</label>
            <input type="text" class="form-control" formControlName="gcash">
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <label>Powerpay name</label>
            <input type="text" class="form-control" formControlName="powerpay_name">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label>Last Invoice No</label>
            <input type="number" class="form-control" formControlName="last_invoice">
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label>TIN</label>
            <input type="text" class="form-control" formControlName="tin">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Cost Center</label>
            <ng-select [items]="costCenters"
                       bindLabel="cost_center_name"
                       bindValue="id"
                       formControlName="cost_center_id"
                       placeholder="Cost Center">
              <ng-template ng-label-tmp let-item="item">
                {{item.cost_center_name}} | {{item.bu_head}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                {{item.cost_center_name}} | {{item.bu_head}}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Bank</label>
            <input type="text" class="form-control" formControlName="bank">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Account name</label>
            <input type="text" class="form-control" formControlName="account_name">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Account number</label>
            <input type="text" class="form-control" formControlName="account_number">
          </div>
        </div>
      </div>
    </div>

    <div class="d-block card-footer text-right">
      <button #updatePayment type="submit" class="btn btn-warning text-nowrap" [disabled]="!paymentForm.valid">
        Update Payment Details
      </button>
    </div>
  </div>
</form>

<div class="card mb-3">
  <div class="card-header">
    Contracts
  </div>

  <div class="card-body">
    <p *ngIf="freelancer">Current Contract:<br>
      <span *ngIf="!freelancer.profile?.current_contract"><i>none</i></span>
      <span *ngIf="freelancer.profile?.current_contract"
            class="font-weight-bold">{{freelancer.profile?.current_contract?.project_name}}
        ({{freelancer.profile?.current_contract?.start_date | date: 'mediumDate'}}
        - {{freelancer.profile?.current_contract?.end_date | date: 'mediumDate'}})</span><br>
      <span *ngIf="freelancer.profile?.current_contract">
        Rate: ₱{{freelancer.profile.current_contract.rate | number: '1.2-2'}}
        {{term[freelancer?.profile?.current_contract.payment_term]}}
      </span>
    </p>

    <p *ngIf="freelancer">New Contract:<br>
      <span *ngIf="!freelancer.profile?.new_contract"><i>none</i></span>
      <span *ngIf="freelancer.profile?.new_contract"
            class="font-weight-bold">{{freelancer.profile?.new_contract?.project_name}}
        ({{freelancer.profile?.new_contract?.start_date | date: 'mediumDate'}}
        - {{freelancer.profile?.new_contract?.end_date | date: 'mediumDate'}})</span><br>
      <span *ngIf="freelancer.profile?.new_contract">
        Rate: ₱{{freelancer.profile.new_contract.rate | number: '1.2-2'}}
        {{term[freelancer.profile?.new_contract?.payment_term]}}
      </span>
    </p>
  </div>
</div>

<div class="card">
  <div class="card-header">
    Status: <strong>{{status}}</strong>
  </div>

  <div class="card-body">
    <div class="form-group" *ngIf="ongoingGigs.length > 0">
      <p>Ongoing Project(s)</p>
      <div *ngFor="let ongoingGig of ongoingGigs">
        <strong>{{ongoingGig.name}}</strong>
        <fa-icon [icon]="faMinusCircle" class="pointer ml-1" tooltip="Unlink Gig"
                 (click)="openUnlinkModal(ongoingGig)"></fa-icon>
      </div>
    </div>

    <div class="form-group" *ngIf="ongoingGigs.length === 0 && status !== undefined">
      <button class="btn btn-success" (click)="assignGig()">Assign Gig</button>
    </div>
  </div>
</div>
