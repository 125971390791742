<div class="card mb-3">
  <div class="card-header">
    Contracts
  </div>

  <div class="table-responsive">
    <table class="table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col"></th>
        <th>Generated by</th>
        <th class="text-nowrap">Freelancer
          <img (click)="sort(0)" class="sort"
               [src]="this.filters[0] === 'desc' ? 'assets/img/down.svg' : 'assets/img/up.svg'"/>
        </th>
        <th>File</th>
        <th class="text-nowrap">Expiry Date
          <img (click)="sort(1)" class="sort"
               [src]="this.filters[1] === 'desc' ? 'assets/img/down.svg' : 'assets/img/up.svg'"/>
        </th>
        <th class="text-center">Status</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let contract of contracts | async | paginate: {id: 'pagination1', itemsPerPage: itemsPerPage, currentPage: p1}">
        <th scope="row" class="text-nowrap">
          <a class="mr-1" (click)="download(contract.id)"><img src="assets/img/download.svg" tooltip="Download"></a>
          <a (click)="delete(contract)" *ngIf="fields['generateContract'] === state.VISIBLE"><img
                  src="assets/img/trash.svg" tooltip="Delete"></a>
        </th>
        <td>{{contract.maker}}</td>
        <td>{{contract.freelancer}}</td>
        <td>{{contract.filename}}</td>
        <td class="text-nowrap">{{contract.end_date !== '-' ? (contract.end_date | date: 'MMM d, y') : contract.end_date}}</td>
        <td class="text-center">
          <div class="badge badge-info" *ngIf="contract?.signers > 0 && contract.document_hash">Pending</div>
          <div class="badge badge-success" *ngIf="contract?.signers === 0 && contract.document_hash">Completed</div>
          <div class="badge badge-primary" *ngIf="!contract.document_hash">Manual</div>
          <div class="badge badge-warning" *ngIf="contract?.signers == null && contract.document_hash">Rejected</div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer d-flex justify-content-between">
    <pagination-controls id="pagination1" (pageChange)="p1 = $event" class="d-inline-block"></pagination-controls>
    <button class="btn btn-success" *ngIf="fields['generateContract'] === state.VISIBLE" (click)="upload()">
      Manual Upload
    </button>
  </div>
</div>


<div class="card" *ngIf="!isFinance">
  <div class="card-header">
    Test Contracts
  </div>

  <div class="table-responsive">
    <table class="table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col"></th>
        <th>Generated by</th>
        <th>Freelancer</th>
        <th>File</th>
        <th class="text-center">Status</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let contract of testContracts | async | paginate: {id: 'pagination2', itemsPerPage: 5, currentPage: p2}">
        <th scope="row" class="text-nowrap">
          <a class="mr-1" (click)="download(contract.id)"><img src="assets/img/download.svg" tooltip="Download"></a>
          <a (click)="delete(contract)" *ngIf="fields['generateContract'] === state.VISIBLE"><img
                  src="assets/img/trash.svg" tooltip="Delete"></a>
        </th>
        <td>{{contract.maker}}</td>
        <td>{{contract.freelancer}}</td>
        <td>{{contract.filename}}</td>
        <td class="text-center">
          <div class="badge badge-info" *ngIf="contract?.signers > 0">Pending</div>
          <div class="badge badge-success" *ngIf="contract?.signers === 0">Completed</div>
          <div class="badge badge-warning" *ngIf="contract?.signers == null">Rejected</div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer">
    <pagination-controls id="pagination2" (pageChange)="p2 = $event"></pagination-controls>
  </div>
</div>
