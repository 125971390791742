<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide(); close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="send()">
  <div class="modal-body">
    <div>Select Interview Date and Time</div>
    <input type="text"
           placeholder="Select date"
           class="form-control w-75 d-inline-block"
           bsDatepicker
           formControlName="date"
           [bsConfig]="{showWeekNumbers: false}"
           [minDate]="minDate"
           (bsValueChange)="changeDate($event)">

    <fa-icon [icon]="faSpinner" [spin]="true" *ngIf="loading" class="ml-2"></fa-icon>
    <select class="form-control w-25 d-inline-block" *ngIf="displayFreeTime || !isAdmin" formControlName="time">
      <option *ngFor="let time of timesToDisplay" [value]="time.value">{{time.text}}</option>
    </select>

    <div *ngIf="isAdmin && !connected"><i>Please connect your Microsoft account to get free time in your calendar.</i>
    </div>

    <div *ngIf="isAdmin">
      <ng-select [items]="projects | async"
                 bindLabel="name"
                 bindValue="id"
                 class="custom mt-4 mb-2"
                 formControlName="projectId"
                 placeholder="Search Gig"
                 notFoundText="Not on list? Create gig.">
        <ng-template ng-label-tmp let-item="item">
          {{item.name}} | {{item.position}} | &#8369;{{item.budget_low}}-{{item.budget_high}}/hr
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.name}} | {{item.position}} | &#8369;{{item.budget_low}}-{{item.budget_high}}/hr
        </ng-template>
      </ng-select>

      <button type="button" class="btn btn-warning" (click)="createGig()">Create Gig</button>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide(); close()">Cancel</button>
    <button #sendBtn type="submit" class="btn btn-warning"
            [innerHTML]="isAdmin ? 'Send Invite' : 'Propose New Schedule'">
    </button>
  </div>
</form>
