import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InvoicesState, InvoicesStore } from './invoices.store';

@Injectable({providedIn: 'root'})
export class InvoicesQuery extends QueryEntity<InvoicesState> {

  constructor(protected store: InvoicesStore) {
    super(store);
  }

}
