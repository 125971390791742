import { Injectable } from '@angular/core';
import { Invoice } from './invoice.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface InvoicesState extends EntityState<Invoice> {}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'invoices'})
export class InvoicesStore extends EntityStore<InvoicesState> {

  constructor() {
    super();
  }

}

