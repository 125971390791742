// export const environment = {
//   production: false,
//   api: 'https://api-dev-gigconnect.cortex.ph/api/',
//   harvestClientID: 'SRJ-Q0p67jSTyB2z1-wr-vtZ',
//   appUrl: 'http://localhost:4200',
//   microsoftClientID: 'fae3128f-367d-4ede-946d-ad6143921fad',
//   supportEmail: 'dev-gigconnect@cortex.ph'
// };
export const environment = {
  production: true,
  api: 'https://api-gigconnect.cortex.ph/api/',
  harvestClientID: 'hTAapH55NTPaLy-aOOweyhGE',
  appUrl: 'https://gigconnect.cortex.ph',
  microsoftClientID: 'fae3128f-367d-4ede-946d-ad6143921fad',
  supportEmail: 'gigconnect@cortex.ph'
};