import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from '../../../core/services/profile.service';
import { UserQuery } from '../../../core/state/user/user.query';
import { UserService } from '../../../core/state/user/user.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {
  @ViewChild('saveBtn') saveBtn: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  @Input() username: string;
  @Input() otp: string;
  form: FormGroup;
  matchPattern: boolean;
  changedPassword: boolean;

  constructor(private fb: FormBuilder,
              private profileService: ProfileService,
              private renderer: Renderer2,
              private router: Router,
              private userQuery: UserQuery,
              private userService: UserService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [this.userQuery.getValue()?.account?.id],
      username: [this.username],
      otp: [this.otp],
      password: ['', Validators.required]
    });
  }

  get f(): any { return this.form.controls; }

  onMatchPattern(event): void {
    this.matchPattern = event;
  }

  changePassword(): void {
    this.renderer.setProperty(this.saveBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Saving...');

    if (this.router.url === '/forgot/password') {
      this.userService.resetPassword(this.form.value).subscribe(
        res => {
          this.enableBtn();
          this.changedPassword = true;
          setTimeout(() => { this.router.navigate(['login']); }, 1000);
        },
        error => { this.enableBtn(); }
      );
    } else {
      this.profileService.changePassword(this.form.value).subscribe(
        res => { this.enableBtn(); },
        error => { this.enableBtn(); }
      );
    }
  }

  enableBtn(): void {
    this.renderer.removeAttribute(this.saveBtn.nativeElement, 'disabled');
    this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Save');
  }

  togglePassword(): void {
    this.passwordInput.nativeElement.type === 'password' ?
      this.renderer.setAttribute(this.passwordInput.nativeElement, 'type', 'text') :
      this.renderer.setAttribute(this.passwordInput.nativeElement, 'type', 'password');
  }

}
