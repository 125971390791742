import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PasswordDirective } from './directives/password.directive';
import { ScheduleModalComponent } from './components/schedule-modal/schedule-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditPasswordComponent } from './components/edit-password/edit-password.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GigModalComponent } from './components/gig-modal/gig-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    PasswordDirective,
    ScheduleModalComponent,
    EditPasswordComponent,
    GigModalComponent
  ],
  exports: [
    PasswordDirective,
    ScheduleModalComponent,
    EditPasswordComponent,
    GigModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgSelectModule,
    FontAwesomeModule
  ],
  providers: []
})
export class SharedModule {}
