import { Injectable } from '@angular/core';
import { ProjectsStore } from './projects.store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { tap } from 'rxjs/operators';
import { ProjectsQuery } from './projects.query';
import { FreelancersStore } from '../freelancers/freelancers.store';

@Injectable({providedIn: 'root'})
export class ProjectsService {

  constructor(private freelancersStore: FreelancersStore,
              private http: HttpClient,
              private projectsQuery: ProjectsQuery,
              private projectsStore: ProjectsStore) { }

  get(): any {
    return this.http.get(`${environment.api}projects`).pipe(tap(
      (res: any) => this.projectsStore.set(res.projects)));
  }

  create(gig): any {
    return this.http.post(`${environment.api}create/gig`, gig).pipe(tap(
      (res: any) => {
        if (this.projectsQuery.hasEntity(res.project.id)) {
          this.projectsStore.replace(res.project.id, res.project);
        } else {
          this.projectsStore.add(res.project);
        }
      }));
  }

  unlink(freelancerId, projectId): any {
    return this.http.get(`${environment.api}unlink/gig/${freelancerId}/${projectId}`).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

  assign(form): any {
    return this.http.post(`${environment.api}assign/gig`, form).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

}
