import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SkillsService } from '../../../core/state/skills/skills.service';
import { SkillsQuery } from '../../../core/state/skills/skills.query';
import { Observable } from 'rxjs';
import { Skill } from '../../../core/state/skills/skill.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-add-platform',
  templateUrl: './add-platform.component.html',
  styleUrls: ['./add-platform.component.scss']
})
export class AddPlatformComponent implements OnInit {
  @ViewChild('addBtn') addBtn: ElementRef;
  form: FormGroup;
  skills: Observable<Skill[]>;

  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private renderer: Renderer2,
              private skillsQuery: SkillsQuery,
              private skillsService: SkillsService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      skill: ['']
    });

    this.skills = this.skillsQuery.selectAll({sortBy: 'name'});
  }

  add(): void {
    this.renderer.setProperty(this.addBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.addBtn.nativeElement, 'innerText', 'Adding...');

    this.skillsService.hireSKill(this.form.value).subscribe(
      res => {
        this.bsModalRef.hide();
      });
  }

}
