<div class="card">
  <div class="card-body">

    <alert type="success" [isOpen]="uploadSuccess">Resume uploaded successfully.</alert>
    <p>Resume: <strong>{{ cv }}</strong></p>

    <div class="form-group">
      <label>Upload Resume</label>
      <div class="input-group">
        <div class="custom-file">
          <input #fileInput type="file" class="custom-file-input" id="inputFile"
                 (change)="onFileChange($event)">
          <label #customLabel class="custom-file-label" for="inputFile">Choose file</label>
        </div>
        <div class="input-group-append">
          <button #uploadBtn (click)="uploadFile()" class="btn btn-warning" type="button"
                  [disabled]="!resumePdf">Upload
          </button>
        </div>
      </div>
      <small *ngIf="!resumePdf" tabindex="-1" class="form-text text-muted font-weight-bold">
        Accepts pdf files only
      </small>
    </div>

  </div>
</div>
