import { Component, OnInit } from '@angular/core';
import { ANS_BOOL, ANS_MULTIPLE, TYPE_QUESTION } from '../../core/constants/Question';
import { EditQuestionComponent } from './edit-question/edit-question.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Skill } from '../../core/state/skills/skill.model';
import { SkillsService } from '../../core/state/skills/skills.service';
import { SkillsQuery } from '../../core/state/skills/skills.query';
import { QuestionsQuery } from '../../core/state/questions/questions.query';
import { Question } from '../../core/state/questions/question.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-manage-quiz',
  templateUrl: './manage-quiz.component.html',
  styleUrls: ['./manage-quiz.component.scss']
})
export class ManageQuizComponent implements OnInit {
  bsModalRef: BsModalRef;
  basicQuestions: Observable<Question[]>;
  functionalQuestions: Observable<Question[]>;
  sets: Skill[];
  type = TYPE_QUESTION;
  ansBool = ANS_BOOL;
  ansMultiple = ANS_MULTIPLE;
  toggle: boolean;
  setId: any = '';

  constructor(private modalService: BsModalService,
              private questionsQuery: QuestionsQuery,
              private skillsQuery: SkillsQuery,
              private skillsService: SkillsService) { }

  ngOnInit(): void {
    this.basicQuestions = this.questionsQuery.selectAll({
      filterBy: entity => entity.skill_id === null
    });
    this.sets = this.skillsQuery.getAll({
      filterBy: entity => entity.with_quiz,
      sortBy: 'name'
    });
  }

  selectSet(): void {
    this.functionalQuestions = this.questionsQuery.selectAll({
      filterBy: entity => entity.skill_id === Number(this.setId)
    });
    this.toggle = this.skillsQuery.getEntity(this.setId).enable;
  }

  enable(): void {
    this.skillsService.enable(this.setId).subscribe();
  }

  openModal(question): void {
    const initialState = {
      questionToEdit: question
    };
    this.bsModalRef = this.modalService.show(EditQuestionComponent, {initialState});
  }

}
