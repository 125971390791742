import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ContractsStore } from './contracts.store';
import { UserStore } from '../user/user.store';
import { FreelancersStore } from '../freelancers/freelancers.store';

@Injectable({providedIn: 'root'})
export class ContractsService {

  constructor(private http: HttpClient,
              private contractsStore: ContractsStore,
              private freelancersStore: FreelancersStore,
              private userStore: UserStore) { }

  sendOffer(offer): any {
    return this.http.post(`${environment.api}offer/gig`, offer);
  }

  acceptOffer(id, gig): any {
    return this.http.get(`${environment.api}accept/offer/${id}/${gig}`);
  }

  generate(details): any {
    return this.http.post(`${environment.api}generate/contract`, details).pipe(tap(
      (res: any) => {
        this.contractsStore.add(res.contract);
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

  get(): any {
    return this.http.get(`${environment.api}contracts`).pipe(tap(
      (res: any) => {
        this.contractsStore.set(res.contracts);
      }));
  }

  download(id): any {
    return this.http.get(`${environment.api}download/contract/${id}`).pipe(map(
      (res: any) => res.file));
  }

  delete(id): any {
    return this.http.get(`${environment.api}delete/contract/${id}`).pipe(tap(
      (res: any) => {
        this.contractsStore.remove(id);
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

  sign(id): any {
    return this.http.get(`${environment.api}sign/contract/${id}`).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  sendToBU(id): any {
    return this.http.get(`${environment.api}send/contract/bu/${id}`);
  }

  sendToFreelancer(id): any {
    return this.http.get(`${environment.api}send/contract/freelancer/${id}`);
  }

  upload(form): any {
    const header = new HttpHeaders().set('enctype', 'multipart/form-data');

    return this.http.post(`${environment.api}upload/contract`, form, {headers: header}).pipe(tap(
      (res: any) => {
        this.contractsStore.add(res.contract);
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

}
