import { Injectable } from '@angular/core';
import { UserStore } from '../state/user/user.store';
import { ProjectsStore } from '../state/projects/projects.store';
import { FreelancersStore } from '../state/freelancers/freelancers.store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  constructor(private freelancersStore: FreelancersStore,
              private http: HttpClient,
              private projectsStore: ProjectsStore,
              private userStore: UserStore) { }

  sendInvite(schedule): any {
    return this.http.post(`${environment.api}invite`, schedule).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

  changeStatus(id, status): any {
    return this.http.get(`${environment.api}interview/${id}/${status}`).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

  proposeNew(schedule): any {
    return this.http.post(`${environment.api}propose`, schedule).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  accept(id): any {
    return this.http.get(`${environment.api}accept/${id}`).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  decline(id): any {
    return this.http.get(`${environment.api}cancel/${id}`).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  confirmSched(id): any {
    return this.http.get(`${environment.api}confirm/${id}`).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

  cancel(id): any {
    return this.http.get(`${environment.api}cancel/${id}`).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.account.id, res.account);
      }));
  }

}
