import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from '../../core/state/invoices/invoice.model';
import { InvoicesQuery } from '../../core/state/invoices/invoices.query';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FilesService } from '../../core/state/files/files.service';
import { formatNumber } from '@angular/common';
import { Rtp } from '../../core/state/rtps/rtp.model';
import { RtpsQuery } from '../../core/state/rtps/rtps.query';
import { Order } from '@datorama/akita';
import { FreelancersQuery } from '../../core/state/freelancers/freelancers.query';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-generate-csv',
  templateUrl: './generate-csv.component.html',
  styleUrls: ['./generate-csv.component.scss']
})
export class GenerateCsvComponent implements OnInit {
  @ViewChild('generateBtn') generateBtn: ElementRef;
  @ViewChild('remindBtn') remindBtn: ElementRef;
  form: FormGroup;
  invoices: Observable<Invoice[]>;
  rtps: Rtp[];
  rtp: number;
  rtpObject: Rtp;

  constructor(private fb: FormBuilder,
              private filesService: FilesService,
              private freelancersQuery: FreelancersQuery,
              private invoicesQuery: InvoicesQuery,
              private renderer: Renderer2,
              private rtpsQuery: RtpsQuery) {
    this.rtps = this.rtpsQuery.getAll({sortBy: 'created_at', sortByOrder: Order.DESC});
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      infos: this.fb.array([])
    });
  }

  get i(): any { return this.form.get('infos') as FormArray; }

  addItem(powerpay, gcash, amount): FormGroup {
    return this.fb.group({
      name: [powerpay],
      gcash: [gcash],
      amount: [formatNumber(amount, 'en-US', '1.2-2')],
      remarks: ['']
    });
  }

  getInvoices(): void {
    this.i.clear();
    this.rtpObject = this.rtp ? this.rtpsQuery.getEntity(this.rtp) : null;
    const invoiceIds = JSON.parse(this.rtpObject.invoice_ids);

    if (invoiceIds) {
      invoiceIds.forEach(id => {
        const invoice = this.invoicesQuery.getEntity(id);
        const freelancer = this.freelancersQuery.getEntity(invoice.account_id);
        this.i.push(this.addItem(freelancer.profile?.powerpay_name, freelancer.profile?.gcash, invoice.amount));
      });
    }
  }

  generate(): void {
    this.renderer.setProperty(this.generateBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generating...');

    this.filesService.generate(this.form.value, this.rtpObject.period, this.rtp).subscribe(
      res => {
        window.open(res, '_self');
        this.renderer.removeAttribute(this.generateBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generate CSV');
      }
    );
  }

}
