<form [formGroup]="form">
  <div class="modal-header">
    <h5 class="modal-title">Edit Question</h5>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <input class="form-control" formControlName="question">
    </div>

    <div class="input-group form-group">
      <div class="input-group-prepend">
        <label class="input-group-text">Type of Question</label>
      </div>
      <select class="custom-select" formControlName="type">
        <option [ngValue]="type.MULTIPLE">Multiple Choice</option>
        <option [ngValue]="type.BOOLEAN">Yes or No</option>
      </select>
    </div>

    <div formGroupName="choices" *ngIf="$form.type.value === type.MULTIPLE">
      <div class="form-group">
        <input formControlName="A" class="form-control mb-2" placeholder="Option A">
      </div>
      <div class="form-group">
        <input formControlName="B" class="form-control mb-2" placeholder="Option B">
      </div>
      <div class="form-group">
        <input formControlName="C" class="form-control mb-2" placeholder="Option C">
      </div>
      <div class="form-group">
        <input formControlName="D" class="form-control mb-2" placeholder="Option D">
      </div>
    </div>

    <div class="input-group form-group">
      <div class="input-group-prepend">
        <label class="input-group-text">Correct Answer</label>
      </div>
      <select class="custom-select" formControlName="correctAnswer">
        <ng-container *ngIf="$form.type.value === type.MULTIPLE">
          <option [ngValue]="ansMultiple.A">A</option>
          <option [ngValue]="ansMultiple.B">B</option>
          <option [ngValue]="ansMultiple.C">C</option>
          <option [ngValue]="ansMultiple.D">D</option>
        </ng-container>
        <ng-container *ngIf="$form.type.value === type.BOOLEAN">
          <option [ngValue]="ansBool.Yes">Yes</option>
          <option [ngValue]="ansBool.No">No</option>
        </ng-container>
      </select>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
    <button #saveBtn type="submit" class="btn btn-warning" (click)="save()">Save</button>
  </div>
</form>
