import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ContractsStore, ContractsState } from './contracts.store';

@Injectable({ providedIn: 'root' })
export class ContractsQuery extends QueryEntity<ContractsState> {

  constructor(protected store: ContractsStore) {
    super(store);
  }

}
