<div class="modal-header">
  <h5 class="modal-title">Upload BIR Form 2307</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body mb-3">
  <alert *ngIf="uploaded" type="success" [dismissOnTimeout]="5000">
    You successfully uploaded BIR Form 2307
  </alert>

  <div class="form-group">
    <label>Select Freelancer</label>
    <ng-select [items]="freelancers"
               bindLabel="search_string"
               bindValue="id"
               [(ngModel)]="id"
               placeholder="Select Freelancer">
      <ng-template ng-label-tmp let-item="item">
        {{item.first_name}} {{item.last_name}} | {{item.username}}
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        {{item.first_name}} {{item.last_name}} | {{item.username}}
      </ng-template>
    </ng-select>
  </div>

  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Period From</label>
        <input type="text" placeholder="Select start date" class="form-control" [(ngModel)]="from"
               bsDatepicker [bsConfig]="{showWeekNumbers: false}">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Period To</label>
        <input type="text" placeholder="Select end date" class="form-control" [(ngModel)]="to"
               bsDatepicker [bsConfig]="{showWeekNumbers: false}">
      </div>
    </div>
  </div>

  <div class="form-group">
    <label>Upload BIR Form 2307</label>
    <div class="input-group">
      <div class="custom-file">
        <input #fileInput type="file" class="custom-file-input h-auto" id="inputFile"
               (change)="onFileChange($event)">
        <label #customLabel class="custom-file-label" for="inputFile">Choose File</label>
      </div>
      <div class="input-group-append">
        <button #uploadBtn (click)="uploadBirForm()" class="btn btn-success" type="button">Upload</button>
      </div>
    </div>
  </div>

</div>
