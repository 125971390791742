<alert type="success" [isOpen]="changedPassword">Changed password successfully. Redirecting to login...</alert>

<form [formGroup]="form" (ngSubmit)="changePassword()">
  <div class="form-group">
    <label>Change Password</label>
    <div class="password">
      <input type="password" placeholder="Password" class="form-control" formControlName="password"
             minlength="8" maxlength="16" appPassword #passwordInput
             (matchPattern)="onMatchPattern($event)">
      <img src="assets/img/eye.svg" (click)="togglePassword()">
    </div>
    <small *ngIf="f.password.errors?.minlength || !matchPattern" tabindex="-1"
           class="form-text text-muted font-weight-bold">
      Minimum of 8 characters, at least one upper case, one lower case and a number
    </small>
  </div>

  <button #saveBtn class="btn btn-warning" [disabled]="!form.valid || !matchPattern">
    Save
  </button>
</form>
