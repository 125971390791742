import { NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppConfigModule } from '../config/app-config.module';
import { AdminModule } from './admin/admin.module';
import { FreelancerModule } from './freelancer/freelancer.module';
import { MainModule } from './main/main.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    MainModule,
    AdminModule,
    FreelancerModule,
    AppConfigModule,
    FontAwesomeModule
  ],
  bootstrap: [AppComponent],
  providers: []
})
export class AppModule {}
