import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RtpsService } from '../../../core/state/rtps/rtps.service';
import { Rtp } from '../../../core/state/rtps/rtp.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-send-rtp-modal',
  templateUrl: './send-rtp-modal.component.html',
  styleUrls: ['./send-rtp-modal.component.scss']
})
export class SendRtpModalComponent implements OnInit {
  @ViewChild('submitBtn') submitBtn: ElementRef;
  rtpToSend: Rtp;

  constructor(public bsModalRef: BsModalRef,
              private rtpsService: RtpsService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  sendToBUHeads(): void {
    this.renderer.setProperty(this.submitBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.submitBtn.nativeElement, 'innerText', 'Sending...');
    this.rtpsService.sendToBUHeads(this.rtpToSend.id).subscribe(
      res => { this.bsModalRef.hide(); });
  }

}
