import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { QuizComponent } from './quiz/quiz.component';
import { RegisterComponent } from './register/register.component';
import { PrivacyPolicyComponent } from './register/privacy-policy/privacy-policy.component';
import { MainComponent } from './main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { OtpComponent } from './otp/otp.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AcceptOfferComponent } from './accept-offer/accept-offer.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    OtpComponent,
    QuizComponent,
    RegisterComponent,
    PrivacyPolicyComponent,
    MainComponent,
    AcceptOfferComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    AlertModule.forRoot(),
    CoreModule,
    SharedModule,
    RouterModule,
    FontAwesomeModule
  ]
})
export class MainModule {}
