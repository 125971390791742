import { Injectable } from '@angular/core';
import { Template } from './template.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface TemplatesState extends EntityState<Template> {}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'templates'})
export class TemplatesStore extends EntityStore<TemplatesState> {

  constructor() {
    super();
  }

}

