import { Component, OnInit } from '@angular/core';
import { Contract } from '../../core/state/contracts/contract.model';
import { ContractsService } from '../../core/state/contracts/contracts.service';
import { UserQuery } from '../../core/state/user/user.query';
import { UntilDestroy } from '@ngneat/until-destroy';

declare var eversign: any;

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-sign-contract',
  templateUrl: './sign-contract.component.html',
  styleUrls: ['./sign-contract.component.scss']
})
export class SignContractComponent implements OnInit {
  contract: Contract;
  currentContract: Contract;
  newContract: Contract;
  
  constructor(private contractsService: ContractsService,
              private userQuery: UserQuery) { }
  
  ngOnInit(): void {
    this.userQuery.select().subscribe(user => {
      this.currentContract = user.account?.profile?.current_contract;
      this.newContract = user.account?.profile?.new_contract;
      this.contract = user.account?.profile?.new_contract ? user.account?.profile?.new_contract
        : user.account?.profile?.current_contract;
    });
    
    const containerWidth = ((window.innerWidth - 40) > 800) ? 800 : (window.innerWidth - 40);
    if (this.contract?.signers === 1) {
      eversign.open({
        url: JSON.parse(this.contract.embedded_url)[2],
        containerID: 'container',
        width: containerWidth,
        height: 600,
        events: {
          signed: () => {
            this.contractsService.sign(this.contract.id).subscribe();
          }
        }
      });
    }
  }

  download(id): void {
    this.contractsService.download(id).subscribe(res => {
      window.open(res, '_self');
    });
  }

}
