import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserQuery } from '../core/state/user/user.query';
import { NgFormsManager } from '@ngneat/forms-manager';
import { ROLE } from '../core/constants/Role';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProjectsService } from '../core/state/projects/projects.service';
import { CostCentersService } from '../core/state/cost-centers/cost-centers.service';
import { FreelancersService } from '../core/state/freelancers/freelancers.service';
import { ContractsService } from '../core/state/contracts/contracts.service';
import { InvoicesService } from '../core/state/invoices/invoices.service';
import { RtpsService } from '../core/state/rtps/rtps.service';
import { FilesService } from '../core/state/files/files.service';
import { UserService } from '../core/state/user/user.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private contractsService: ContractsService,
              private costCentersService: CostCentersService,
              private filesService: FilesService,
              private formsManager: NgFormsManager,
              private freelancersService: FreelancersService,
              private invoicesService: InvoicesService,
              private projectsService: ProjectsService,
              private router: Router,
              private rtpsService: RtpsService,
              private userQuery: UserQuery,
              private userService: UserService) { }

  ngOnInit(): void {
    this.formsManager.destroy();

    switch (this.userQuery.getValue().account.role) {
      case ROLE.SUPER_ADMIN:
        if (this.router.url === '/') { this.router.navigate(['pool/talent']); }
        this.freelancersService.getFirstPage().subscribe();
        this.projectsService.get().subscribe();
        this.contractsService.get().subscribe();
        this.invoicesService.get().subscribe();
        this.rtpsService.get().subscribe();
        this.filesService.get().subscribe();
        this.costCentersService.get().subscribe();
        break;
      case ROLE.HR:
        if (this.router.url === '/') { this.router.navigate(['pool/talent']); }
        this.freelancersService.getFirstPage().subscribe();
        this.projectsService.get().subscribe();
        this.contractsService.get().subscribe();
        this.costCentersService.get().subscribe();
        break;
      case ROLE.FINANCE:
        if (this.router.url === '/') { this.router.navigate(['contracts']); }
        this.freelancersService.getFirstPage().subscribe();
        this.contractsService.get().subscribe();
        this.invoicesService.get().subscribe();
        this.rtpsService.get().subscribe();
        this.filesService.get().subscribe();
        this.costCentersService.get().subscribe();
        break;
      case ROLE.PM:
        if (this.router.url === '/') { this.router.navigate(['pool/talent']); }
        this.freelancersService.getFirstPage().subscribe();
        this.projectsService.get().subscribe();
        this.costCentersService.get().subscribe();
        break;
      case ROLE.DEVELOPER:
        if (this.router.url === '/') { this.router.navigate(['profile']); }
        this.userService.get().subscribe();
        break;
    }
  }

}
