import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appPassword]'
})
export class PasswordDirective {
  @Output() matchPattern = new EventEmitter<boolean>();
  upperCaseLetters = /[A-Z]/g;
  lowerCaseLetters = /[a-z]/g;
  numbers = /[0-9]/g;

  constructor(private el: ElementRef) { }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent): any {
    this.matchPattern.emit(this.el.nativeElement.value.match(this.upperCaseLetters) !== null
      && this.el.nativeElement.value.match(this.lowerCaseLetters) !== null
      && this.el.nativeElement.value.match(this.numbers) !== null);
  }

}
