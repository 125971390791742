import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TemplatesStore } from './templates.store';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class TemplatesService {

  constructor(private templatesStore: TemplatesStore,
              private http: HttpClient) { }

  get(): any {
    return this.http.get(`${environment.api}templates`).pipe(tap(
      (res: any) => { this.templatesStore.set(res.templates); }));
  }

  save($name, $template): any {
    const params = {
      name: $name,
      template: $template,
    };

    return this.http.post(`${environment.api}save/template`, params).pipe(tap(
      (res: any) => { this.templatesStore.add(res.template); }));
  }

  delete(id): any {
    return this.http.get(`${environment.api}delete/template/${id}`).pipe(tap(
      () => { this.templatesStore.remove(id); }));
  }

}
