import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Experience, YEARS_OF_EXPERIENCE } from '../../core/constants/Experience';
import { Order } from '@datorama/akita';
import { INTERVIEW_STATUS } from '../../core/constants/InterviewStatus';
import { SkillsQuery } from '../../core/state/skills/skills.query';
import { FreelancersQuery } from '../../core/state/freelancers/freelancers.query';
import { Skill } from '../../core/state/skills/skill.model';
import { FreelancersService } from '../../core/state/freelancers/freelancers.service';
import { UiQuery } from '../../core/state/ui/ui.query';
import { UntilDestroy } from '@ngneat/until-destroy';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { UnlinkProjectComponent } from './unlink-project/unlink-project.component';
import { fieldStates, State } from '../../core/constants/FieldState';
import { UserQuery } from '../../core/state/user/user.query';
import { User } from '../../core/state/user/user.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-talent-pool',
  templateUrl: './talent-pool.component.html',
  styleUrls: ['./talent-pool.component.scss']
})
export class TalentPoolComponent implements OnInit {
  bsModalRef: BsModalRef | null;
  bsModalRef2: BsModalRef;
  bsModalRef3: BsModalRef;
  talents: Observable<User[]>;
  skills: Observable<Skill[]>;
  experience = Experience;
  yrsExp = YEARS_OF_EXPERIENCE;
  filters = [null, null, null, null, null, null];
  interviewStatus = INTERVIEW_STATUS;
  p = 1;
  clicked: boolean;
  faMinusCircle = faMinusCircle;
  fields: any;
  state = State;
  candidateId: number;

  constructor(private modalService: BsModalService,
              private freelancersQuery: FreelancersQuery,
              private freelancersService: FreelancersService,
              private skillsQuery: SkillsQuery,
              private uiQuery: UiQuery,
              private userQuery: UserQuery) {
    this.fields = fieldStates[this.userQuery.getValue().account.role];
  }

  ngOnInit(): void {
    this.skills = this.skillsQuery.selectAll({sortBy: 'name'});
    this.talents = this.freelancersQuery.selectAll();
  }

  filterSort(index, event?): void {
    if (index === 4) {
      this.filters[index] = this.filters[index] === 'desc' ? 'asc' : 'desc';
    } else {
      this.filters[index] = event.target.value !== '' ? Number(event.target.value) : null;
    }

    this.talents = this.freelancersQuery.selectAll({
      filterBy: entity =>
        ((this.filters[0] == null) ? true : entity.profile.skill_array.includes(this.filters[0])) &&
        ((this.filters[1] == null) ? true : entity.profile.yrs_exp === this.filters[1]) &&

        ((this.filters[2] == null) ? true :
          (this.filters[2] === 0 ? entity.profile.past_gigs.length < 1 : entity.profile.past_gigs.length > 0)) &&

        ((this.filters[3] == null) ? true :
          (this.filters[3] === 0 ? entity.profile.ongoing_gigs.length === 0 :
            (this.filters[3] === 1 ? entity.profile.ongoing_gigs.length > 0 :
              (entity.profile.interview.status < INTERVIEW_STATUS.DONE)))) &&
        
        ((this.filters[5] == null) ? true :
          (this.filters[5] === 0 ? entity.profile.hours === 0 : entity.profile.hours === 1)),
      
      sortBy: index === 4 ? 'yrs_exp' : null,
      sortByOrder: index === 4 ? (this.filters[index] === 'desc' ? Order.DESC : Order.ASC) : null
    });
  }

  exportFreelancers(bool): void {
    this.freelancersService.exportFreelancers(bool).subscribe(res => {
      window.open(res, '_self');
      this.clicked = false;
    });
  }

  openModal(template: TemplateRef<any>, id): void {
    this.candidateId = id;
    this.bsModalRef = this.modalService.show(template, {id: 1});
  }

  openModal2(template: TemplateRef<any>): void {
    this.bsModalRef2 = this.modalService.show(template, {id: 2});
  }

  openUnlinkModal($id, $freelancer, $project): void {
    const initialState = {
      id: $id,
      freelancer: $freelancer,
      project: $project
    };
    this.bsModalRef3 = this.modalService.show(UnlinkProjectComponent, {initialState});
  }

  closeModal(modalId?: number): void {
    this.modalService.hide(modalId);
  }

}
