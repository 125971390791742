import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PrintInvoice } from '../../shared/models/print-invoice.model';
import { INVOICE_PERIOD } from '../../core/constants/InvoicePeriod';
import { InvoicesService } from '../../core/state/invoices/invoices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserQuery } from '../../core/state/user/user.query';
import { environment } from '../../../environments/environment';
import { ReceiptsService } from '../../core/state/receipts/receipts.service';
import { TERM } from '../../core/constants/Term';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('customLabel') customLabel: ElementRef;
  @ViewChild('uploadBtn') uploadBtn: ElementRef;
  invoicePeriods = INVOICE_PERIOD;
  years = [new Date().getFullYear() - 1, new Date().getFullYear()];
  generatedInvoice: boolean;
  accessToken = '';
  scope = '';
  accountId = '';
  details = new PrintInvoice();
  year: number;
  month: string;
  paymentTerm: number;
  or: any;
  uploaded: boolean;

  constructor(private invoicesService: InvoicesService,
              private receiptsService: ReceiptsService,
              private renderer: Renderer2,
              private route: ActivatedRoute,
              private router: Router,
              private userQuery: UserQuery) {
    this.year = Number(localStorage.getItem('year')) || this.years[1];
    this.month = localStorage.getItem('month') ? localStorage.getItem('month') : this.invoicePeriods[new Date().getMonth()].text;
    if (this.userQuery.getValue().account.profile?.last_contract) {
      this.paymentTerm = this.userQuery.getValue().account.profile?.last_contract.payment_term;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.accessToken = params.code;
      this.scope = params.scope;

      if (this.accessToken) {
        localStorage.setItem('harvest_token', this.accessToken);

        if (this.scope) {
          this.accountId = this.scope.split(':')[1];
          localStorage.setItem('account_id', this.accountId);
        }

        this.router.navigate([], {
          queryParams: {
            code: null,
            scope: null,
          },
          queryParamsHandling: 'merge'
        });

        this.createInvoice(false);
      }
    });
  }

  createInvoice(bool: boolean): void {
    if (bool) {
      localStorage.setItem('month', this.month.toString());
      localStorage.setItem('year', this.year.toString());
    }
    
    if (this.userQuery.getValue().account.profile?.last_contract.payment_term === TERM.fixed) {
      this.generate();
    } else {
      if (localStorage.getItem('harvest_token')) {
        this.generate();
      } else {
        window.open(`https://id.getharvest.com/oauth2/authorize?client_id=${environment.harvestClientID}&response_type=code&redirect_uri=${environment.appUrl}/invoice`, '_self');
      }
    }
  }

  generate(): void {
    const monthObj = this.invoicePeriods.find(month => month.text === this.month);
    this.invoicesService.generate(monthObj).subscribe(
      res => {
        this.details.name = `${this.userQuery.getValue().account.first_name} ${this.userQuery.getValue().account.last_name}`;
        this.details.contact = this.userQuery.getValue().account.profile.mobile;
        this.details.email = this.userQuery.getValue().account.username;
        this.details.tin = this.userQuery.getValue().account.profile.TIN;
        this.details.number = res.num;
        
        this.details.rate = res.rate;
        this.details.monthName = this.month;
        this.details.year = Number(localStorage.getItem('year'));
        
        if (this.userQuery.getValue().account.profile?.last_contract.payment_term === TERM.fixed) {
          this.details.description = res.project_name;
          this.details.totalHours = 1;
          this.details.amount = res.data;
        } else {
          const timeEntries = JSON.parse(res.data);
          if (timeEntries.time_entries.length > 0) {
            this.details.description = timeEntries.time_entries[0].project.name;
            this.details.totalHours = timeEntries.time_entries.reduce((acc, time) => acc + time.hours, 0);
            this.details.amount = this.details.totalHours * this.details.rate;
          }
        }

        this.generatedInvoice = true;
        localStorage.removeItem('harvest_token');
        localStorage.removeItem('account_id');
      }, error => {
        localStorage.removeItem('harvest_token');
        localStorage.removeItem('account_id');
      });
  }

  onFileChange(event): void {
    const fileName = this.fileInput.nativeElement.files[0].name;
    this.renderer.setProperty(this.customLabel.nativeElement, 'innerText', fileName);

    if (event.target.files.length > 0) {
      this.or = event.target.files[0];
    }
  }

  prepareSave(): any {
    const input = new FormData();
    input.append('period', `${this.month} ${this.year}`);
    input.append('or', this.or);
    return input;
  }

  uploadOR(): void {
    this.uploaded = false;
    this.renderer.setProperty(this.uploadBtn.nativeElement, 'disabled', 'true');
    this.receiptsService.uploadOR(this.prepareSave()).subscribe(
      res => {
        this.renderer.removeAttribute(this.uploadBtn.nativeElement, 'disabled');
        this.uploaded = true;
      }, error => {
        this.renderer.removeAttribute(this.uploadBtn.nativeElement, 'disabled');
      });
  }

}
