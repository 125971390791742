<div class="app-page-title">
  <div class="form-group" *ngIf="fields['connectFreelancers'] === state.VISIBLE">
    <button class="btn btn-success mr-2" (click)="connectAccounts()">
      Connect Harvest Accounts of Freelancers
    </button>
    <fa-icon [icon]="faSpinner" [spin]="true" *ngIf="loading"></fa-icon>
  </div>


  <div class="form-group" *ngIf="fields['connectMicrosoft'] === state.VISIBLE">
    <button class="btn btn-primary" *ngIf="!user.microsoft_connect" (click)="connectMicrosoft()">
      Connect Microsoft Account
    </button>
    <button class="btn btn-primary" *ngIf="user.microsoft_connect" (click)="disconnectMicrosoft()">
      Disconnect Microsoft
    </button>
  </div>


  <div class="form-group" *ngIf="fields['connectHarvest'] === state.VISIBLE">
    <button class="btn btn-warning" *ngIf="!user.harvest_connect" (click)="connectHarvest()">
      Connect Harvest Account
    </button>
    <button class="btn btn-warning" *ngIf="user.harvest_connect" (click)="disconnectHarvest()">
      Disconnect Harvest
    </button>
  </div>
</div>

<div class="card" *ngIf="fields['costCenters'] === state.VISIBLE">
  <div class="card-header">
    RTP Approver
  </div>

  <div class="table-responsive">
    <form [formGroup]="form">
      <table class="align-middle table table-borderless">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><input type="text" class="w-100" formControlName="name" [readOnly]="readonly"></td>
          <td><input type="text" class="w-100" formControlName="email" [readOnly]="readonly"></td>
          <td>
            <button class="btn action-btn" (click)="edit()"
                    [ngClass]="{'btn-success': !readonly, 'btn-info': readonly}">
              {{!readonly ? 'Save' : 'Edit'}}
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
