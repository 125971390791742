export enum State {
  HIDDEN,
  VISIBLE
}

export const fieldStates = [
  {
    talents: State.VISIBLE,
    downloadFreelancers: State.VISIBLE,
    interviews: State.VISIBLE,
    gigs: State.VISIBLE,
    quiz: State.VISIBLE,
    generateContract: State.VISIBLE,
    contracts: State.VISIBLE,
    invoices: State.VISIBLE,
    markInvoices: State.VISIBLE,
    settings: State.VISIBLE,
    connectFreelancers: State.VISIBLE,
    connectHarvest: State.VISIBLE,
    connectMicrosoft: State.VISIBLE,
    costCenters: State.VISIBLE,
    updateData: State.VISIBLE,
    freelancerProfile: State.HIDDEN
  },
  {
    talents: State.VISIBLE,
    downloadFreelancers: State.VISIBLE,
    interviews: State.VISIBLE,
    gigs: State.VISIBLE,
    quiz: State.VISIBLE,
    generateContract: State.VISIBLE,
    contracts: State.VISIBLE,
    invoices: State.HIDDEN,
    markInvoices: State.HIDDEN,
    settings: State.VISIBLE,
    connectFreelancers: State.HIDDEN,
    connectHarvest: State.HIDDEN,
    connectMicrosoft: State.VISIBLE,
    costCenters: State.VISIBLE,
    updateData: State.VISIBLE,
    freelancerProfile: State.HIDDEN
  },
  {
    talents: State.VISIBLE,
    downloadFreelancers: State.HIDDEN,
    interviews: State.VISIBLE,
    gigs: State.VISIBLE,
    quiz: State.HIDDEN,
    generateContract: State.HIDDEN,
    contracts: State.HIDDEN,
    invoices: State.HIDDEN,
    markInvoices: State.HIDDEN,
    settings: State.VISIBLE,
    connectFreelancers: State.HIDDEN,
    connectHarvest: State.HIDDEN,
    connectMicrosoft: State.VISIBLE,
    costCenters: State.HIDDEN,
    updateData: State.HIDDEN,
    freelancerProfile: State.HIDDEN
  },
  {
    talents: State.HIDDEN,
    downloadFreelancers: State.HIDDEN,
    interviews: State.HIDDEN,
    gigs: State.HIDDEN,
    quiz: State.HIDDEN,
    generateContract: State.HIDDEN,
    contracts: State.VISIBLE,
    invoices: State.VISIBLE,
    markInvoices: State.VISIBLE,
    settings: State.VISIBLE,
    connectFreelancers: State.VISIBLE,
    connectHarvest: State.VISIBLE,
    connectMicrosoft: State.HIDDEN,
    costCenters: State.VISIBLE,
    updateData: State.HIDDEN,
    freelancerProfile: State.HIDDEN
  },
  {
    talents: State.HIDDEN,
    downloadFreelancers: State.HIDDEN,
    interviews: State.HIDDEN,
    gigs: State.HIDDEN,
    quiz: State.HIDDEN,
    generateContract: State.HIDDEN,
    contracts: State.HIDDEN,
    invoices: State.HIDDEN,
    markInvoices: State.HIDDEN,
    settings: State.HIDDEN,
    connectFreelancers: State.HIDDEN,
    connectHarvest: State.HIDDEN,
    connectMicrosoft: State.HIDDEN,
    costCenters: State.HIDDEN,
    updateData: State.HIDDEN,
    freelancerProfile: State.VISIBLE
  }
];
