import { Component, OnInit } from '@angular/core';
import { ProjectsQuery } from '../../core/state/projects/projects.query';
import { Observable } from 'rxjs';
import { Project } from '../../core/state/projects/project.model';
import { AddPlatformComponent } from './add-platform/add-platform.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GigModalComponent } from '../../shared/components/gig-modal/gig-modal.component';
import { AssignGigComponent } from './assign-gig/assign-gig.component';
import { UserQuery } from '../../core/state/user/user.query';
import { ROLE } from '../../core/constants/Role';

@Component({
  selector: 'app-gig-pool',
  templateUrl: './gig-pool.component.html',
  styleUrls: ['./gig-pool.component.scss']
})
export class GigPoolComponent implements OnInit {
  bsModalRef: BsModalRef;
  projects: Observable<Project[]>;
  superAdmin: boolean;
  p = 1;

  constructor(private modalService: BsModalService,
              private projectsQuery: ProjectsQuery,
              private userQuery: UserQuery) {
    this.superAdmin = this.userQuery.getValue().account.role === ROLE.SUPER_ADMIN;
  }

  ngOnInit(): void {
    this.projects = this.projectsQuery.selectByLead();
  }

  addPlatform(): void {
    this.bsModalRef = this.modalService.show(AddPlatformComponent);
  }

  createGig(): void {
    const initialState = {
      title: 'Create Gig',
      action: 'Create'
    };
    this.bsModalRef = this.modalService.show(GigModalComponent, {initialState, ignoreBackdropClick: true});
  }

  editGig(project): void {
    const initialState = {
      title: 'Edit Gig',
      projectToEdit: project,
      action: 'Save'
    };
    this.bsModalRef = this.modalService.show(GigModalComponent, {initialState, ignoreBackdropClick: true});
  }

  assignGig(): void {
    this.bsModalRef = this.modalService.show(AssignGigComponent);
  }

}
