export const NAV = [
  {
    route: '/profile',
    state: 'freelancerProfile',
    text: 'Profile'
  },
  {
    route: '/payment',
    state: 'freelancerProfile',
    text: 'Payment'
  },
  {
    route: '/account',
    state: 'freelancerProfile',
    text: 'Account'
  },
  {
    route: '/contract',
    state: 'freelancerProfile',
    text: 'Contract'
  },
  {
    route: '/invoice',
    state: 'freelancerProfile',
    text: 'Invoice'
  },
  {
    route: '/bir-forms',
    state: 'freelancerProfile',
    text: 'BIR 2307'
  },
  {
    route: '/support',
    state: 'freelancerProfile',
    text: 'Support'
  },
  {
    route: '/pool/talent',
    state: 'talents',
    text: 'Talent Pool'
  },
  {
    route: '/pool/interview',
    state: 'interviews',
    text: 'Interview Pool'
  },
  {
    route: '/pool/gig',
    state: 'gigs',
    text: 'Gig Pool'
  },
  {
    route: '/update/freelancer',
    state: 'updateData',
    text: 'Freelancer Data'
  },
  {
    route: '/manage/quiz',
    state: 'quiz',
    text: 'Manage Quiz'
  },
  {
    route: '/offer/gig',
    state: 'quiz',
    text: 'Offer Confirmation'
  },
  {
    route: '/generate/contract',
    state: 'generateContract',
    text: 'Generate Contract'
  },
  {
    route: '/contracts',
    state: 'contracts',
    text: 'Contracts'
  },
  {
    route: '/contract/details',
    state: 'invoices',
    text: 'Contract Details'
  },
  {
    route: '/invoices',
    state: 'invoices',
    text: 'Invoices'
  },
  {
    route: '/generate/rtp',
    state: 'invoices',
    text: 'Generate RTP'
  },
  {
    route: '/rtp',
    state: 'invoices',
    text: 'RTP'
  },
  {
    route: '/generate/csv',
    state: 'invoices',
    text: 'Generate CSV'
  },
  {
    route: '/gcash',
    state: 'invoices',
    text: 'GCASH CSV'
  },
  {
    route: '/milestone',
    state: 'invoices',
    text: 'Milestone'
  },
  {
    route: '/receipts',
    state: 'invoices',
    text: 'Receipts'
  },
  {
    route: '/cost-centers',
    state: 'costCenters',
    text: 'Cost Centers'
  },
  {
    route: '/settings',
    state: 'settings',
    text: 'Settings'
  }
];
