import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilesStore } from './files.store';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { InvoicesStore } from '../invoices/invoices.store';

@Injectable({providedIn: 'root'})
export class FilesService {

  constructor(private filesStore: FilesStore,
              private http: HttpClient,
              private invoicesStore: InvoicesStore) { }

  generate(details, $period, $id): any {
    const params = {
      period: $period,
      infos: details.infos,
      id: $id
    };
    console.log(params)
    return this.http.post(`${environment.api}generate/csv`, params).pipe(tap(
      (res: any) => {
        console.log(res)
        this.filesStore.add(res.csv);
        res.invoices.map(invoice => {
          console.log(invoice)
          this.invoicesStore.replace(invoice.id, invoice);
        });
      }),
      map((res: any) => res.file));
  }

  get(): any {
    return this.http.get(`${environment.api}files`).pipe(tap(
      (res: any) => {
        console.log(res)
        this.filesStore.set(res.files.data);
      }));
  }

  download(id): any {
    return this.http.get(`${environment.api}download/file/${id}`).pipe(map(
      (res: any) => res.file));
  }

}
