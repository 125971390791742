<div class="app-page-title">
  <div class="form-group d-inline-block">
    <select [(ngModel)]="year" class="custom-select w-auto mr-2" (ngModelChange)="getInvoices()">
      <option *ngFor="let year of years;" value="{{year}}">{{year}}</option>
    </select>

    <select [(ngModel)]="month" class="custom-select w-auto mr-2" (ngModelChange)="getInvoices()">
      <option *ngFor="let period of invoicePeriods" value="{{period.value}}">{{period.text}}</option>
    </select>
  </div>

  <div class="form-group d-inline-block">
    <button #generateBtn class="btn btn-warning mr-2" (click)="generate()" [disabled]="invoicesIds.length === 0">
      Generate RTP
    </button>
  </div>
</div>


<div class="card">
  <div class="card-header">
    Check invoices to include in RTP
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-hover">
      <thead>
      <tr>
        <th scope="col" class="text-center">
          <input type="checkbox" [checked]="checkAll" (change)="checkAll = !checkAll; selectAll()">
        </th>
        <th>Freelancer</th>
        <th>Period</th>
        <th>Project</th>
        <th>Paid</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let invoice of invoices" [ngClass]="{'paid': invoice.paid}">
        <th scope="row" class="text-center">
          <input type="checkbox" [checked]="invoicesIds.includes(invoice.id)" (click)="addID(invoice.id)"
                 *ngIf="!invoice.paid">
        </th>
        <td>{{invoice.freelancer}}</td>
        <td>{{invoice.period}}</td>
        <td>{{invoice.project}}</td>
        <td>{{invoice.paid ? 'Yes' : 'No'}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
