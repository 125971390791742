<div class="mb-3 card">
  <div class="card-body">

    <div class="d-flex justify-content-between mb-2">
      <h6>Platforms, Frameworks & Programming Languages</h6>
      <button (click)="canEdit = !canEdit" type="button" class="btn btn-default add">
        <img src="assets/img/plus.svg">
      </button>
    </div>

    <div *ngFor="let skill of user?.profile?.skills" class="mr-4 d-inline-block">
      <h6 [ngClass]="{'font-weight-bold': skill.confirmed}">{{skill.skill_name}}
        <img *ngIf="canEdit" (click)="delete(skill.id)" class="delete" src="assets/img/delete.svg">
      </h6>
    </div>

    <div *ngIf="canEdit">
      <form [formGroup]="form" (ngSubmit)="edit()">
        <ng-select [items]="skills"
                   bindLabel="name"
                   bindValue="id"
                   [multiple]="true"
                   class="custom"
                   formControlName="skills"
                   placeholder="Select skill(s)"></ng-select>
        <button #saveBtn class="mt-3 btn btn-warning">Add skill(s)</button>
      </form>
    </div>

  </div>
</div>
