import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { SkillsQuery } from '../app/core/state/skills/skills.query';
import { SkillsService } from '../app/core/state/skills/skills.service';
import { QuestionsQuery } from '../app/core/state/questions/questions.query';
import { QuestionsService } from '../app/core/state/questions/questions.service';

@Injectable()
export class AppResolver implements Resolve<any> {

  constructor(private skillsQuery: SkillsQuery,
              private skillsService: SkillsService,
              private questionsQuery: QuestionsQuery,
              private questionsService: QuestionsService) { }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    return new Observable((observer: Observer<any>) => {
      if (this.skillsQuery.getAll().length === 0) {
        this.skillsService.get().subscribe();
      }

      if (this.questionsQuery.getAll().length === 0) {
        this.questionsService.get().subscribe();
      }

      observer.next('');
      observer.complete();
    });
  }

}
