import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { INTERVIEW_STATUS, INTERVIEW_STATUS_TEXT } from '../../core/constants/InterviewStatus';
import { InterviewService } from '../../core/services/interview.service';
import { FreelancersQuery } from '../../core/state/freelancers/freelancers.query';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User } from '../../core/state/user/user.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-interview-pool',
  templateUrl: './interview-pool.component.html',
  styleUrls: ['./interview-pool.component.scss']
})
export class InterviewPoolComponent implements OnInit {
  bsModalRef: BsModalRef;
  interviewVal = INTERVIEW_STATUS;
  interviewStatus = INTERVIEW_STATUS_TEXT;
  candidates: Observable<User[]>;
  clicked: number;
  p = 1;

  constructor(private modalService: BsModalService,
              private freelancersQuery: FreelancersQuery,
              private interviewService: InterviewService) { }

  ngOnInit(): void {
    this.candidates = this.freelancersQuery.selectAll({
      filterBy: entity => entity.profile?.interview?.status <= INTERVIEW_STATUS.DECLINED
    });
  }

  confirm(id): void {
    this.interviewService.confirmSched(id).subscribe();
  }

  cancel(id): void {
    this.interviewService.cancel(id).subscribe();
  }

  changeInterviewStatus(id, status): void {
    this.interviewService.changeStatus(id, status).subscribe();
  }

}
