import { Injectable } from '@angular/core';
import { SkillsStore } from './skills.store';
import { UserStore } from '../user/user.store';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SkillsService {

  constructor(private http: HttpClient,
              private skillsStore: SkillsStore,
              private userStore: UserStore) { }

  get(): any {
    return this.http.get(`${environment.api}skills`).pipe(tap(
      (res: any) => {
        this.skillsStore.set(res.skills);
      }));
  }

  addSKill(user): any {
    return this.http.post(`${environment.api}add/skill`, user).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  deleteSkill(id): any {
    return this.http.get(`${environment.api}delete/skill/${id}`).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  enable(id): any {
    return this.http.get(`${environment.api}enable/set/${id}`).pipe(tap(
      (res: any) => {
        this.skillsStore.replace(res.skill.id, res.skill);
      }));
  }

  hireSKill(skill): any {
    return this.http.post(`${environment.api}hire/skill`, skill).pipe(tap(
      (res: any) => {
        this.skillsStore.add(res.skill);
      }));
  }

}
