<div class="modal-header">
  <h5 class="modal-title">Change Freelancer Payment Info</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="form-group">
    <ng-select [items]="freelancers | async"
               bindLabel="search_string"
               bindValue="id"
               [(ngModel)]="id"
               (ngModelChange)="getPaymentTerm()"
               placeholder="Select Freelancer">
      <ng-template ng-label-tmp let-item="item">
        {{item.first_name}} {{item.last_name}} | {{item.username}}
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        {{item.first_name}} {{item.last_name}} | {{item.username}}
      </ng-template>
    </ng-select>
  </div>

  <div class="row form-group">
    <label class="col-sm-5 col-form-label">Powerpay Name</label>
    <div class="col-sm-7">
      <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="powerpay">
        <div class="input-group-append">
          <button #savePowerpay class="btn btn-primary" (click)="editPowerpayName()">Save</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row form-group">
    <label class="col-sm-5 col-form-label">GCash</label>
    <div class="col-sm-7">
      <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="gcash">
        <div class="input-group-append">
          <button #saveGcash class="btn btn-primary" (click)="editGcash()">Save</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row form-group">
    <label class="col-sm-5 col-form-label">Last Invoice Number</label>
    <div class="col-sm-7">
      <input class="form-control font-weight-bold" readonly [(ngModel)]="lastInvoice">
    </div>
  </div>

  <div class="row form-group">
    <label class="col-sm-5 col-form-label">Edit Invoice Number</label>
    <div class="col-sm-7">
      <div class="input-group">
        <input type="number" class="form-control" [(ngModel)]="invoice">
        <div class="input-group-append">
          <button #saveInvoiceNum class="btn btn-primary" (click)="changeLastInvoice()">Save</button>
        </div>
      </div>
    </div>
  </div>

</div>
