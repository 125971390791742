import { Injectable } from '@angular/core';
import { Rtp } from './rtp.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface RtpsState extends EntityState<Rtp> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'rtps' })
export class RtpsStore extends EntityStore<RtpsState> {

  constructor() {
    super();
  }

}

