<div class="modal-header">
  <h5 class="modal-title">Decline Invitation</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  Are you sure you want to decline the invitation?
</div>
<div class="modal-footer justify-content-between">
  <button class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
  <button class="btn btn-danger" (click)="decline()">Yes</button>
</div>
