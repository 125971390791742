import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TemplatesStore, TemplatesState } from './templates.store';

@Injectable({ providedIn: 'root' })
export class TemplatesQuery extends QueryEntity<TemplatesState> {

  constructor(protected store: TemplatesStore) {
    super(store);
  }

}
