import { Injectable } from '@angular/core';
import { File } from './file.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface FilesState extends EntityState<File> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'files' })
export class FilesStore extends EntityStore<FilesState> {

  constructor() {
    super();
  }

}

