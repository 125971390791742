import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../state/user/user.service';
import { UiService } from '../../state/ui/ui.service';
import { UiQuery } from '../../state/ui/ui.query';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router,
              private uiQuery: UiQuery,
              private uiService: UiService,
              private userService: UserService) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.userService.logout();
    this.router.navigate(['login']);
  }

  openSidebar(): void {
    this.uiService.updateSidebarState(!this.uiQuery.getValue().sidebarOpen);
  }

}
