import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { UserStore } from '../state/user/user.store';

@Injectable({
  providedIn: 'root'
})
export class HarvestService {

  constructor(private http: HttpClient,
              private userStore: UserStore) { }

  connectHarvest(): any {
    const params = {
      code: localStorage.getItem('harvest_token'),
      accountId: localStorage.getItem('account_id'),
    };
    console.log(params + "PARAMS")
    return this.http.post(`${environment.api}connect/harvest`, params).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  connectAccounts(): any {
    return this.http.get(`${environment.api}connect/accounts`);
  }

  disconnectHarvest(): any {
    return this.http.get(`${environment.api}disconnect/harvest`).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

}
