<div class="modal-header">
  <h5 class="modal-title">DATA PRIVACY NOTICE</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-justify">
  <p>Cortex Technologies Corporation (collectively referred to as “CTC”) values the privacy of the Freelancers using
    this platform for project gigs/assignments. We take the protection and security of our Freelancers’ Personal Data
    with utmost importance and commitment. This Data Privacy Notice contains a summary of our policies regarding the
    collection, use, storage, disclosure, and disposal of our Freelancers’ Personal Data which we receive and collect
    from our Freelancers through our authorized representatives, contact center channels, official websites, web-based
    and mobile-based applications, and other forms of communications.</p>

  <p class="font-weight-bold">What Personal Data do we collect?</p>
  <p>We collect and process Freelancers’ Personal Data only upon their affirmative consent and in accordance with the
    Data Privacy Act. In the usual course of our business activities, we collect the following Personal Data from
    freelancers:</p>
  <ol>
    <li>full name (First and Last)</li>
    <li>email address </li>
    <li>contact numbers</li>
    <li>home address</li>
    <li>work background</li>
    <li>BIR TIN</li>
    <li>Personal bank account number</li>
    <li>Valid Government ID</li>
  </ol>
  <p>When our Freelancers provide us with Personal Data by which they can be reasonably identified, they can be assured
    that their Personal Data will be used only in accordance with our Privacy Policy and the relevant Terms and
    Conditions governing their relationship with us. We collect Freelancers’ Personal Data from this platform.</p>

  <p class="font-weight-bold">How do we use your Personal Data?</p>
  <p>All our Freelancers’ Personal Data, which are collected with their consent, and other information with regard to
    their application with us may be combined and processed: (1) for freelance employment (2) payout of rendered
    services (3) background check (4) skills assessment</p>

  <p class="font-weight-bold">How do we protect your Personal Data?</p>
  <p>We protect our Freelancers’ Personal Data through organizational, procedural, and technical security measures. We
    ensure that only authorized employees and third party service providers, who have undertaken to satisfy our
    information security and data privacy requirements are allowed to process our Freelancers’ Personal Data. While we
    warrant that due diligence is exercised to guarantee privacy of our Freelancers’ Personal Data, note that no data
    transmission conducted over the internet and/or through electronic channels can be guaranteed to be absolutely
    secure. Your Personal Data shall be stored in our secure database for five (5) years. Thereafter, physical and
    electronic records shall be disposed.</p>

  <p class="font-weight-bold">Contact Us</p>
  <p>If you have any comments or questions or if you wish to access, correct, update or ask for a copy of personal
    information we hold about you, or if you have questions about CTC’s privacy practices in general, please email
    <a>dpo@cortex.ph</a></p>
</div>
