<div class="modal-header">
  <h5 class="modal-title">Unlink Project</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  Unlink
  <strong>{{ freelancer.first_name }} {{ freelancer.last_name }}</strong>
  from
  <strong>{{ project.name }}</strong> ?
</div>

<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
  <button type="submit" #submitBtn class="btn btn-danger" (click)="unlink()">Unlink</button>
</div>
