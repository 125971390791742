import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ProfileService } from '../../../core/services/profile.service';
import { UserQuery } from '../../../core/state/user/user.query';
import { UserService } from '../../../core/state/user/user.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @ViewChild('deleteBtn') deleteBtn: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  deleteForm: FormGroup;
  wrongPassword: boolean;

  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private profileService: ProfileService,
              private renderer: Renderer2,
              private router: Router,
              private userQuery: UserQuery,
              private userService: UserService) { }

  ngOnInit(): void {
    this.deleteForm = this.fb.group({
      id: [this.userQuery.getValue().account.id],
      password: ['']
    });
  }

  delete(): void {
    this.renderer.setProperty(this.deleteBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.deleteBtn.nativeElement, 'innerText', 'Deleting...');

    this.profileService.deleteUser(this.deleteForm.value).subscribe(
      res => {
        this.bsModalRef.hide();
        this.userService.logout();
        this.router.navigate(['register']);
      },
      error => {
        this.renderer.removeAttribute(this.deleteBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.deleteBtn.nativeElement, 'innerText', 'Delete');
        this.wrongPassword = true;
      }
    );
  }

  togglePassword(): void {
    this.passwordInput.nativeElement.type === 'password' ?
      this.renderer.setAttribute(this.passwordInput.nativeElement, 'type', 'text') :
      this.renderer.setAttribute(this.passwordInput.nativeElement, 'type', 'password');
  }

}
