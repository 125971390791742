import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { FreelancersQuery } from '../../core/state/freelancers/freelancers.query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FreelancersService } from '../../core/state/freelancers/freelancers.service';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { UnlinkProjectComponent } from '../talent-pool/unlink-project/unlink-project.component';
import { AssignGigComponent } from '../gig-pool/assign-gig/assign-gig.component';
import { User } from '../../core/state/user/user.model';
import { CostCenter } from '../../core/state/cost-centers/cost-center.model';
import { CostCentersQuery } from '../../core/state/cost-centers/cost-centers.query';
import { TERM } from '../../core/constants/Term';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-freelancer-data',
  templateUrl: './freelancer-data.component.html',
  styleUrls: ['./freelancer-data.component.scss']
})
export class FreelancerDataComponent implements OnInit {
  @ViewChild('updatePersonal') updatePersonal: ElementRef;
  @ViewChild('updatePayment') updatePayment: ElementRef;
  bsModalRef: BsModalRef;
  bsModalRef2: BsModalRef;
  freelancers: Observable<User[]>;
  userForm: FormGroup;
  paymentForm: FormGroup;
  status: string;
  ongoingGigs = [];
  faMinusCircle = faMinusCircle;
  freelancer: User;
  costCenters: CostCenter[];
  term = TERM;
  
  constructor(private fb: FormBuilder,
              private freelancersQuery: FreelancersQuery,
              private freelancersService: FreelancersService,
              private costCentersQuery: CostCentersQuery,
              private modalService: BsModalService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      id: [null, Validators.required],
      first_name: ['', Validators.required],
      middle_name: [''],
      last_name: ['', Validators.required],
      address: [''],
      mobile: ['', Validators.required],
      hours: [null],
      hours_remarks: ['']
    });

    this.paymentForm = this.fb.group({
      id: [null, Validators.required],
      gcash: [''],
      powerpay_name: [''],
      last_invoice: [null],
      rate: [''],
      payment_term: [0],
      tin: [''],
      cost_center_id: [null],
      bank: [''],
      account_name: [''],
      account_number: ['']
    });

    this.freelancers = this.freelancersQuery.selectAll({sortBy: 'username'});
    this.costCenters = this.costCentersQuery.getAll({filterBy: entity => entity.cost_center !== 'RTP'});
  }

  getInfo(): void {
    if (this.userForm.get('id').value) {
      this.freelancer = this.freelancersQuery.getEntity(this.userForm.get('id').value);
      this.userForm.patchValue({first_name: this.freelancer.first_name});
      this.userForm.patchValue({middle_name: this.freelancer.middle_name});
      this.userForm.patchValue({last_name: this.freelancer.last_name});
      this.userForm.patchValue({address: this.freelancer.profile.address});
      this.userForm.patchValue({mobile: this.freelancer.profile.mobile});
      this.userForm.patchValue({hours: this.freelancer.profile.hours});
      this.userForm.patchValue({hours_remarks: this.freelancer.profile.hours_remarks});
      
      this.paymentForm.patchValue({id: this.userForm.get('id').value});
      this.paymentForm.patchValue({gcash: this.freelancer.profile.gcash});
      this.paymentForm.patchValue({powerpay_name: this.freelancer.profile.powerpay_name});
      this.paymentForm.patchValue({last_invoice: this.freelancer.profile.last_invoice});
      this.paymentForm.patchValue({tin: this.freelancer.profile.TIN});
      this.paymentForm.patchValue({cost_center_id: this.freelancer.profile.cost_center_id});

      this.paymentForm.patchValue({bank: this.freelancer.profile.bank});
      this.paymentForm.patchValue({account_name: this.freelancer.profile.bank_acct_name});
      this.paymentForm.patchValue({account_number: this.freelancer.profile.bank_acct_num});

      this.status = this.freelancer.profile.ongoing_gigs.length > 0 ? 'ACTIVE' : 'INACTIVE';
      this.ongoingGigs = this.freelancer.profile.ongoing_gigs;
    } else {
      this.userForm.reset();
      this.paymentForm.reset();
      this.status = undefined;
      this.ongoingGigs = [];
    }
  }

  updateFreelancerInfo(): void {
    this.renderer.setProperty(this.updatePersonal.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.updatePersonal.nativeElement, 'innerText', 'Updating...');

    this.freelancersService.updateFreelancerInfo(this.userForm.value).subscribe(
      res => {
        this.getInfo();
        this.renderer.removeAttribute(this.updatePersonal.nativeElement, 'disabled');
        this.renderer.setProperty(this.updatePersonal.nativeElement, 'innerText', 'Update Personal Info');
      });
  }

  updateFreelancerPaymentInfo(): void {
    this.renderer.setProperty(this.updatePayment.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.updatePayment.nativeElement, 'innerText', 'Updating...');

    this.paymentForm.patchValue({id: this.userForm.get('id').value});
    this.freelancersService.updateFreelancerPaymentInfo(this.paymentForm.value).subscribe(
      res => {
        this.getInfo();
        this.renderer.removeAttribute(this.updatePayment.nativeElement, 'disabled');
        this.renderer.setProperty(this.updatePayment.nativeElement, 'innerText', 'Update Payment Details');
      });
  }

  openUnlinkModal($project): void {
    const initialState = {
      id: this.freelancer.id,
      freelancer: this.freelancer,
      project: $project
    };
    this.bsModalRef = this.modalService.show(UnlinkProjectComponent, {initialState});
    this.bsModalRef.content.onClose.subscribe(res => {
      if (res) { this.getInfo(); }
    });
  }

  assignGig(): void {
    const initialState = {
      freelancerId: this.freelancer.id
    };
    this.bsModalRef2 = this.modalService.show(AssignGigComponent, {initialState});
    this.bsModalRef2.content.onClose.subscribe(res => {
      if (res) { this.getInfo(); }
    });
  }

}
