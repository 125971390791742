import { Component, OnInit } from '@angular/core';
import { ContractsService } from '../../../core/state/contracts/contracts.service';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

declare var eversign: any;

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-sign-rtp',
  templateUrl: './sign-rtp.component.html',
  styleUrls: ['./sign-rtp.component.scss']
})
export class SignRtpComponent implements OnInit {
  url: string;
  id: number;
  role: string;

  constructor(private contractsService: ContractsService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.url = params.url;
      this.id = params.id;
    });

    eversign.open({
      url: this.url,
      containerID: 'container',
      width: 1000,
      height: 600,
      events: {}
    });
  }

}
