import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RtpsStore, RtpsState } from './rtps.store';

@Injectable({ providedIn: 'root' })
export class RtpsQuery extends QueryEntity<RtpsState> {

  constructor(protected store: RtpsStore) {
    super(store);
  }

}
