export class PrintInvoice {
  position: string;
  name: string;
  contact: string;
  email: string;
  tin: string;
  number: number;
  description: string;
  totalHours: number;
  rate: number;
  amount: number;
  monthName: string;
  year: number;
}
