import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  username: string;
  emailNoExist: boolean;
  verifiedOtp: boolean;
  otp: string;

  constructor() {
    this.username = '';
    this.otp = '';
  }

  ngOnInit(): void { }

  verifyOtp(verified: boolean): void {
    this.verifiedOtp = verified;
  }

  verifyEmail(emailNoExist: boolean): void {
    this.emailNoExist = emailNoExist;
    console.log(emailNoExist);
  }

  setOtp(otp: string): void {
    this.otp = otp;
  }

}
