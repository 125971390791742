import { Injectable } from '@angular/core';
import { Receipt } from './receipt.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface ReceiptsState extends EntityState<Receipt> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'receipts' })
export class ReceiptsStore extends EntityStore<ReceiptsState> {

  constructor() {
    super();
  }

}

