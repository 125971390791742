<div class="app-page-title">
  <alert *ngIf="uploaded" type="success" [dismissOnTimeout]="5000">
    You successfully uploaded OR for {{month}} {{year}}.
  </alert>

  <div class="form-group d-inline-block">
    <select [(ngModel)]="year" class="custom-select w-auto mr-2">
      <option *ngFor="let year of years;" value="{{year}}">{{year}}</option>
    </select>

    <select [(ngModel)]="month" class="custom-select w-auto mr-2">
      <option *ngFor="let period of invoicePeriods" value="{{period.text}}">{{period.text}}</option>
    </select>
  </div>

  <div class="form-group d-inline-block">
    <button class="btn btn-warning mr-2" (click)="createInvoice(true)">
      View Invoice
    </button>
  </div>

  <div class="form-group">
    <div class="input-group">
      <div class="custom-file">
        <input #fileInput type="file" class="custom-file-input h-auto" id="inputFile"
               (change)="onFileChange($event)">
        <label #customLabel class="custom-file-label" for="inputFile">Upload AR/OR</label>
      </div>
      <div class="input-group-append">
        <button #uploadBtn (click)="uploadOR()" class="btn btn-success" type="button">Upload</button>
      </div>
    </div>
  </div>

</div>


<app-print-invoice *ngIf="generatedInvoice" [details]="details"></app-print-invoice>

<!--<div id="container" class="mt-2"></div>-->
