import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { FreelancersQuery } from '../../core/state/freelancers/freelancers.query';
import { ProjectsQuery } from '../../core/state/projects/projects.query';
import { Project } from '../../core/state/projects/project.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TemplatesService } from '../../core/state/templates/templates.service';
import { Template } from '../../core/state/templates/template.model';
import { TemplatesQuery } from '../../core/state/templates/templates.query';
import { ContractsService } from '../../core/state/contracts/contracts.service';
import { CostCentersQuery } from '../../core/state/cost-centers/cost-centers.query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmContractComponent } from './confirm-contract/confirm-contract.component';
import { CostCenter } from '../../core/state/cost-centers/cost-center.model';
import { User } from '../../core/state/user/user.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-generate-contract',
  templateUrl: './generate-contract.component.html',
  styleUrls: ['./generate-contract.component.scss']
})
export class GenerateContractComponent implements OnInit {
  @ViewChild('generateBtn') generateBtn: ElementRef;
  @ViewChild('saveBtn') saveBtn: ElementRef;
  bsModalRef: BsModalRef;
  form: FormGroup;
  freelancers: Observable<User[]>;
  projects: Observable<Project[]>;
  services: FormArray;
  deliverables: FormArray;
  activities: FormArray;
  templates: Observable<Template[]>;
  template = 0;
  costCenters: CostCenter[];
  milestone = false;
  
  constructor(private contractsService: ContractsService,
              private costCentersQuery: CostCentersQuery,
              private fb: FormBuilder,
              private freelancersQuery: FreelancersQuery,
              private modalService: BsModalService,
              private projectsQuery: ProjectsQuery,
              private renderer: Renderer2,
              private templatesQuery: TemplatesQuery,
              private templatesService: TemplatesService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      milestone: [false],
      test: [false],
      freelancer_id: [null, Validators.required],
      name: ['', Validators.required],
      address: ['', Validators.required],

      project_id: [null, Validators.required],
      position: [''],
      agreed_rate: [null, Validators.required],
      payment_term: [0],
      duration: [null, Validators.required],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],

      cost_center: [null, Validators.required],
      bu_head: [null, Validators.required],
      bu_position: ['', Validators.required],
      bu_mobile: ['', Validators.required],
      bu_id: ['', Validators.required],
      bu_id_details: ['', Validators.required],

      services: this.fb.array([
        this.fb.control('', [Validators.required])
      ]),
      deliverables: this.fb.array([
        this.fb.control('', [Validators.required])
      ]),
      activities: this.fb.array([this.createActivity()]),
      template_name: ['']
    });

    this.freelancers = this.freelancersQuery.selectAll({sortBy: 'username'});
    this.projects = this.projectsQuery.selectByLead();

    this.templatesService.get().subscribe();
    this.templates = this.templatesQuery.selectAll();

    this.costCenters = this.costCentersQuery.getAll({filterBy: entity => entity.cost_center !== 'RTP'});
  }

  get s(): any { return this.form.get('services') as FormArray; }

  get d(): any { return this.form.get('deliverables') as FormArray; }

  get a(): any { return this.form.get('activities') as FormArray; }

  getNameAddress(): void {
    if (this.form.get('freelancer_id').value) {
      const freelancer = this.freelancersQuery.getEntity(this.form.get('freelancer_id').value);
      const middleName = freelancer.middle_name === '' ? '' : ` ${freelancer.middle_name}`;
      this.form.patchValue({name: `${freelancer.first_name}${middleName} ${freelancer.last_name}`});
      this.form.patchValue({address: freelancer.profile.address});
    } else {
      this.form.patchValue({name: ''});
      this.form.patchValue({address: ''});
    }
  }

  getCostCenter(): void {
    if (this.form.get('project_id').value) {
      const gig = this.projectsQuery.getEntity(this.form.get('project_id').value);
      this.form.patchValue({position: gig.position});
      this.form.patchValue({cost_center: gig.cost_center_id});
      this.getBU();
    } else {
      this.form.patchValue({position: ''});
      this.form.patchValue({cost_center: null});
      this.getBU();
    }
  }

  getBU(): void {
    if (this.form.get('cost_center').value) {
      const bu = this.costCentersQuery.getEntity(this.form.get('cost_center').value);
      this.form.patchValue({bu_head: bu.bu_head});
      this.form.patchValue({bu_position: bu.bu_position});
      this.form.patchValue({bu_mobile: bu.bu_mobile});
      this.form.patchValue({bu_id: bu.valid_id});
      this.form.patchValue({bu_id_details: bu.id_details});
    } else {
      this.form.patchValue({bu_head: ''});
      this.form.patchValue({bu_position: ''});
      this.form.patchValue({bu_mobile: ''});
      this.form.patchValue({bu_id: ''});
      this.form.patchValue({bu_id_details: ''});
    }
  }

  createActivity(): FormGroup {
    return this.fb.group({
      milestone: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      due: ['']
    });
  }

  addService(index?): void {
    if (index || index === 0) {
      this.s.removeAt(index);
    } else {
      this.s.push(this.fb.control('', [Validators.required]));
    }
  }

  addDeliverable(index?): void {
    if (index || index === 0) {
      this.d.removeAt(index);
    } else {
      this.d.push(this.fb.control('', [Validators.required]));
    }
  }

  addActivity(index?): void {
    if (index || index === 0) {
      this.a.removeAt(index);
    } else {
      this.a.push(this.createActivity());
    }
  }
  
  changeTerm(): void {
    this.form.patchValue({payment_term: this.milestone ? 2 : 0});
  }
  
  generate(): void {
    this.form.patchValue({milestone: this.milestone});
    const initialState = {
      freelancer: this.freelancersQuery.getEntity(this.form.get('freelancer_id').value),
      project: this.projectsQuery.getEntity(this.form.get('project_id').value),
      costCenter: this.costCentersQuery.getEntity(this.form.get('cost_center').value),
      formValue: this.form.value
    };
    this.bsModalRef = this.modalService.show(ConfirmContractComponent, {initialState, class: 'modal-xl'});
    
    this.bsModalRef.content.onClose.subscribe(res => {
      this.form.patchValue({test: res});
      this.renderer.setProperty(this.generateBtn.nativeElement, 'disabled', 'true');
      this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generating...');

      this.contractsService.generate(this.form.value).subscribe(resp => {
        this.form.reset();
        this.template = 0;
        this.renderer.removeAttribute(this.generateBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.generateBtn.nativeElement, 'innerText', 'Generate Contract');
      });
    });
  }

  save(): void {
    this.renderer.setProperty(this.saveBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Saving...');
    
    this.form.patchValue({milestone: this.milestone});
    this.templatesService.save(this.form.get('template_name').value, this.form.value).subscribe(
      res => {
        this.form.get('template_name').patchValue('');
        this.renderer.removeAttribute(this.saveBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Save Template');
      });
  }

  load(): void {
    this.s.clear();
    this.d.clear();
    this.a.clear();

    const templateToUse = JSON.parse(this.templatesQuery.getEntity(this.template).template);
    templateToUse.start_date = new Date(templateToUse.start_date);
    templateToUse.end_date = new Date(templateToUse.end_date);
    templateToUse.activities.forEach(activity => {
      activity.start = new Date(activity.start);
      activity.end = new Date(activity.end);
    });
    
    this.milestone = templateToUse.milestone;
    Object.keys(templateToUse).forEach(name => {
      if (this.form.controls[name]) {
        this.form.get(name).patchValue(templateToUse[name]);
      }
    });

    for (const s of templateToUse.services) {
      this.addService();
    }
    for (const d of templateToUse.deliverables) {
      this.addDeliverable();
    }
    for (const a of templateToUse.activities) {
      this.addActivity();
    }

    this.s.patchValue(templateToUse.services);
    this.d.patchValue(templateToUse.deliverables);
    this.a.patchValue(templateToUse.activities);
  }

  delete(): void {
    this.templatesService.delete(this.template).subscribe();
    this.template = 0;
  }

}
