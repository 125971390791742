<div class="mb-3 card">
  <div class="card-body">
    <form [formGroup]="form" (ngSubmit)="update()">
      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Years of Experience</label>
            <select formControlName="yrsExp" class="custom-select">
              <option *ngFor="let exp of yearsExp" [ngValue]="exp.id">{{exp.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Hours</label>
            <select formControlName="hours" class="custom-select">
              <option [ngValue]="0">Part time</option>
              <option [ngValue]="1">Full time</option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Github Account</label>
        <input type="text" placeholder="Github Account" class="form-control" formControlName="github">
      </div>

      <div class="form-group">
        <label>Microsoft Account</label>
        <input type="text" placeholder="Microsoft Account" class="form-control" formControlName="microsoft">
      </div>

      <div class="form-group">
        <label>LinkedIn Profile URL</label>
        <input type="text" placeholder="LinkedIn" class="form-control" formControlName="linkedin">
      </div>

      <div class="form-group">
        <label>Portfolio URL</label>
        <input type="text" placeholder="Portfolio" class="form-control" formControlName="portfolio">
      </div>

      <button #saveBtn type="submit" class="btn btn-warning">Save</button>
    </form>
  </div>
</div>
