<form [formGroup]="form" (ngSubmit)="create()">
  <div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide(); close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label>Project Name</label>
      <input type="text" class="form-control" formControlName="name">
    </div>

    <div class="form-group">
      <label>Open Position</label>
      <input type="text" class="form-control" formControlName="position">
    </div>

    <div class="form-group">
      <label>Required Platforms, Frameworks, and Programming Language</label>
      <ng-select [items]="skills | async"
                 bindLabel="name"
                 bindValue="id"
                 [multiple]="true"
                 formControlName="platform"></ng-select>
    </div>

    <div class="form-group">
      <label>Scope of Work</label>
      <textarea class="form-control" formControlName="scope"></textarea>
    </div>

    <div class="form-group">
      <label>Budget Range</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">&#8369;</span>
        </div>
        <input type="text" class="form-control" formControlName="budgetLow">
        <input type="text" class="dash" value="-" disabled>
        <input type="text" class="form-control" formControlName="budgetHigh">
      </div>
    </div>

    <div class="form-group">
      <label>Cost Center</label>
      <ng-select [items]="costCenters"
                 bindLabel="cost_center_name"
                 bindValue="id"
                 formControlName="costCenterId"
                 placeholder="Select Cost Center">
        <ng-template ng-label-tmp let-item="item">
          {{item.cost_center_name}} | {{item.bu_head}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.cost_center_name}} | {{item.bu_head}}
        </ng-template>
      </ng-select>
    </div>

    <div class="form-group">
      <label>Project Manager</label>
      <input type="text" class="form-control" formControlName="pmEmail">
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide(); close()">Cancel</button>
    <button type="submit" #createBtn class="btn btn-warning">{{action}}</button>
  </div>
</form>
