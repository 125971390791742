import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectsService } from '../../../core/state/projects/projects.service';
import { FreelancersQuery } from '../../../core/state/freelancers/freelancers.query';
import { Project } from '../../../core/state/projects/project.model';
import { ProjectsQuery } from '../../../core/state/projects/projects.query';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { User } from '../../../core/state/user/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-assign-gig',
  templateUrl: './assign-gig.component.html',
  styleUrls: ['./assign-gig.component.scss']
})
export class AssignGigComponent implements OnInit {
  onClose: Subject<boolean>;
  @ViewChild('submitBtn') submitBtn: ElementRef;
  form: FormGroup;
  freelancerId: number;
  freelancers: User[];
  projects: Project[];
  readonly = false;

  constructor(private fb: FormBuilder,
              public bsModalRef: BsModalRef,
              private freelancersQuery: FreelancersQuery,
              private projectsQuery: ProjectsQuery,
              private projectsService: ProjectsService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      project_id: [null, Validators.required],
      freelancer_id: [this.freelancerId ? this.freelancerId : null, Validators.required],
      start_date: [null],
      end_date: [null]
    });

    this.freelancers = this.freelancersQuery.getAll();
    this.projects = this.projectsQuery.getAll();

    this.readonly = this.freelancerId !== undefined;
    this.onClose = new Subject();
  }

  assign(): void {
    this.renderer.setProperty(this.submitBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.submitBtn.nativeElement, 'innerText', 'Assigning...');
    this.projectsService.assign(this.form.value).subscribe(res => {
      this.onClose.next(true);
      this.bsModalRef.hide();
    });
  }

}
