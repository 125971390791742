import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CostCentersService } from '../../../core/state/cost-centers/cost-centers.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-cost-center-modal',
  templateUrl: './cost-center-modal.component.html',
  styleUrls: ['./cost-center-modal.component.scss']
})
export class CostCenterModalComponent implements OnInit {
  @ViewChild('submitBtn') submitBtn: ElementRef;
  form: FormGroup;
  title: string;
  costCenterToEdit: any;
  action: string;

  constructor(public bsModalRef: BsModalRef,
              private costCentersService: CostCentersService,
              private fb: FormBuilder,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [this.costCenterToEdit.id],
      cost_center: [this.costCenterToEdit.cost_center],
      cost_center_name: [this.costCenterToEdit.cost_center_name],
      bu_head: [this.costCenterToEdit.bu_head],
      bu_head_email: [this.costCenterToEdit.bu_head_email],
      bu_position: [this.costCenterToEdit.bu_position],
      bu_mobile: [this.costCenterToEdit.bu_mobile],
      valid_id: [this.costCenterToEdit.valid_id],
      id_details: [this.costCenterToEdit.id_details]
    });
  }

  submit(): void {
    this.renderer.setProperty(this.submitBtn.nativeElement, 'disabled', 'true');

    switch (this.action) {
      case 'Create':
        this.renderer.setProperty(this.submitBtn.nativeElement, 'innerText', 'Creating...');
        this.costCentersService.create(this.form.value).subscribe(
          res => { this.bsModalRef.hide(); });
        break;

      case 'Save':
        this.renderer.setProperty(this.submitBtn.nativeElement, 'innerText', 'Saving...');
        this.costCentersService.edit(this.form.value).subscribe(
          res => { this.bsModalRef.hide(); });
        break;

      default:
        this.renderer.setProperty(this.submitBtn.nativeElement, 'innerText', 'Deleting...');
        this.costCentersService.delete(this.form.get('id').value).subscribe(
          res => { this.bsModalRef.hide(); });
    }
  }

}
