import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '../../core/state/user/user.model';
import { ProfileService } from '../../core/services/profile.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {
  @ViewChild('saveBtn') saveBtn: ElementRef;
  @Input() user: User;
  userForm: FormGroup;

  constructor(private fb: FormBuilder,
              private renderer: Renderer2,
              private profileService: ProfileService) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      id: [this.user.id],
      firstName: [this.user.first_name],
      middleName: [this.user.middle_name],
      lastName: [this.user.last_name],
      username: [this.user.username],
      address: [this.user.profile.address],
      mobile: [this.user.profile.mobile]
    });
  }

  update(): void {
    this.renderer.setProperty(this.saveBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Saving...');

    this.profileService.updateInfo(this.userForm.value).subscribe(
      res => {
        this.renderer.removeAttribute(this.saveBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Save');
      },
      error => {
        this.renderer.removeAttribute(this.saveBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Save');
      }
    );
  }

}
