import { Component, OnInit } from '@angular/core';
import { UserQuery } from '../../state/user/user.query';
import { fieldStates, State } from '../../constants/FieldState';
import { UiQuery } from '../../state/ui/ui.query';
import { Observable } from 'rxjs';
import { UiService } from '../../state/ui/ui.service';
import { NAV } from '../../constants/Nav';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menus: any;
  state = State;
  sidebarOpen: Observable<boolean>;
  navigations = NAV;

  constructor(private userQuery: UserQuery,
              private uiQuery: UiQuery,
              private uiService: UiService) {
    this.menus = fieldStates[this.userQuery.getValue().account.role];
    this.sidebarOpen = this.uiQuery.sidebarOpen$;
  }

  ngOnInit(): void {
  }

  closeSidebar(): void {
    this.uiService.updateSidebarState(false);
  }

}
