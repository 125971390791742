<div class="mb-3 card">
  <div class="card-body">
    <app-pool-status [user]="user"></app-pool-status>

    <form [formGroup]="userForm" (ngSubmit)="update()">
      <div class="form-group">
        <label>Given name</label>
        <input type="text" placeholder="First name" class="form-control" formControlName="firstName">
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Middle name</label>
            <input type="text" placeholder="Middle name" class="form-control" formControlName="middleName">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Last name</label>
            <input type="text" placeholder="Last name" class="form-control" formControlName="lastName">
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Username</label>
        <input type="email" placeholder="Username" class="form-control" formControlName="username">
      </div>

      <div class="form-group">
        <label>Address</label>
        <input type="text" placeholder="Address" class="form-control" formControlName="address">
      </div>

      <div class="form-group">
        <label>Mobile</label>
        <input type="text" placeholder="Mobile" class="form-control" formControlName="mobile">
      </div>

      <button #saveBtn class="btn btn-warning">Save</button>
    </form>
  </div>
</div>
