import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { FreelancersQuery } from '../../../core/state/freelancers/freelancers.query';
import { FreelancersService } from '../../../core/state/freelancers/freelancers.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InvoicesService } from '../../../core/state/invoices/invoices.service';
import { User } from '../../../core/state/user/user.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {
  @ViewChild('savePowerpay') savePowerpay: ElementRef;
  @ViewChild('saveGcash') saveGcash: ElementRef;
  @ViewChild('saveInvoiceNum') saveInvoiceNum: ElementRef;
  freelancers: Observable<User[]>;
  id: number;
  freelancer: User;
  invoice: number;
  powerpay: string;
  gcash: string;
  lastInvoice: number;
  paymentTerm: string;

  constructor(public bsModalRef: BsModalRef,
              private freelancersQuery: FreelancersQuery,
              private freelancersService: FreelancersService,
              private invoicesService: InvoicesService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.freelancers = this.freelancersQuery.selectAll({sortBy: 'username'});
  }

  getPaymentTerm(): void {
    if (this.id) {
      this.freelancersQuery.selectEntity(this.id).subscribe(data => {
        this.freelancer = data;
        this.powerpay = this.freelancer.profile.powerpay_name;
        this.gcash = this.freelancer.profile.gcash;
        this.lastInvoice = this.freelancer.profile.last_invoice;
      });
    } else {
      this.powerpay = '';
      this.gcash = '';
      this.lastInvoice = null;
      this.paymentTerm = '';
    }
  }

  changeLastInvoice(): void {
    this.renderer.setProperty(this.saveInvoiceNum.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.saveInvoiceNum.nativeElement, 'innerText', 'Saving...');
    this.freelancersService.changeLastInvoice(this.id, this.invoice).subscribe(
      res => {
        this.renderer.removeAttribute(this.saveInvoiceNum.nativeElement, 'disabled');
        this.renderer.setProperty(this.saveInvoiceNum.nativeElement, 'innerText', 'Save');
      });
  }
  
  editPowerpayName(): void {
    this.renderer.setProperty(this.savePowerpay.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.savePowerpay.nativeElement, 'innerText', 'Saving...');
    this.freelancersService.editPowerpayName(this.id, this.powerpay).subscribe(
      res => {
        this.renderer.removeAttribute(this.savePowerpay.nativeElement, 'disabled');
        this.renderer.setProperty(this.savePowerpay.nativeElement, 'innerText', 'Save');
      });
  }

  editGcash(): void {
    this.renderer.setProperty(this.saveGcash.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.saveGcash.nativeElement, 'innerText', 'Saving...');
    this.freelancersService.editGcash(this.id, this.gcash).subscribe(
      res => {
        this.renderer.removeAttribute(this.saveGcash.nativeElement, 'disabled');
        this.renderer.setProperty(this.saveGcash.nativeElement, 'innerText', 'Save');
      });
  }

}
