import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccountComponent } from './account/account.component';
import { DeleteModalComponent } from './account/delete-modal/delete-modal.component';
import { DevAccountComponent } from './dev-account/dev-account.component';
import { InterviewScheduleComponent } from './interview-schedule/interview-schedule.component';
import { DeclineModalComponent } from './interview-schedule/decline-modal/decline-modal.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { PoolStatusComponent } from './pool-status/pool-status.component';
import { SkillsComponent } from './skills/skills.component';
import { UploadCvComponent } from './upload-cv/upload-cv.component';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../shared/shared.module';
import { SupportComponent } from './support/support.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { SignContractComponent } from './sign-contract/sign-contract.component';
import { PrintInvoiceComponent } from './create-invoice/print-invoice/print-invoice.component';
import { MyBirFormsComponent } from './my-bir-forms/my-bir-forms.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    AccountComponent,
    DeleteModalComponent,
    DevAccountComponent,
    InterviewScheduleComponent,
    DeclineModalComponent,
    PaymentDetailsComponent,
    PersonalInfoComponent,
    PoolStatusComponent,
    ProfileComponent,
    SkillsComponent,
    UploadCvComponent,
    SupportComponent,
    CreateInvoiceComponent,
    SignContractComponent,
    PrintInvoiceComponent,
    MyBirFormsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    NgSelectModule,
    FontAwesomeModule,
    SharedModule,
    NgxPaginationModule,
    TooltipModule
  ]
})
export class FreelancerModule {}
