import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserQuery } from '../../core/state/user/user.query';
import { UserService } from '../../core/state/user/user.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginBtn') loginBtn: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  loginForm: FormGroup;
  incorrectLogin: boolean;
  unregisteredEmail: boolean;
  redirectUrl: string;

  constructor(private fb: FormBuilder,
              private router: Router,
              private renderer: Renderer2,
              private route: ActivatedRoute,
              private userQuery: UserQuery,
              private userService: UserService) { }

  ngOnInit(): void {
    this.redirectUrl = this.route.snapshot.params.redirectUrl || '';

    if (this.userQuery.getValue().token !== '') {
      this.router.navigate(['']);
    }

    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  login(): void {
    this.unregisteredEmail = false;
    this.incorrectLogin = false;
    this.renderer.setProperty(this.loginBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.loginBtn.nativeElement, 'innerText', 'Logging in...');

    this.userService.login(this.loginForm.value).subscribe(
      res => {
        this.router.navigate([this.redirectUrl]);
      },
      error => {
        (error.status === 404) ? this.unregisteredEmail = true : this.incorrectLogin = true;
        this.renderer.removeAttribute(this.loginBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.loginBtn.nativeElement, 'innerText', 'Login to Dashboard');
      }
    );
  }

  togglePassword(): void {
    this.passwordInput.nativeElement.type === 'password' ?
      this.renderer.setAttribute(this.passwordInput.nativeElement, 'type', 'text') :
      this.renderer.setAttribute(this.passwordInput.nativeElement, 'type', 'password');
  }

}
