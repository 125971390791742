import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CostCenter } from '../../core/state/cost-centers/cost-center.model';
import { CostCentersQuery } from '../../core/state/cost-centers/cost-centers.query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CostCenterModalComponent } from './cost-center-modal/cost-center-modal.component';

@Component({
  selector: 'app-cost-centers',
  templateUrl: './cost-centers.component.html',
  styleUrls: ['./cost-centers.component.scss']
})
export class CostCentersComponent implements OnInit {
  bsModalRef: BsModalRef;
  costCenters: Observable<CostCenter[]>;

  constructor(private costCentersQuery: CostCentersQuery,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.costCenters = this.costCentersQuery.selectAll(
      {sortBy: 'cost_center', filterBy: entity => entity.cost_center !== 'RTP'});
  }

  create(): void {
    const initialState = {
      title: 'Create Cost Center',
      costCenterToEdit: {cost_center: '', cost_center_name: '', bu_head: '', bu_head_email: ''},
      action: 'Create'
    };
    this.bsModalRef = this.modalService.show(CostCenterModalComponent, {initialState});
  }

  edit(costCenter): void {
    const initialState = {
      title: 'Edit Cost Center',
      costCenterToEdit: costCenter,
      action: 'Save'
    };
    this.bsModalRef = this.modalService.show(CostCenterModalComponent, {initialState});
  }

  delete(costCenter): void {
    const initialState = {
      title: 'Delete Cost Center',
      costCenterToEdit: costCenter,
      action: 'Delete'
    };
    this.bsModalRef = this.modalService.show(CostCenterModalComponent, {initialState});
  }

}
