import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ROLE, ROLES } from '../../core/constants/Role';
import { SkillsQuery } from '../../core/state/skills/skills.query';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UserService } from '../../core/state/user/user.service';
import { Experience, YEARS_OF_EXPERIENCE } from '../../core/constants/Experience';
import { NgFormsManager } from '@ngneat/forms-manager';
import { Helper } from '../../core/utils/helper';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @ViewChild('registerBtn') registerBtn: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('customLabel') customLabel: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  bsModalRef: BsModalRef;
  url: string;
  roles = ROLES;
  skills: any;
  userForm: FormGroup;
  matchPattern: boolean;
  emailExist: boolean;
  emailNotSent: boolean;
  verifiedOtp: boolean;
  username: string;
  mobile: string;
  enable: boolean;
  resume: any;
  resumePdf = true;
  yearsExp = YEARS_OF_EXPERIENCE;

  constructor(private fb: FormBuilder,
              private formsManager: NgFormsManager,
              private modalService: BsModalService,
              private renderer: Renderer2,
              private router: Router,
              private skillsQuery: SkillsQuery,
              private userService: UserService) {
    this.url = router.url;
  }

  ngOnInit(): void {
    this.skills = this.skillsQuery.selectAll({sortBy: 'name'});
    this.userForm = this.fb.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      role: [this.url === '/register' ? ROLE.DEVELOPER : ROLE.HR],
      mobile: [null],
      skills: [null],
      yrsExp: [Experience['Less than 1 year']],
      cv: [null],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      agreePolicy: [false, Validators.requiredTrue]
    });

    this.formsManager.upsert('register', this.userForm);

    if (this.url === '/register') {
      this.userForm.get('firstName').setValidators(Validators.required);
      this.userForm.get('lastName').setValidators(Validators.required);
      this.userForm.get('mobile').setValidators(Validators.required);
      this.userForm.get('skills').setValidators(Validators.required);
      this.userForm.get('cv').setValidators(Validators.required);
    }

    if (this.url === '/cortex/signup') {
      this.userForm.get('username').setValidators(
        [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@(cortex|cbsi|cic|ccac)\.com.ph$')]);
    }

    this.onChanges();
  }

  get f(): any { return this.userForm.controls; }

  onChanges(): void {
    this.userForm.valueChanges.subscribe(val => {
      this.username = val.username;
      this.mobile = val.mobile;
      this.enable = this.userForm.valid;
    });
  }

  onMatchPattern(event): void {
    this.matchPattern = event;
  }

  onVerify(verified: boolean): void {
    this.verifiedOtp = verified;
  }

  onFileChange(event): void {
    const fileName = this.fileInput.nativeElement.files[0].name;
    this.renderer.setProperty(this.customLabel.nativeElement, 'innerText', fileName);

    if (event.target.files.length > 0) {
      this.resume = event.target.files[0];
      this.userService.storeResume(this.resume);
      this.resumePdf = event.target.files[0].type === 'application/pdf';
    }
  }

  register(): void {
    this.renderer.setProperty(this.registerBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.registerBtn.nativeElement, 'innerText', 'Creating account...');
    this.emailExist = false;

    this.userService.checkEmail(this.username).subscribe(
      res => {
        this.emailExist = true;
        this.renderer.removeAttribute(this.registerBtn.nativeElement, 'disabled');
        this.renderer.setProperty(this.registerBtn.nativeElement, 'innerText', 'Create Account');
      },
      error => {
        if (this.url === '/cortex/signup') {
          this.userService.register(this.userForm.value).subscribe(
            res => {
              this.router.navigate(['']);
            });

        } else {
          Helper.isNextStep = true;
          this.router.navigate(['quiz']);
        }
      }
    );
  }

  openPrivacyPolicy(): void {
    this.bsModalRef = this.modalService.show(PrivacyPolicyComponent);
  }

  togglePassword(): void {
    this.passwordInput.nativeElement.type === 'password' ?
      this.renderer.setAttribute(this.passwordInput.nativeElement, 'type', 'text') :
      this.renderer.setAttribute(this.passwordInput.nativeElement, 'type', 'password');
  }

}
