import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../core/state/user/user.model';
import { FreelancersQuery } from '../../core/state/freelancers/freelancers.query';
import { TERM } from '../../core/constants/Term';

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss']
})
export class ContractDetailsComponent implements OnInit {
  freelancers: Observable<User[]>;
  p = 1;
  term = TERM;
  
  constructor(private freelancersQuery: FreelancersQuery) { }

  ngOnInit(): void {
    this.freelancers = this.freelancersQuery.selectAll({
      filterBy: entity => entity.profile?.current_contract !== null,
      sortBy: 'first_name'
    });
  }

}
