<div class="modal-header">
  <h5 class="modal-title">Confirm Contract</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <h6>Please check if all contract details are correct.</h6>
  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group">
        <div>Freelancer Name: <strong>{{formValue.name}}</strong></div>
        <p>Freelancer Address: <strong>{{formValue.address}}</strong></p>

        <div>Project Name: <strong>{{project.name}}</strong></div>
        <div>Position: <strong>{{project.position}}</strong></div>
        <div>Agreed Rate: <strong>{{formValue.agreed_rate}}, {{term[formValue.payment_term]}}</strong></div>
        <div>Duration: <strong>{{formValue.duration}}</strong></div>
        <div>Contract Start: <strong>{{formValue.start_date | date: 'mediumDate'}}</strong></div>
        <p>Contract End: <strong>{{formValue.end_date | date: 'mediumDate'}}</strong></p>

        <div>Cost Center: <strong>{{costCenter.cost_center_name}}</strong></div>
        <div>BU Head: <strong>{{formValue.bu_head}}</strong></div>
        <div>BU Position: <strong>{{formValue.bu_position}}</strong></div>
        <div>BU Contact: <strong>{{formValue.bu_mobile}}</strong></div>
        <div>Valid ID Number: <strong>{{formValue.bu_id}}</strong></div>
        <div>Location and Date of Expiry: <strong>{{formValue.bu_id_details}}</strong></div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        Services:
        <div *ngFor="let s of formValue.services; let i = index">
          <span>{{i + 1}}. </span>{{s}}
        </div>
      </div>

      <div class="form-group">
        Deliverables:
        <div *ngFor="let d of formValue.deliverables; let i = index">
          <span>{{i + 1}}. </span>{{d}}
        </div>
      </div>

      <div class="form-group">
        {{formValue.milestone ? 'Milestones:' : 'Activities:'}}
        <div *ngFor="let a of formValue.activities">
          {{a.milestone}}
          {{formValue.milestone ?
            ' - Php' + a.due : ' (' + (a.start | date: 'mediumDate') + '-' + (a.end | date: 'mediumDate') + ')'}}
        </div>
      </div>
    </div>
  </div>

  <p><strong>Note:</strong> You can opt to send a test document first to the approvers(HR and BU Head).
    An email will be sent to you if they approve so you can create the actual contract after.</p>

  <p>If you are already sure, click <strong>Send for Actual Signing</strong>.</p>
</div>

<div class="modal-footer justify-content-between">
  <button class="btn btn-primary" (click)="actualSend()">Send for Actual Signing</button>
  <button class="btn btn-warning" (click)="testDoc()">Send Test Document</button>
</div>
