<div class="card">
  <div class="card-header">
    BIR Form 2307
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col"></th>
        <th>Period</th>
        <th>File</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let form of forms | paginate: {itemsPerPage: 10, currentPage: p}">
        <th scope="row">
          <a class="mr-2" (click)="download(form.id)">
            <img src="assets/img/download.svg" tooltip="Download"></a>
        </th>
        <td>{{form.period}}</td>
        <td>{{form.filename}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</div>
