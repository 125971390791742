<div class="card">
  <div class="card-header">
    Interview Pool
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col"></th>
        <th>Name</th>
        <th>Skills</th>
        <th>Interview Schedule</th>
        <th>Interview Status</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let candidate of candidates | async | paginate: {itemsPerPage: 10, currentPage: p}; let i=index">
        <th scope="row">
          <a href="/profile/{{candidate.id}}" target="_blank"><img src="assets/img/eye.svg" tooltip="See Profile"></a>
        </th>
        <td>{{candidate.first_name}} {{candidate.last_name}}</td>
        <td>
          <div *ngFor="let skill of candidate.profile.skills"
               [ngClass]="{'font-weight-bold': skill.confirmed}">{{skill.skill_name}}</div>
        </td>
        <td>{{candidate.profile?.interview?.schedule | date: 'MMM d, y, h:mm a'}}</td>
        <td>{{interviewStatus[candidate.profile?.interview?.status]}}</td>
        <td>
          <button type="button" class="btn btn-warning" (click)="cancel(candidate.profile.interview.id); clicked = i;"
                  [disabled]="clicked === i" tooltip="Cancel Interview"
                  *ngIf="candidate.profile?.interview?.status === interviewVal.INVITED">
            Cancel
          </button>

          <button type="button" class="btn btn-warning" (click)="confirm(candidate.id); clicked = i;"
                  [disabled]="clicked === i" tooltip="Confirm Proposed Schedule"
                  *ngIf="candidate.profile?.interview?.status === interviewVal.RESCHEDULED">
            Confirm
          </button>

          <div class="btn-group" *ngIf="candidate.profile?.interview?.status === interviewVal.CONFIRMED">
            <button class="btn btn-success" (click)="changeInterviewStatus(candidate.id, interviewVal.ENDORSED)">
              Accept
            </button>
            <button class="btn btn-danger" (click)="changeInterviewStatus(candidate.id, interviewVal.FAILED)">
              Fail
            </button>
            <button class="btn btn-primary" (click)="changeInterviewStatus(candidate.id, interviewVal.NO_SHOW)">
              No Show
            </button>
          </div>

        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</div>
