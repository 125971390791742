<div class="card">
  <div class="card-header">
    Contracts Details
  </div>

  <div class="table-responsive">
    <table class="table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th>Freelancer</th>
        <th>Contract Start</th>
        <th>Contract End</th>
        <th>Payment Term</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let freelancer of freelancers | async | paginate: {itemsPerPage: 10, currentPage: p}">
        <td>{{freelancer.first_name}} {{freelancer.last_name}}</td>
        <td>{{freelancer.profile?.current_contract?.start_date}}</td>
        <td>{{freelancer.profile?.current_contract?.end_date}}</td>
        <td>{{term[freelancer.profile?.current_contract?.payment_term]}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer d-flex justify-content-between">
    <pagination-controls (pageChange)="p = $event" class="d-inline-block"></pagination-controls>
  </div>
</div>
