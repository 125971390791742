import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ProjectsState, ProjectsStore } from './projects.store';
import { UserQuery } from '../user/user.query';
import { ROLE } from '../../constants/Role';
import { Observable } from 'rxjs';
import { Project } from './project.model';

@Injectable({providedIn: 'root'})
export class ProjectsQuery extends QueryEntity<ProjectsState> {

  constructor(protected store: ProjectsStore,
              private userQuery: UserQuery) {
    super(store);
  }

  selectByLead(): Observable<Project[]> {
    return this.selectAll({
      filterBy: entity => this.userQuery.getValue().account.role === ROLE.PM ?
        entity.lead_id === this.userQuery.getValue().account.id : true,
      sortBy: 'name'
    });
  }

}
