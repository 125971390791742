<form [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="d-flex h-100 justify-content-center align-items-center min-vh-100">
    <div class="mx-auto app-login-box col-md-8">

      <div class="logo text-center">
        <img src="assets/img/logo.png" alt="logo">
      </div>

      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <div>Welcome back,</div>
                <span>Please sign in to your account below.</span>
              </h4>
            </div>

            <div class="form-group">
              <input type="email" formControlName="username" placeholder="Enter email" class="form-control">
              <small *ngIf="unregisteredEmail" tabindex="-1" class="form-text text-muted font-weight-bold">
                Email does not exist.
              </small>
            </div>
            <div class="form-group">
              <div class="password">
                <input #passwordInput type="password" formControlName="password" placeholder="Enter password"
                       class="form-control">
                <img src="assets/img/eye.svg" (click)="togglePassword()">
              </div>
              <small *ngIf="incorrectLogin" tabindex="-1" class="form-text text-muted font-weight-bold">
                Your email or password is incorrect.
              </small>
            </div>

            <div class="divider"></div>
            <h6 class="mb-0">No account?
              <a class="text-primary" routerLink="/cortex/signup">
                Register as <strong>Cortex User</strong></a> |
              <a class="text-primary" routerLink="/register">Register as <strong>Freelancer</strong></a>
            </h6>
          </div>

          <div class="modal-footer">
            <div class="float-left">
              <a class="btn-lg btn btn-link" routerLink="/forgot/password">Recover Password</a>
            </div>
            <div class="float-right">
              <button #loginBtn type="submit" class="btn btn-warning btn-lg" [disabled]="!loginForm.valid">
                Login to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
