import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from '../../../core/state/user/user.model';
import { Project } from '../../../core/state/projects/project.model';
import { ProjectsService } from '../../../core/state/projects/projects.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-unlink-project',
  templateUrl: './unlink-project.component.html',
  styleUrls: ['./unlink-project.component.scss']
})
export class UnlinkProjectComponent implements OnInit {
  onClose: Subject<boolean>;
  @ViewChild('submitBtn') submitBtn: ElementRef;
  id: number;
  freelancer: User;
  project: Project;

  constructor(public bsModalRef: BsModalRef,
              private projectsService: ProjectsService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  unlink(): void {
    this.renderer.setProperty(this.submitBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.submitBtn.nativeElement, 'innerText', 'Processing...');
    this.projectsService.unlink(this.id, this.project.id).subscribe(res => {
      this.onClose.next(true);
      this.bsModalRef.hide();
    });
  }

}
