<div class="app-container">
  <app-header></app-header>
  <app-sidebar></app-sidebar>

  <div class="app-main__outer">
    <div class="app-main__inner">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
