import { Injectable } from '@angular/core';
import { QuestionsStore } from './questions.store';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class QuestionsService {

  constructor(private http: HttpClient,
              private questionsStore: QuestionsStore) { }

  get(): any {
    return this.http.get(`${environment.api}questions`).pipe(tap(
      (res: any) => this.questionsStore.set(res.questions)));
  }

  editQuestion(item): any {
    return this.http.post(`${environment.api}edit/quiz`, item).pipe(tap(
      (res: any) => this.questionsStore.set(res.questions)));
  }

}
