<div class="modal-header">
  <h5 class="modal-title">Send RTP for signing</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  Send <strong>{{ rtpToSend.filename }}</strong> to<br>
  BU Heads and Finance for signing?
</div>

<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
  <button type="submit" #submitBtn class="btn btn-warning" (click)="sendToBUHeads()">Send</button>
</div>
