import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserQuery } from '../../app/core/state/user/user.query';

@Injectable()
export class CanActivateLoginGuard implements CanActivate {

  constructor(private userQuery: UserQuery,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer: any) => {
      const canActivate = this.userQuery.getValue().token !== '';

      if (!canActivate) {
        return this.router.navigate(['login', {redirectUrl: state.url}]);
      }

      observer.next(canActivate);
      return observer.complete();
    });
  }
}
