<div class="app-page-title">
  <div class="page-title-heading">
    <div class="form-group">
      <table class="table table-borderless mb-0">
        <tbody>
        <tr *ngIf="currentContract">
          <th scope="row">
            <a class="mr-2" (click)="download(currentContract.id)">
              <img src="assets/img/download.svg" tooltip="Download"></a>
          </th>
          <td>{{currentContract.filename}}</td>
        </tr>
        <tr *ngIf="newContract && (newContract.signers === 0 || newContract.signers == null)">
          <th scope="row">
            <a class="mr-2" (click)="download(newContract.id)">
              <img src="assets/img/download.svg" tooltip="Download"></a>
          </th>
          <td>{{newContract.filename}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<div id="container" [ngClass]="{'d-none': contract.signers === 0}"></div>
