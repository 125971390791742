import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(private http: HttpClient) { }

  sendMobileOtp(user): any {
    return this.http.post(`${environment.api}sendSMS`, user);
  }

  sendEmailOtp(user): any {
    return this.http.post(`${environment.api}sendEmail`, user);
  }

  verifyOtp(user): any {
    return this.http.post(`${environment.api}verify/otp`, user);
  }

}
