import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SkillsStore, SkillsState } from './skills.store';

@Injectable({ providedIn: 'root' })
export class SkillsQuery extends QueryEntity<SkillsState> {

  constructor(protected store: SkillsStore) {
    super(store);
  }

}
