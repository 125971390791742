import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UiState, UiStore } from './ui.store';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UiQuery extends Query<UiState> {
  sidebarOpen$: Observable<boolean>;

  constructor(protected store: UiStore) {
    super(store);
    this.sidebarOpen$ = this.select('sidebarOpen');
  }

}
