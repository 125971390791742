import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractsService } from '../../../core/state/contracts/contracts.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

declare var eversign: any;

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-sign-page',
  templateUrl: './sign-page.component.html',
  styleUrls: ['./sign-page.component.scss']
})
export class SignPageComponent implements OnInit {
  bsModalRef: BsModalRef;
  url: string;
  id: number;
  role: string;

  constructor(private contractsService: ContractsService,
              private modalService: BsModalService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.url = params.url;
      this.id = params.id;
      this.role = params.role;
    });

    eversign.open({
      url: this.url,
      containerID: 'container',
      width: 900,
      height: 600,
      events: {
        signed: () => {
          switch (this.role) {
            case 'hr':
              this.sendToBU();
              break;
            case 'bu':
              this.sendToFreelancer();
              break;
            default: // Do nothing
          }
        }
      }
    });
  }

  sendToBU(): void {
    this.contractsService.sendToBU(this.id).subscribe();
  }

  sendToFreelancer(): void {
    this.contractsService.sendToFreelancer(this.id).subscribe();
  }

}
