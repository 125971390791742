import { Injectable } from '@angular/core';
import { InvoicesStore } from './invoices.store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { InvoicesQuery } from './invoices.query';
import { RtpsStore } from '../rtps/rtps.store';

@Injectable({providedIn: 'root'})
export class InvoicesService {

  constructor(private http: HttpClient,
              private invoicesQuery: InvoicesQuery,
              private invoicesStore: InvoicesStore,
              private rtpsStore: RtpsStore) { }
  
  get(): any {
    return this.http.get(`${environment.api}invoices`).pipe(tap(
      (res: any) => {
        console.log(res.invoices.data)
        this.invoicesStore.set(res.invoices.data);
      }));
  }

  generate(monthObj): any {
    const params = {
      code: localStorage.getItem('harvest_token'),
      accountId: localStorage.getItem('account_id'),
      month: monthObj,
      year: localStorage.getItem('year')
    };
    console.log(params)

    return this.http.post(`${environment.api}create/invoice`, params);
  }

  download(id): any {
    return this.http.get(`${environment.api}download/invoice/${id}`).pipe(map(
      (res: any) => res.file));
  }

  generateAll(monthParam, yearParam): any {
    console.log(monthParam, yearParam, environment.api)
    const params = {
      month: monthParam,
      year: yearParam
    };

    return this.http.post(`${environment.api}generate/invoices`, params).pipe(tap(
      (res: any) => {
        console.log(res)
        res.invoices.map(invoice => {
          if (this.invoicesQuery.hasEntity(invoice.id)) {
            this.invoicesStore.replace(invoice.id, invoice);
          } else {
            this.invoicesStore.add(invoice);
          }
        });
      }),
      map((res: any) => res.file));
  }

  markInvoice(id): any {
    return this.http.get(`${environment.api}mark/invoice/${id}`).pipe(tap(
      (res: any) => {
        this.invoicesStore.replace(res.invoice.id, res.invoice);
      }));
  }
  
  generateMilestone(data): any {
    return this.http.post(`${environment.api}generate/milestone`, data).pipe(tap(
      (res: any) => {
        res.invoices.map(invoice => {
          this.invoicesStore.add(invoice);
        });
        this.rtpsStore.add(res.rtp);
      }),
      map((res: any) => res.file));
  }
  
}
