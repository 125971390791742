import { Component, OnInit } from '@angular/core';
import { UserQuery } from '../../core/state/user/user.query';
import { User } from '../../core/state/user/user.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { INTERVIEW_STATUS } from '../../core/constants/InterviewStatus';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user: User;
  interviewStatus = INTERVIEW_STATUS;

  constructor(private userQuery: UserQuery) { }

  ngOnInit(): void {
    this.userQuery.select().subscribe($user => {
      this.user = $user.account;
    });
  }

}
