import { Injectable } from '@angular/core';
import { UiStore } from './ui.store';

@Injectable({providedIn: 'root'})
export class UiService {

  constructor(private uiStore: UiStore) { }

  saveNextPage(url: string): void {
    this.uiStore.update({nextPageUrl: url});
  }

  saveLastPage(totalItems: number): void {
    const lastPageNum = (totalItems % 10 === 0) ? (totalItems / 10) : (Math.floor(totalItems / 10) + 1);
    this.uiStore.update({lastPage: lastPageNum});
  }

  updateSidebarState(state: boolean): void {
    this.uiStore.update({sidebarOpen: state});
  }

}
