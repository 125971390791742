<div class="app-page-title">
  <div class="form-group text-nowrap">
    <h6 class="d-inline-block text-uppercase">Generate Contract</h6>
    <select [(ngModel)]="milestone" class="ml-4 custom-select w-auto" (ngModelChange)="changeTerm()">
      <option [ngValue]="false">Regular</option>
      <option [ngValue]="true">Milestone</option>
    </select>
  </div>
  <div>
    <select class="custom-select w-auto form-group" [(ngModel)]="template" (ngModelChange)="load()">-->
      <option [ngValue]="0" disabled selected>Select Template</option>
      <option *ngFor="let template of templates | async" [ngValue]="template.id">{{template.name}}</option>
    </select>
    <button type="button" class="btn btn-danger ml-2 form-group" *ngIf="template !== 0" (click)="delete()">
      Delete Template
    </button>
  </div>
</div>


<form [formGroup]="form">
  <div class="card mb-3">
    <div class="card-header">
      Freelancer
    </div>

    <div class="card-body">
      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Select Freelancer</label>
            <ng-select [items]="freelancers | async"
                       bindLabel="search_string"
                       bindValue="id"
                       formControlName="freelancer_id"
                       placeholder="Select Freelancer"
                       (change)="getNameAddress()">
              <ng-template ng-label-tmp let-item="item">
                {{item.first_name}} {{item.last_name}} | {{item.username}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                {{item.first_name}} {{item.last_name}} | {{item.username}}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Name</label>
            <input type="text" placeholder="Freelancer Name" class="form-control" formControlName="name">
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Address</label>
        <input type="text" placeholder="Freelancer Address" class="form-control" formControlName="address">
      </div>
    </div>
  </div>


  <div class="card mb-3">
    <div class="card-header">
      Gig
    </div>

    <div class="card-body">
      <div class="form-group">
        <label>Select Gig</label>
        <ng-select [items]="projects | async"
                   bindLabel="name"
                   bindValue="id"
                   formControlName="project_id"
                   placeholder="Select Gig"
                   (change)="getCostCenter()"></ng-select>
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Position</label>
            <input type="text" readonly formControlName="position" class="form-control" placeholder="Position">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Rate</label>
            <input type="text" formControlName="agreed_rate" class="form-control" placeholder="Agreed Rate">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Payment</label>
            <select formControlName="payment_term" class="custom-select">
              <option [ngValue]="0" *ngIf="!milestone">hourly</option>
              <option [ngValue]="1" *ngIf="!milestone">fixed</option>
              <option [ngValue]="2" *ngIf="milestone">milestone</option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Duration</label>
            <input type="text" formControlName="duration" class="form-control" placeholder="Duration">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Contract Start</label>
            <input type="text" placeholder="Select start date" class="form-control" formControlName="start_date"
                   bsDatepicker [bsConfig]="{showWeekNumbers: false}">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Contract End</label>
            <input type="text" placeholder="Select end date" class="form-control" formControlName="end_date"
                   bsDatepicker [bsConfig]="{showWeekNumbers: false}">
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card mb-3">
    <div class="card-header">
      Cost Center
    </div>

    <div class="card-body">
      <div class="form-row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Cost Center</label>
            <ng-select [items]="costCenters"
                       bindLabel="cost_center_name"
                       bindValue="id"
                       formControlName="cost_center"
                       placeholder="Cost Center"
                       (change)="getBU()">
              <ng-template ng-label-tmp let-item="item">
                {{item.cost_center_name}} | {{item.bu_head}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                {{item.cost_center_name}} | {{item.bu_head}}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>BU Head</label>
            <input type="text" formControlName="bu_head" class="form-control" placeholder="BU Head">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>BU Position</label>
            <input type="text" formControlName="bu_position" class="form-control" placeholder="BU Position">
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-4">
          <div class="form-group">
            <label>BU Contact</label>
            <input type="text" formControlName="bu_mobile" class="form-control" placeholder="BU Contact">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Valid ID Number</label>
            <input type="text" formControlName="bu_id" class="form-control" placeholder="Valid ID Number">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Location and Date of Expiry</label>
            <input type="text" formControlName="bu_id_details" class="form-control"
                   placeholder="Location and Date of Expiry">
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card">
    <div class="card-header">
      Details
    </div>

    <div class="card-body">
      <div class="form-group">
        <label>SERVICES</label>
        <div formArrayName="services" *ngFor="let service of s.controls; let i = index;"
             class="d-flex align-items-center form-group">
          <span>{{i + 1}}.</span>
          <input type="text" [formControlName]="i" class="form-control flex-grow-1" placeholder="Service">
          <button type="button" class="btn btn-default delete-btn" (click)="i > 0 ? addService(i) : null">
            <img src="assets/img/delete.svg" *ngIf="i > 0">
          </button>
        </div>
        <button type="button" class="btn btn-success" (click)="addService()">Add Service</button>
      </div>

      <div class="form-group">
        <label>DELIVERABLES</label>
        <div formArrayName="deliverables" *ngFor="let del of d.controls; let i = index;"
             class="d-flex align-items-center form-group">
          <span>{{i + 1}}.</span>
          <input type="text" [formControlName]="i" class="form-control flex-grow-1" placeholder="Deliverable">
          <button type="button" class="btn btn-default delete-btn" (click)="i > 0 ? addDeliverable(i) : null">
            <img src="assets/img/delete.svg" *ngIf="i > 0">
          </button>
        </div>
        <button type="button" class="btn btn-success" (click)="addDeliverable()">Add Deliverable</button>
      </div>

      <div class="form-group">
        <label>{{ milestone ? 'MILESTONES' : 'ACTIVITIES' }}</label>
        <div formArrayName="activities" *ngFor="let activity of a.controls; let i = index;">
          <div [formGroupName]="i">
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" formControlName="milestone" class="form-control"
                         placeholder="{{ milestone ? 'Milestone' : 'Activity' }}">
                </div>
              </div>
              <div class="col" *ngIf="milestone">
                <div class="form-group d-flex">
                  <input type="text" formControlName="due" class="form-control flex-grow-1" placeholder="Payment Due">
                </div>
              </div>
              <div class="col">
                <div class="form-group d-flex">
                  <input type="text" placeholder="Start Date" class="form-control" formControlName="start"
                         bsDatepicker [bsConfig]="{showWeekNumbers: false}">
                </div>
              </div>
              <div class="col">
                <div class="form-group d-flex">
                  <input type="text" placeholder="End Date" class="form-control" formControlName="end"
                         bsDatepicker [bsConfig]="{showWeekNumbers: false}">
                  <button type="button" class="btn btn-default delete-btn" (click)="i > 0 ? addActivity(i) : null">
                    <img src="assets/img/delete.svg" *ngIf="i > 0">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-success" (click)="addActivity()">
          Add {{ milestone ? 'Milestone' : 'Activity' }}</button>
      </div>
    </div>

    <div class="d-block card-footer">
      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <input class="form-control" placeholder="Template Name" formControlName="template_name">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <button #saveBtn type="button" class="btn btn-primary" (click)="save()">Save Template</button>
          </div>
        </div>
      </div>

      <button #generateBtn type="submit" class="btn btn-warning btn-lg" [disabled]="!form.valid" (click)="generate()">
        Generate Contract
      </button>
    </div>
  </div>
</form>
