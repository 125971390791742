import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  link: string;
  email: string;

  constructor() { }

  ngOnInit(): void {
    this.link = `mailto:${environment.supportEmail}`;
    this.email = environment.supportEmail;
  }

}
