<div class="app-page-title">
  <div class="form-group d-inline-block">
    <select [(ngModel)]="year" class="custom-select w-auto mr-2">
      <option *ngFor="let year of years;" value="{{year}}">{{year}}</option>
    </select>

    <select [(ngModel)]="month" class="custom-select w-auto mr-2">
      <option *ngFor="let period of months" value="{{period.text}}">{{period.text}}</option>
    </select>
  </div>

  <div class="form-group d-inline-block">
    <button #submitBtn class="btn btn-primary mr-2" (click)="getReceipts()">Get ARs / ORs</button>
  </div>

  <div class="form-group d-inline-block">
    <button class="btn btn-success" (click)="openModal()">Upload BIR 2307</button>
  </div>
</div>


<div class="card">
  <div class="card-header">
    Receipts
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col"></th>
        <th>Freelancer</th>
        <th>Period</th>
        <th>Submitted?</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let invoice of invoices | paginate: {itemsPerPage: 10, currentPage: p}">
        <th scope="row">
          <a class="mr-2" *ngIf="invoice.$receiptId" (click)="download(invoice.$receiptId)">
            <img src="assets/img/download.svg" tooltip="Download"></a>
        </th>
        <td>{{invoice.freelancer}}</td>
        <td>{{invoice.period}}</td>
        <td>
          <span *ngIf="invoice.$acknowledged">Yes</span>
          <span *ngIf="!invoice.$acknowledged" class="no-text">No</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</div>
