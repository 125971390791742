import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { User } from '../user/user.model';

export interface FreelancersState extends EntityState<User> {}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'freelancers'})
export class FreelancersStore extends EntityStore<FreelancersState> {

  constructor() {
    super();
  }

}
