<div class="app-page-title" *ngIf="fields['downloadFreelancers'] === state.VISIBLE">
  <div class="form-group d-inline-block">
    <button type="button" class="btn btn-warning mr-2" [disabled]="clicked"
            (click)="exportFreelancers(true); clicked=true">
      Export Freelancers
    </button>
  </div>
  <div class="form-group d-inline-block">
    <button type="button" class="btn btn-success" [disabled]="clicked" (click)="exportFreelancers(false); clicked=true">
      Export without Projects
    </button>
  </div>
</div>


<div class="card">
  <div class="card-header">
    Talent Pool
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Name</th>
        <th scope="col">
          Skills<br>
          <select class="mt-1" (change)="filterSort(0, $event)">
            <option value="">All</option>
            <option *ngFor="let skill of skills | async" value="{{skill.id}}">{{skill.name}}</option>
          </select>
        </th>
        <th scope="col">
          Years of Experience
          <img (click)="filterSort(4)" class="sort"
               [src]="this.filters[4] === 'desc' ? 'assets/img/down.svg' : 'assets/img/up.svg'"/><br>
          <select class="mt-1" (change)="filterSort(1, $event)">
            <option value="">All</option>
            <option *ngFor="let exp of yrsExp" value="{{exp.id}}">{{exp.name}}</option>
          </select>
        </th>
        <th scope="col">
          Past Gigs<br>
          <select class="mt-1" (change)="filterSort(2, $event)">
            <option value="">All</option>
            <option value="1">with</option>
            <option value="0">without</option>
          </select>
        </th>
        <th scope="col">
          Ongoing Gigs/Interview<br>
          <select class="mt-1" (change)="filterSort(3, $event)">
            <option value="">All</option>
            <option value="1">with</option>
            <option value="0">without</option>
            <option value="2">w/ interview</option>
          </select>
        </th>
        <th scope="col">
          Hours<br>
          <select class="mt-1" (change)="filterSort(5, $event)">
            <option value="">All</option>
            <option value="0">Part time</option>
            <option value="1">Full time</option>
          </select>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let talent of talents | async | paginate: {itemsPerPage: 10, currentPage: p}">
        <th scope="row" class="text-nowrap">
          <a class="mr-2" href="/profile/{{talent?.id}}" target="_blank">
            <img src="assets/img/eye.svg" tooltip="See Profile"></a>
          <a (click)="openModal(scheduleModal, talent.id)" *ngIf="talent.profile.ongoing_gigs.length === 0 &&
        (!talent.profile.interview || talent?.profile?.interview?.status > interviewStatus.CONFIRMED)">
            <img src="assets/img/mail.svg" tooltip="Invite for Interview"></a>
        </th>
        <td>{{talent.first_name}} {{talent.last_name}}</td>
        <td>
          <div *ngFor="let skill of talent.profile.skills"
               [ngClass]="{'font-weight-bold': skill.confirmed}">{{skill.skill_name}}</div>
        </td>
        <td>{{experience[talent.profile.yrs_exp]}}</td>
        <td>
          <div *ngFor="let pastGig of talent?.profile.past_gigs">
            {{pastGig.name}}
            <fa-icon [icon]="faMinusCircle" class="pointer" tooltip="Unlink Gig"
                     (click)="openUnlinkModal(talent.id, talent, pastGig)"></fa-icon>
          </div>
        </td>
        <td>
          <div *ngFor="let ongoingGig of talent?.profile.ongoing_gigs">
            {{ongoingGig.name}}
            <fa-icon [icon]="faMinusCircle" class="pointer" tooltip="Unlink Gig"
                     (click)="openUnlinkModal(talent.id, talent, ongoingGig)"></fa-icon>
          </div>
          <div *ngIf="talent.profile?.interview && talent.profile?.interview?.status < interviewStatus.DONE">
            Interview: {{talent.profile?.interview?.project_name}}</div>
        </td>
        <td>{{talent.profile?.hours == null ? '' : (talent.profile?.hours === 0 ? 'Part time' : 'Full time')}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</div>

<ng-template #scheduleModal>
  <app-schedule-modal [$candidateId]="candidateId" [$title]="'Send Invite'" [nested]="true"
                      (openModal2)="openModal2(gigModal)" (closeModal)="closeModal(1)"></app-schedule-modal>
</ng-template>

<ng-template #gigModal>
  <app-create-gig [$title]="'Create Gig'" [$action]="'Create'" [nested]="true"
                  (closeModal)="closeModal(2)"></app-create-gig>
</ng-template>
