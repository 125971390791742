<div class="card mb-3">
  <div class="card-header">
    Manage Quiz
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col" class="first-col"></th>
        <th scope="col" class="first-col">#</th>
        <th scope="col">Basic Questions</th>
        <th scope="col">Type</th>
        <th scope="col">Correct Answer</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let question of basicQuestions | async">
        <th scope="row">
          <a (click)="openModal(question)"><img src="assets/img/edit.svg" tooltip="Edit question"></a>
        </th>
        <th scope="row">{{question.id}}</th>
        <td>{{question.question}}</td>
        <td>{{type[question.type]}}</td>
        <td>{{(question.type === type['Yes or No']) ? ansBool[question.correct_answer]
            : ansMultiple[question.correct_answer]}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<div class="card">
  <div class="card-header">
    <div><strong>Modify/Enable Functional Questions</strong></div>
    <div class="mt-2">
      <select class="custom-select" [(ngModel)]="setId" (ngModelChange)="selectSet()">
        <option value="" disabled selected>Select</option>
        <option *ngFor="let set of sets" value="{{set.id}}">{{set.name}}</option>
      </select>
      <div class="custom-control custom-switch d-inline-block ml-5" *ngIf="setId">
        <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="toggle"
               (ngModelChange)="enable()">
        <label class="custom-control-label" for="customSwitch1">Enable</label>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col" class="first-col"></th>
        <th scope="col" class="first-col">#</th>
        <th scope="col" class="col-question">Functional Questions</th>
        <th scope="col">Type</th>
        <th scope="col">Correct Answer</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let question of functionalQuestions | async; let i=index">
        <th scope="row">
          <a (click)="openModal(question)"><img src="assets/img/edit.svg" tooltip="Edit question"></a>
        </th>
        <th scope="row">{{i + 1}}</th>
        <td>{{question.question}}</td>
        <td>{{type[question.type]}}</td>
        <td>{{(question.type === type['Yes or No']) ? ansBool[question.correct_answer]
            : ansMultiple[question.correct_answer]}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
