<div class="col-lg-6 pr-0 pl-0">
  <div class="card mb-3">
    <div class="card-body">
      <app-edit-password></app-edit-password>
    </div>
  </div>
</div>

<div class="col-lg-6 pr-0 pl-0">
  <div class="card">
    <div class="card-body">
      <p>Deleting account is permanent.</p>
      <button (click)="delete()" class="btn btn-danger">Delete Account</button>
    </div>
  </div>
</div>
