import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProjectsService } from '../../../core/state/projects/projects.service';
import { SkillsQuery } from '../../../core/state/skills/skills.query';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Skill } from '../../../core/state/skills/skill.model';
import { Project } from '../../../core/state/projects/project.model';
import { Observable } from 'rxjs';
import { UserQuery } from '../../../core/state/user/user.query';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CostCenter } from '../../../core/state/cost-centers/cost-center.model';
import { CostCentersQuery } from '../../../core/state/cost-centers/cost-centers.query';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-create-gig',
  templateUrl: './gig-modal.component.html',
  styleUrls: ['./gig-modal.component.scss']
})
export class GigModalComponent implements OnInit {
  @ViewChild('createBtn') createBtn: ElementRef;
  @Output() closeModal = new EventEmitter<number>();
  @Input() $title: string;
  @Input() $action: string;
  @Input() nested: boolean;
  form: FormGroup;
  skills: Observable<Skill[]>;
  projectToEdit: Project;
  title: string;
  action: string;
  costCenters: CostCenter[];

  constructor(public bsModalRef: BsModalRef,
              private costCentersQuery: CostCentersQuery,
              private fb: FormBuilder,
              private renderer: Renderer2,
              private skillsQuery: SkillsQuery,
              private projectsService: ProjectsService,
              private userQuery: UserQuery) { }

  ngOnInit(): void {
    if (this.nested) {
      this.action = this.$action;
      this.title = this.$title;
    }

    this.form = this.fb.group({
      id: [!this.projectToEdit ? null : this.projectToEdit.id],
      leadId: [this.userQuery.getValue().account.id],
      name: [!this.projectToEdit ? null : this.projectToEdit.name],
      position: [!this.projectToEdit ? null : this.projectToEdit.position],
      platform: [this.action === 'Create' ? null : this.projectToEdit.platform.map(platform => platform.id)],
      scope: [!this.projectToEdit ? null : this.projectToEdit.scope],
      budgetLow: [!this.projectToEdit ? null : this.projectToEdit.budget_low],
      budgetHigh: [!this.projectToEdit ? null : this.projectToEdit.budget_high],
      costCenterId: [!this.projectToEdit ? null : this.projectToEdit.cost_center_id],
      pmEmail: [!this.projectToEdit ? null : this.projectToEdit.pm_email]
    });

    this.skills = this.skillsQuery.selectAll({sortBy: 'name'});
    this.costCenters = this.costCentersQuery.getAll({filterBy: entity => entity.cost_center !== 'RTP'});
  }

  create(): void {
    this.renderer.setProperty(this.createBtn.nativeElement, 'disabled', 'true');
    const text = this.action === 'Save' ? 'Saving...' : 'Creating...';
    this.renderer.setProperty(this.createBtn.nativeElement, 'innerText', text);

    this.projectsService.create(this.form.value).subscribe(
      res => {
        this.bsModalRef.hide();
        this.close();
      });
  }

  close(): void {
    this.closeModal.emit(2);
  }

}
