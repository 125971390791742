import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ANS_BOOL, ANS_MULTIPLE, QUESTION_TYPE } from '../../../core/constants/Question';
import { QuestionsService } from '../../../core/state/questions/questions.service';
import { Question } from '../../../core/state/questions/question.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss']
})
export class EditQuestionComponent implements OnInit {
  @ViewChild('saveBtn') saveBtn: ElementRef;
  form: FormGroup;
  questionToEdit: Question;
  type = QUESTION_TYPE;
  ansBool = ANS_BOOL;
  ansMultiple = ANS_MULTIPLE;

  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private questionsService: QuestionsService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [this.questionToEdit.id],
      question: [this.questionToEdit.question],
      type: [this.questionToEdit.type],
      choices: this.fb.group({
        A: [this.questionToEdit?.choices?.A],
        B: [this.questionToEdit?.choices?.B],
        C: [this.questionToEdit?.choices?.C],
        D: [this.questionToEdit?.choices?.D]
      }),
      correctAnswer: [this.questionToEdit.correct_answer]
    });
  }

  get $form(): any { return this.form.controls; }

  save(): void {
    this.renderer.setProperty(this.saveBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.saveBtn.nativeElement, 'innerText', 'Saving...');

    this.questionsService.editQuestion(this.form.value).subscribe(
      res => {
        this.bsModalRef.hide();
      }
    );
  }

}
