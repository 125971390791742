import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../../core/services/profile.service';
import { User } from '../../core/state/user/user.model';
import { Experience } from '../../core/constants/Experience';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FreelancersQuery } from '../../core/state/freelancers/freelancers.query';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-profile-link',
  templateUrl: './profile-link.component.html',
  styleUrls: ['./profile-link.component.scss']
})
export class ProfileLinkComponent implements OnInit {
  id: string;
  user: User;
  resumeUrl: string;
  experience = Experience;
  
  constructor(private freelancersQuery: FreelancersQuery,
              private route: ActivatedRoute,
              private profileService: ProfileService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.user = this.freelancersQuery.getEntity(this.id);
    
    this.profileService.downloadCV(this.id).subscribe(url => {
      this.resumeUrl = url;
    });
  }

}
