import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserStore } from '../state/user/user.store';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftService {

  constructor(private http: HttpClient,
              private userStore: UserStore) { }

  connectMicrosoft(): any {
    const params = {
      code: localStorage.getItem('microsoft_token')
    };

    return this.http.post(`${environment.api}connect/microsoft`, params).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

  getFreeTime(date): any {
    return this.http.get(`${environment.api}free/${date}`);
  }

  disconnectMicrosoft(): any {
    return this.http.get(`${environment.api}disconnect/microsoft`).pipe(tap(
      (res: any) => {
        this.userStore.update({
          account: res.account
        });
      }));
  }

}
