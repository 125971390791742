import { Component, OnInit } from '@angular/core';
import { ContractsService } from '../../core/state/contracts/contracts.service';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-accept-offer',
  templateUrl: './accept-offer.component.html',
  styleUrls: ['./accept-offer.component.scss']
})
export class AcceptOfferComponent implements OnInit {
  sent: boolean;

  constructor(private contractsService: ContractsService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

      if (params.id && params.gig) {
        this.contractsService.acceptOffer(params.id, params.gig).subscribe(res => {
          this.sent = true;
        });
      }
    });
  }

}
