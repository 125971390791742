import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { User } from '../../core/state/user/user.model';
import { ScheduleModalComponent } from '../../shared/components/schedule-modal/schedule-modal.component';
import { InterviewService } from '../../core/services/interview.service';
import { INTERVIEW_STATUS } from '../../core/constants/InterviewStatus';
import { UserQuery } from '../../core/state/user/user.query';
import { DeclineModalComponent } from './decline-modal/decline-modal.component';
import { Helper } from '../../core/utils/helper';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-interview-schedule',
  templateUrl: './interview-schedule.component.html',
  styleUrls: ['./interview-schedule.component.scss']
})
export class InterviewScheduleComponent implements OnInit {
  @ViewChild('acceptBtn') acceptBtn: ElementRef;
  bsModalRef: BsModalRef;
  faCalendar = faCalendar;
  interviewStatus = INTERVIEW_STATUS;
  user: User;
  allowed: boolean;

  constructor(private modalService: BsModalService,
              private interviewService: InterviewService,
              private renderer: Renderer2,
              private router: Router,
              private userQuery: UserQuery) { }

  ngOnInit(): void {
    this.userQuery.select().subscribe($user => {
      this.user = $user.account;
      this.allowed = new Date() > new Date(this.user?.reapply_date) || this.user?.reapply_date == null;
    });
  }

  accept(): void {
    this.renderer.setProperty(this.acceptBtn.nativeElement, 'disabled', 'true');
    this.renderer.setProperty(this.acceptBtn.nativeElement, 'innerText', 'Accepting...');
    this.interviewService.accept(this.user.id).subscribe();
  }

  decline(): void {
    const initialState = {interviewId: this.user.profile.interview.id};
    this.bsModalRef = this.modalService.show(DeclineModalComponent, {initialState});
  }

  reapply(): void {
    Helper.isNextStep = true;
    this.router.navigate(['quiz', {userId: this.user.id}]);
  }

  openModal(): void {
    const initialState = {
      candidateId: this.user.id,
      title: 'Propose New Schedule'
    };
    this.bsModalRef = this.modalService.show(ScheduleModalComponent, {initialState});
  }

}
