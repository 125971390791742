import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { User } from './user.model';

export interface UserState {
  token: string;
  account: User;
}

export function createInitialState(): UserState {
  return {
    token: '',
    account: null
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'user'})
export class UserStore extends Store<UserState> {

  constructor() {
    super(createInitialState());
  }

}

