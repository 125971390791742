import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserQuery } from '../../core/state/user/user.query';
import { User } from '../../core/state/user/user.model';
import { HarvestService } from '../../core/services/harvest.service';
import { MicrosoftService } from '../../core/services/microsoft.service';
import { fieldStates, State } from '../../core/constants/FieldState';
import { CostCenter } from '../../core/state/cost-centers/cost-center.model';
import { CostCentersQuery } from '../../core/state/cost-centers/cost-centers.query';
import { CostCentersService } from '../../core/state/cost-centers/cost-centers.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  accessToken = '';
  scope = '';
  accountId = '';
  loading: boolean;
  faSpinner = faSpinner;
  user: User;
  fields: any;
  state = State;
  readonly = true;
  rtpApprover: CostCenter;
  form: FormGroup;

  constructor(private costCentersQuery: CostCentersQuery,
              private costCentersService: CostCentersService,
              private fb: FormBuilder,
              private harvestService: HarvestService,
              private microsoftService: MicrosoftService,
              private route: ActivatedRoute,
              private router: Router,
              private userQuery: UserQuery) {
    this.fields = fieldStates[this.userQuery.getValue().account.role];
  }

  ngOnInit(): void {
    this.userQuery.select().subscribe($user => {
      this.user = $user.account;
      console.log(this.user+" Users")
    });

    this.route.queryParams.subscribe(params => {
      this.accessToken = params.code;
      this.scope = params.scope;

      if (this.accessToken) {
        if (localStorage.getItem('connect') === 'microsoft') {
          localStorage.setItem('microsoft_token', this.accessToken);
        } else {
          localStorage.setItem('harvest_token', this.accessToken);
        }

        if (this.scope) {
          this.accountId = this.scope.split(':')[1];
          localStorage.setItem('account_id', this.accountId);
        }

        this.router.navigate([], {
          queryParams: {
            code: null,
            state: null,
            scope: null
          },
          queryParamsHandling: 'merge'
        });

        if (localStorage.getItem('connect') === 'microsoft') {

          console.log(this.connectMicrosoft())
        } else {
          console.log(this.connectHarvest())
        }
      }
    });

    this.form = this.fb.group({
      name: [''],
      email: ['']
    });

    this.costCentersQuery.selectAll(
      {filterBy: entity => entity.cost_center === 'RTP', limitTo: 1}).subscribe(data => {
      this.rtpApprover = data[0];
      this.form.patchValue({name: this.rtpApprover.bu_head});
      this.form.patchValue({email: this.rtpApprover.bu_head_email});
    });
  }

  connectAccounts(): void {
    this.loading = true;
    console.log("Connect Accounts")
    console.log(this.harvestService.connectAccounts().subscribe(res => { this.loading = false; })+ " Connect Account")
    
  }

  connectMicrosoft(): void {
    localStorage.setItem('connect', 'microsoft');
    if (localStorage.getItem('microsoft_token')) {
      this.microsoftService.connectMicrosoft().subscribe(
        res => {
          localStorage.removeItem('microsoft_token');
          localStorage.removeItem('connect');
        }, error => {
          localStorage.removeItem('microsoft_token');
          localStorage.removeItem('connect');
        });
    } else {
      window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
      client_id=${environment.microsoftClientID}
      &response_type=code
      &redirect_uri=${environment.appUrl}/settings
      &response_mode=query
      &scope=offline_access user.read calendars.readwrite
      &state=12345`, '_self');
    }
  }

  connectHarvest(): void {
    localStorage.setItem('connect', 'harvest');
    console.log("Conect Harvest")
    if (localStorage.getItem('harvest_token')) {
     console.log( this.harvestService.connectHarvest().subscribe(
        res => {
          localStorage.removeItem('harvest_token');
          localStorage.removeItem('account_id');
          localStorage.removeItem('connect');
        }, error => {
          localStorage.removeItem('harvest_token');
          localStorage.removeItem('account_id');
          localStorage.removeItem('connect');
        }))
    } else {
      window.open(`https://id.getharvest.com/oauth2/authorize?client_id=${environment.harvestClientID}&response_type=code&redirect_uri=${environment.appUrl}/settings`, '_self');
    }
  }

  disconnectMicrosoft(): void {
    this.microsoftService.disconnectMicrosoft().subscribe();
  }

  disconnectHarvest(): void {
    this.harvestService.disconnectHarvest().subscribe();
  }

  edit(): void {
    if (!this.readonly) {
      this.costCentersService.editRTPApprover(this.form.value).subscribe(
        res => { this.readonly = true; });
    } else {
      this.readonly = false;
    }
  }

}
