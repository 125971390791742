<div class="modal-header">
  <h5 class="modal-title">Delete Account</h5>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="deleteForm" (ngSubmit)="delete()">
  <div class="modal-body">
    Deleting account would delete all your records in our system including:
    <ul>
      <li>User Profile</li>
      <li>Uploaded CV</li>
      <li>Projects Handled</li>
      <li>Access to Project Resources</li>
    </ul>

    <div class="form-group">
      <label>Please type your password to confirm.</label>
      <div class="password">
        <input #passwordInput type="password" class="form-control" formControlName="password">
        <img src="assets/img/eye.svg" (click)="togglePassword()">
      </div>
      <small *ngIf="wrongPassword" tabindex="-1" class="form-text text-muted font-weight-bold">
        Your password is incorrect.
      </small>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
    <button type="submit" #deleteBtn class="btn btn-danger">Delete</button>
  </div>
</form>
