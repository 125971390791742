<div class="app-sidebar" [ngClass]="{'app-sidebar--open': sidebarOpen | async}">
  <div class="app-header__logo">
    <img src="assets/img/logo.png" height="30px">
  </div>
  <div class="app-sidebar-content">
    <div class="list-group list-group-flush w-100">
      <div *ngFor="let nav of navigations">
        <a [routerLink]="nav.route" [routerLinkActive]="['is-active']"
           class="list-group-item list-group-item-action" (click)="closeSidebar()"
           *ngIf="menus[nav.state] === state.VISIBLE">{{nav.text}}</a>
      </div>
    </div>
  </div>
</div>
