<form [formGroup]="form">
  <div class="card mb-3">
    <div class="card-header">
      Offer Confirmation
    </div>

    <div class="card-body">
      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Select Freelancer</label>
            <ng-select [items]="freelancers | async"
                       bindLabel="search_string"
                       bindValue="id"
                       formControlName="freelancer_id"
                       placeholder="Select Freelancer">
              <ng-template ng-label-tmp let-item="item">
                {{item.first_name}} {{item.last_name}} | {{item.username}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                {{item.first_name}} {{item.last_name}} | {{item.username}}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Select Gig</label>
            <ng-select [items]="projects | async"
                       bindLabel="name"
                       bindValue="id"
                       formControlName="project_id"
                       placeholder="Select Gig"></ng-select>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Rate</label>
            <input type="text" formControlName="rate" class="form-control" placeholder="Agreed Rate">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Payment</label>
            <select formControlName="payment_term" class="custom-select">
              <option [ngValue]="0">hourly</option>
              <option [ngValue]="1">fixed</option>
              <option [ngValue]="2">milestone</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Contract Start</label>
            <input type="text" placeholder="Select start date" class="form-control" formControlName="start_date"
                   bsDatepicker [bsConfig]="{showWeekNumbers: false}">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Contract End</label>
            <input type="text" placeholder="Select end date" class="form-control" formControlName="end_date"
                   bsDatepicker [bsConfig]="{showWeekNumbers: false}">
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card">
    <div class="card-header">
      Details
    </div>

    <div class="card-body">
      <div class="form-group">
        <label>SERVICES</label>
        <div formArrayName="services" *ngFor="let service of s.controls; let i = index;"
             class="d-flex align-items-center form-group">
          <span>{{i + 1}}.</span>
          <input type="text" [formControlName]="i" class="form-control flex-grow-1" placeholder="Service">
          <button type="button" class="btn btn-default delete-btn" (click)="i > 0 ? addService(i) : null">
            <img src="assets/img/delete.svg" *ngIf="i > 0">
          </button>
        </div>
        <button type="button" class="btn btn-success" (click)="addService()">Add Service</button>
      </div>

      <div class="form-group">
        <label>DELIVERABLES</label>
        <div formArrayName="deliverables" *ngFor="let del of d.controls; let i = index;"
             class="d-flex align-items-center form-group">
          <span>{{i + 1}}.</span>
          <input type="text" [formControlName]="i" class="form-control flex-grow-1" placeholder="Deliverable">
          <button type="button" class="btn btn-default delete-btn" (click)="i > 0 ? addDeliverable(i) : null">
            <img src="assets/img/delete.svg" *ngIf="i > 0">
          </button>
        </div>
        <button type="button" class="btn btn-success" (click)="addDeliverable()">Add Deliverable</button>
      </div>

      <div class="form-group">
        <label>ACTIVITIES</label>
        <div formArrayName="activities" *ngFor="let activity of a.controls; let i = index;">
          <div [formGroupName]="i">
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" formControlName="milestone" class="form-control"
                         placeholder="Activities/Milestones">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex">
                  <input type="text" formControlName="date" class="form-control flex-grow-1"
                         placeholder="Start and Completion">
                  <button type="button" class="btn btn-default delete-btn" (click)="i > 0 ? addActivity(i) : null">
                    <img src="assets/img/delete.svg" *ngIf="i > 0">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-success" (click)="addActivity()">Add Activity</button>
      </div>
    </div>

    <div class="d-block card-footer text-center">
      <button #sendBtn type="submit" class="btn btn-warning btn-lg send-btn" [disabled]="!form.valid" (click)="send()">
        Send Offer Confirmation
      </button>
    </div>
  </div>
</form>
