export const TIMESLOT = [
  {
    value: '9:00',
    text: '9:00am'
  },
  {
    value: '9:30',
    text: '9:30am'
  },
  {
    value: '10:00',
    text: '10:00am'
  },
  {
    value: '10:30',
    text: '10:30am'
  },
  {
    value: '11:00',
    text: '11:00am'
  },
  {
    value: '11:30',
    text: '11:30am'
  },
  {
    value: '12:00',
    text: '12:00nn'
  },
  {
    value: '12:30',
    text: '12:30pm'
  },
  {
    value: '13:00',
    text: '1:00pm'
  },
  {
    value: '13:30',
    text: '1:30pm'
  },
  {
    value: '14:00',
    text: '2:00pm'
  },
  {
    value: '14:30',
    text: '2:30pm'
  },
  {
    value: '15:00',
    text: '3:00pm'
  },
  {
    value: '15:30',
    text: '3:30pm'
  },
  {
    value: '16:00',
    text: '4:00pm'
  },
  {
    value: '16:30',
    text: '4:30pm'
  },
  {
    value: '17:00',
    text: '5:00pm'
  },
  {
    value: '17:30',
    text: '5:30pm'
  }
];
