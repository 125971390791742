<form [formGroup]="userForm" (ngSubmit)="register()">
  <div class="d-flex h-100 justify-content-center align-items-center min-vh-100">
    <div class="mx-auto app-login-box col-md-8">

      <div class="logo text-center mt-4">
        <img src="assets/img/logo.png" alt="logo">
      </div>

      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title">
              <h4 class="mt-2">
                <div>Welcome,</div>
                <span *ngIf="url === '/register'">Sign up as <span class="text-success">freelancer</span> below</span>
                <span *ngIf="url === '/cortex/signup'">Sign up as <span
                        class="text-success">CCAC user</span> below</span>
              </h4>
            </div>

            <!--First name and Last name-->
            <div class="form-group" *ngIf="url === '/cortex/signup'">
              <div class="input-group">
                <input type="text" formControlName="firstName" class="form-control" placeholder="First name">
                <input type="text" formControlName="lastName" class="form-control" placeholder="Last name">
              </div>
              <small *ngIf="f.firstName.errors || f.lastName.errors" tabindex="-1"
                     class="form-text text-muted font-weight-bold">
                First name and last name is required
              </small>
            </div>

            <!--Cortex User Role-->
            <div class="input-group form-group" *ngIf="url === '/cortex/signup'">
              <div class="input-group-prepend">
                <label class="input-group-text" for="roleSelect">Role</label>
              </div>
              <select class="custom-select" formControlName="role" id="roleSelect">
                <option *ngFor="let role of roles" [ngValue]="role.id">{{role.name}}</option>
              </select>
            </div>

            <div *ngIf="url === '/register'">
              <!--Full name-->
              <div class="form-group">
                <input type="text" formControlName="firstName" class="form-control"
                       placeholder="Full given name (include 2nd/3rd name etc. if applicable)">
              </div>

              <div class="form-group">
                <div class="input-group">
                  <input type="text" formControlName="middleName" class="form-control" placeholder="Middle name">
                  <input type="text" formControlName="lastName" class="form-control" placeholder="Last name">
                </div>
                <small *ngIf="f.firstName.errors || f.lastName.errors" tabindex="-1"
                       class="form-text text-muted font-weight-bold">
                  Please indicate FULL name
                </small>
              </div>

              <!--Mobile-->
              <div class="form-group">
                <input type="text" class="form-control" formControlName="mobile" placeholder="Mobile">
                <small *ngIf="f.mobile.errors" tabindex="-1" class="form-text text-muted font-weight-bold">
                  Mobile is required
                </small>
              </div>

              <!--Platform/Framework/Programming Language-->
              <div class="form-group">
                <ng-select [items]="skills | async"
                           bindLabel="name"
                           bindValue="id"
                           [multiple]="true"
                           class="custom"
                           formControlName="skills"
                           placeholder="Select Platform/Framework/Programming Language"></ng-select>
                <small *ngIf="f.skills.errors" tabindex="-1" class="form-text text-muted font-weight-bold">
                  Select at least one
                </small>
              </div>

              <!--Years of Experience-->
              <div class="input-group form-group">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="expSelect">Years of Experience</label>
                </div>
                <select class="custom-select" formControlName="yrsExp" id="expSelect">
                  <option *ngFor="let exp of yearsExp" [ngValue]="exp.id">{{exp.name}}</option>
                </select>
              </div>

              <!--Upload Resume-->
              <div class="form-group">
                <div class="input-group">
                  <div class="custom-file">
                    <input #fileInput type="file" class="custom-file-input" id="inputFile"
                           formControlName="cv" (change)="onFileChange($event)"
                           accept="application/pdf">
                    <label #customLabel class="custom-file-label" for="inputFile">Upload Resume</label>
                  </div>
                </div>
                <small *ngIf="f.cv.errors" tabindex="-1" class="form-text text-muted font-weight-bold">
                  Resume is required
                </small>
                <small *ngIf="!resumePdf" tabindex="-1" class="form-text text-muted font-weight-bold">
                  Accepts pdf files only
                </small>
              </div>
            </div>

            <!--Email-->
            <div class="form-group">
              <input type="email" email class="form-control" formControlName="username" placeholder="Email address">
              <small *ngIf="f.username.errors?.required" tabindex="-1" class="form-text text-muted font-weight-bold">
                Email is required
              </small>
              <small *ngIf="url === '/cortex/signup' && f.username.errors?.pattern" tabindex="-1"
                     class="form-text text-muted font-weight-bold">
                Only cortex | cbsi | cic | ccac emails are allowed
              </small>
              <small *ngIf="emailExist" tabindex="-1" class="form-text text-muted font-weight-bold">
                Email already exists
              </small>
              <small *ngIf="emailNotSent" tabindex="-1" class="form-text text-muted font-weight-bold">
                Unable to send email
              </small>
            </div>

            <!--Password-->
            <div class="form-group">
              <div class="password">
                <input type="password" placeholder="Password" class="form-control" formControlName="password"
                       minlength="8" maxlength="16" appPassword #passwordInput
                       (matchPattern)="onMatchPattern($event)">
                <img src="assets/img/eye.svg" (click)="togglePassword()">
              </div>
              <small *ngIf="f.password.errors?.minlength || !matchPattern" tabindex="-1"
                     class="form-text text-muted font-weight-bold">
                Minimum of 8 characters, at least one upper case, one lower case and a number
              </small>
              <small *ngIf="f.password.errors?.required" tabindex="-1" class="form-text text-muted font-weight-bold">
                Password is required
              </small>
            </div>
            <!--End Password-->

            <div class="form-group">
              <div class="custom-checkbox custom-control">
                <input type="checkbox" id="agreePolicy" class="custom-control-input" formControlName="agreePolicy">
                <label for="agreePolicy" class="custom-control-label">
                  I agree to the terms of the <a (click)="openPrivacyPolicy()" class="text-primary">Data Privacy
                  Policy</a>.
                </label>
              </div>
              <small *ngIf="f.agreePolicy.errors" tabindex="-1" class="form-text text-muted font-weight-bold">
                Should agree to the policy to proceed
              </small>
            </div>

            <!--OTP-->
            <app-otp [username]="username" [mobile]="mobile" (verified)="onVerify($event)"
                     [enable]="enable && matchPattern && resumePdf"></app-otp>

            <div class="divider"></div>
            <h6 class="mb-0">Already have an account?
              <a class="text-primary" routerLink="/login">Sign in</a>
            </h6>
          </div>

          <div class="modal-footer">
            <div class="float-right">
              <button #registerBtn type="submit" class="btn btn-lg btn-warning"
                      [disabled]="!userForm.valid || !matchPattern || !verifiedOtp || !resumePdf">
                Create Account
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
