<div class="app-page-title">
  <label>Choose RTP as basis for CSV</label>
  <div class="form-row">
    <div class="col-md-8">
      <div class="form-group">
        <ng-select [items]="rtps"
                   bindLabel="filename"
                   bindValue="id"
                   placeholder="Select RTP"
                   [(ngModel)]="rtp"
                   (change)="getInvoices()">
        </ng-select>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <button #generateBtn class="btn btn-warning mr-2" (click)="generate()">
          Generate CSV
        </button>
      </div>
    </div>
  </div>
</div>


<div class="card">
  <div class="table-responsive">
    <form [formGroup]="form">
      <table class="align-middle table table-hover">
        <thead>
        <tr>
          <th>Name</th>
          <th>GCASH</th>
          <th>Amount(-10%)</th>
          <th>Remarks</th>
        </tr>
        </thead>
        <tbody formArrayName="infos" *ngFor="let info of i.controls; let i = index;">
        <tr [formGroupName]="i">
          <td>
            <input readonly formControlName="name" class="w-100">
          </td>
          <td>
            <input readonly formControlName="gcash" class="w-100">
          </td>
          <td>&#8369;
            <input readonly formControlName="amount">
          </td>
          <td>
            <input type="text" formControlName="remarks" class="w-100">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

</div>
