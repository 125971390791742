export const ROLES = [
  {
    id: 1,
    name: 'HR'
  },
  {
    id: 2,
    name: 'Project Manager'
  },
  {
    id: 3,
    name: 'Finance'
  }
];

export enum ROLE {
  SUPER_ADMIN,
  HR,
  PM,
  FINANCE,
  DEVELOPER
}
