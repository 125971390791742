import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '../app/main/main.component';
import { RegisterComponent } from '../app/main/register/register.component';
import { ProfileComponent } from '../app/freelancer/profile/profile.component';
import { TalentPoolComponent } from '../app/admin/talent-pool/talent-pool.component';
import { InterviewPoolComponent } from '../app/admin/interview-pool/interview-pool.component';
import { CanActivateLoginGuard } from './guards/login.guard';
import { PaymentDetailsComponent } from '../app/freelancer/payment-details/payment-details.component';
import { AccountComponent } from '../app/freelancer/account/account.component';
import { ProfileLinkComponent } from '../app/admin/profile-link/profile-link.component';
import { AppResolver } from './app-resolver';
import { CanActivateAdminGuard } from './guards/admin.guard';
import { QuizComponent } from '../app/main/quiz/quiz.component';
import { ManageQuizComponent } from '../app/admin/manage-quiz/manage-quiz.component';
import { CanActivateQuizGuard } from './guards/quiz.guard';
import { GigPoolComponent } from '../app/admin/gig-pool/gig-pool.component';
import { GenerateContractComponent } from '../app/admin/generate-contract/generate-contract.component';
import { SettingsComponent } from '../app/admin/settings/settings.component';
import { SupportComponent } from '../app/freelancer/support/support.component';
import { GenerateCsvComponent } from '../app/admin/generate-csv/generate-csv.component';
import { CreateInvoiceComponent } from '../app/freelancer/create-invoice/create-invoice.component';
import { InvoicesComponent } from '../app/admin/invoices/invoices.component';
import { RtpComponent } from '../app/admin/rtp/rtp.component';
import { GcashComponent } from '../app/admin/gcash/gcash.component';
import { ContractsComponent } from '../app/admin/contracts/contracts.component';
import { CostCentersComponent } from '../app/admin/cost-centers/cost-centers.component';
import { CanActivateHrPmGuard } from './guards/hr-pm.guard';
import { CanActivateHrGuard } from './guards/hr.guard';
import { CanActivateFinanceGuard } from './guards/finance.guard';
import { SignContractComponent } from '../app/freelancer/sign-contract/sign-contract.component';
import { SignPageComponent } from '../app/admin/generate-contract/sign-page/sign-page.component';
import { ReceiptsComponent } from '../app/admin/receipts/receipts.component';
import { CanActivateHrFinanceGuard } from './guards/hr-finance.guard';
import { SignRtpComponent } from '../app/admin/rtp/sign-rtp/sign-rtp.component';
import { LoginComponent } from '../app/main/login/login.component';
import { ForgotPasswordComponent } from '../app/main/login/forgot-password/forgot-password.component';
import { CanActivateSuperAdminGuard } from './guards/super-admin.guard';
import { FreelancerDataComponent } from '../app/admin/freelancer-data/freelancer-data.component';
import { GenerateRtpComponent } from '../app/admin/generate-rtp/generate-rtp.component';
import { OfferGigComponent } from '../app/admin/offer-gig/offer-gig.component';
import { AcceptOfferComponent } from '../app/main/accept-offer/accept-offer.component';
import { MyBirFormsComponent } from '../app/freelancer/my-bir-forms/my-bir-forms.component';
import { ContractDetailsComponent } from '../app/admin/contract-details/contract-details.component';
import { MilestoneComponent } from '../app/admin/milestone/milestone.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [CanActivateLoginGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'payment',
        component: PaymentDetailsComponent
      },
      {
        path: 'account',
        component: AccountComponent
      },
      {
        path: 'contract',
        component: SignContractComponent
      },
      {
        path: 'invoice',
        component: CreateInvoiceComponent
      },
      {
        path: 'bir-forms',
        component: MyBirFormsComponent
      },
      {
        path: 'support',
        component: SupportComponent
      },
      {
        path: 'pool/talent',
        component: TalentPoolComponent,
        canActivate: [CanActivateHrPmGuard]
      },
      {
        path: 'pool/interview',
        component: InterviewPoolComponent,
        canActivate: [CanActivateHrPmGuard]
      },
      {
        path: 'pool/gig',
        component: GigPoolComponent,
        canActivate: [CanActivateHrPmGuard]
      },
      {
        path: 'manage/quiz',
        component: ManageQuizComponent,
        canActivate: [CanActivateHrGuard]
      },
      {
        path: 'offer/gig',
        component: OfferGigComponent,
        canActivate: [CanActivateHrGuard]
      },
      {
        path: 'generate/contract',
        component: GenerateContractComponent,
        canActivate: [CanActivateHrGuard]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [CanActivateAdminGuard]
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [CanActivateFinanceGuard]
      },
      {
        path: 'milestone',
        component: MilestoneComponent,
        canActivate: [CanActivateFinanceGuard]
      },
      {
        path: 'generate/rtp',
        component: GenerateRtpComponent,
        canActivate: [CanActivateFinanceGuard]
      },
      {
        path: 'rtp',
        component: RtpComponent,
        canActivate: [CanActivateFinanceGuard]
      },
      {
        path: 'generate/csv',
        component: GenerateCsvComponent,
        canActivate: [CanActivateFinanceGuard]
      },
      {
        path: 'gcash',
        component: GcashComponent,
        canActivate: [CanActivateFinanceGuard]
      },
      {
        path: 'contracts',
        component: ContractsComponent,
        canActivate: [CanActivateHrFinanceGuard]
      },
      {
        path: 'contract/details',
        component: ContractDetailsComponent,
        canActivate: [CanActivateFinanceGuard]
      },
      {
        path: 'cost-centers',
        component: CostCentersComponent,
        canActivate: [CanActivateHrFinanceGuard]
      },
      {
        path: 'receipts',
        component: ReceiptsComponent,
        canActivate: [CanActivateFinanceGuard]
      },
      {
        path: 'update/freelancer',
        component: FreelancerDataComponent,
        canActivate: [CanActivateHrGuard]
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      init: AppResolver
    }
  },
  {
    path: 'forgot/password',
    component: ForgotPasswordComponent
  },
  {
    path: 'cortex/signup',
    component: RegisterComponent,
    resolve: {
      init: AppResolver
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    resolve: {
      init: AppResolver
    }
  },
  {
    path: 'quiz',
    component: QuizComponent,
    canActivate: [CanActivateQuizGuard]
  },
  {
    path: 'profile/:id',
    component: ProfileLinkComponent,
    canActivate: [CanActivateAdminGuard]
  },
  {
    path: 'sign/contract',
    component: SignPageComponent
  },
  {
    path: 'sign/rtp',
    component: SignRtpComponent
  },
  {
    path: 'accept/offer',
    component: AcceptOfferComponent
  },
  {path: '**', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    CanActivateLoginGuard,
    CanActivateQuizGuard,
    CanActivateAdminGuard,
    CanActivateSuperAdminGuard,
    CanActivateHrPmGuard,
    CanActivateHrGuard,
    CanActivateFinanceGuard,
    CanActivateHrFinanceGuard,
    AppResolver
  ]
})
export class AppRoutingModule {}
