<div class="card">
  <div class="card-header">
    Cost Centers
  </div>

  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th scope="col"></th>
        <th>Cost Center</th>
        <th>Cost Center Name</th>
        <th>BU Head</th>
        <th>BU Head Email</th>
        <th>BU Position</th>
        <th>BU Mobile</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let center of costCenters | async">
        <th scope="row" class="text-nowrap">
          <a class="mr-1" (click)="edit(center)"><img src="assets/img/edit.svg" tooltip="Edit"></a>
          <a (click)="delete(center)"><img src="assets/img/trash.svg" tooltip="Delete"></a>
        </th>
        <td>{{center.cost_center}}</td>
        <td>{{center.cost_center_name}}</td>
        <td>{{center.bu_head}}</td>
        <td>{{center.bu_head_email}}</td>
        <td>{{center.bu_position}}</td>
        <td>{{center.bu_mobile}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer text-center">
    <button class="btn btn-success" (click)="create()">Create Cost Center</button>
  </div>
</div>
