<form [formGroup]="form" (ngSubmit)="add()">
  <div class="modal-header">
    <h5 class="modal-title">Add Skill for Hiring</h5>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <ng-select [items]="skills | async"
                 bindLabel="name"
                 bindValue="id"
                 placeholder="Search existing skills"></ng-select>
    </div>

    <div class="form-group">
      <label>Platform/Framework/Programming Language</label>
      <input type="text" class="form-control" formControlName="skill" placeholder="Type Skill to Add" required>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Cancel</button>
    <button type="submit" #addBtn class="btn btn-warning" [disabled]="!form.valid">Add</button>
  </div>
</form>
