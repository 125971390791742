<form [formGroup]="form" (ngSubmit)="verifyOtp()">
  <div class="form-group">
    <button #otpBtn type="button" class="btn btn-warning" [disabled]="!enable"
            (click)="(router.url === '/forgot/password') ? checkEmail() : sendOtp()">
      <span *ngIf="router.url === '/register'">Send One-time Pin via SMS</span>
      <span *ngIf="router.url !== '/register'">Send One-time Pin via Email</span>
    </button>
    <button type="button" #timerBtn class="btn btn-default d-none ml-2 font-weight-bold">
      {{timer | async}}s remaining
    </button>
  </div>

  <div class="input-group" *ngIf="showOtpInput">
    <input #otpInput type="text" class="form-control" placeholder="Type OTP" formControlName="otp"
           [ngClass]="{'otp-match' : otpMatch, 'otp-mismatch' : otpMismatch}">
    <div class="input-group-append">
      <button type="submit" class="btn btn-warning" [disabled]="loading">
        <fa-icon [icon]="faSpinner" [spin]="true" *ngIf="loading"></fa-icon>
        Verify
      </button>
    </div>
  </div>
</form>
