import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FilesService } from '../../core/state/files/files.service';
import { FilesQuery } from '../../core/state/files/files.query';
import { File } from '../../core/state/files/file.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Order } from '@datorama/akita';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-gcash',
  templateUrl: './gcash.component.html',
  styleUrls: ['./gcash.component.scss']
})
export class GcashComponent implements OnInit {
  files: Observable<File[]>;
  p = 1;

  constructor(private filesService: FilesService,
              private filesQuery: FilesQuery) { }

  ngOnInit(): void {
    this.files = this.filesQuery.selectAll({
      filterBy: entity => entity.type === 'gcash',
      sortBy: 'created_at', sortByOrder: Order.DESC
    });
  }

  download(id): void {
    this.filesService.download(id).subscribe(res => {
      window.open(res, '_self');
    });
  }

}
