import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserQuery } from '../../app/core/state/user/user.query';
import { ROLE } from '../../app/core/constants/Role';

@Injectable()
export class CanActivateHrFinanceGuard implements CanActivate {

  constructor(private userQuery: UserQuery,
              private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer: any) => {
      if (this.userQuery.getValue().account == null) {
        return this.router.navigate(['login', {redirectUrl: state.url}]);
      }

      const roles = [ROLE.SUPER_ADMIN, ROLE.HR, ROLE.FINANCE];
      const canActivate = roles.includes(this.userQuery.getValue().account.role);
      if (!canActivate) {
        return this.router.navigate(['']);
      }

      observer.next(canActivate);
      return observer.complete();
    });
  }
}
