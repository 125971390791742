import { Injectable } from '@angular/core';
import { FreelancersStore } from './freelancers.store';
import { UiService } from '../ui/ui.service';
import { UiQuery } from '../ui/ui.query';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FreelancersService {

  constructor(private freelancersStore: FreelancersStore,
              private http: HttpClient,
              private uiQuery: UiQuery,
              private uiService: UiService) { }

  getFirstPage(): any {
    return this.http.get(`${environment.api}freelancers`).pipe(tap(
      (res: any) => {
        this.freelancersStore.set(res.freelancers.data);
        this.uiService.saveNextPage(res.freelancers.next_page_url);
        this.uiService.saveLastPage(res.freelancers.total);

        if (res.freelancers.next_page_url) { this.getNextPage().subscribe(); }
      }));
  }

  getNextPage(): any {
    const pageParam = Number(this.uiQuery.getValue().nextPageUrl.split('=')[1]);
    const param: any = {page: pageParam};

    return this.http.get(`${environment.api}freelancers`, {params: param}).pipe(tap(
      (res: any) => {
        this.freelancersStore.add(res.freelancers.data);
        this.uiService.saveNextPage(res.freelancers.next_page_url);
        this.uiService.saveLastPage(res.freelancers.total);

        if (res.freelancers.next_page_url) { this.getNextPage().subscribe(); }
      }));
  }

  changeLastInvoice(id, numberParam): any {
    return this.http.get(`${environment.api}last/invoice/${id}/${numberParam}`).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }
  
  editPowerpayName(id, nameParam): any {
    return this.http.get(`${environment.api}powerpay/${id}/${nameParam}`).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

  editGcash(id, gcash): any {
    return this.http.get(`${environment.api}gcash/${id}/${gcash}`).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

  exportFreelancers(bool): any {
    return this.http.get(`${environment.api}export/freelancers/${bool}`).pipe(map(
      (res: any) => res.file));
  }

  updateFreelancerInfo(data): any {
    return this.http.post(`${environment.api}update/freelancer/info`, data).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

  updateFreelancerPaymentInfo(data): any {
    return this.http.post(`${environment.api}update/freelancer/payment`, data).pipe(tap(
      (res: any) => {
        this.freelancersStore.replace(res.freelancer.id, res.freelancer);
      }));
  }

}
