import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReceiptsQuery } from '../../core/state/receipts/receipts.query';
import { ReceiptsService } from '../../core/state/receipts/receipts.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Receipt } from '../../core/state/receipts/receipt.model';
import { INVOICE_PERIOD } from '../../core/constants/InvoicePeriod';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UploadBirComponent } from './upload-bir/upload-bir.component';
import { RtpsQuery } from '../../core/state/rtps/rtps.query';
import { Order } from '@datorama/akita';
import { Rtp } from '../../core/state/rtps/rtp.model';
import { InvoicesQuery } from '../../core/state/invoices/invoices.query';
import { clone } from 'lodash';
import { Invoice } from '../../core/state/invoices/invoice.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss']
})
export class ReceiptsComponent implements OnInit {
  @ViewChild('submitBtn') submitBtn: ElementRef;
  bsModalRef: BsModalRef;
  receipts: Observable<Receipt[]>;
  p = 1;
  years = [new Date().getFullYear() - 1, new Date().getFullYear()];
  months = INVOICE_PERIOD;
  year = this.years[1];
  pastMonth = new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;
  month = this.months[this.pastMonth].text;
  invoices: Invoice[] = [];

  constructor(private invoicesQuery: InvoicesQuery,
              private modalService: BsModalService,
              private receiptsQuery: ReceiptsQuery,
              private receiptsService: ReceiptsService,
              private rtpsQuery: RtpsQuery) { }

  ngOnInit(): void {
    this.receipts = this.receiptsQuery.selectAll();
  }

  getReceipts(): void {
    this.invoices = [];
    this.receiptsService.get(`${this.month} ${this.year}`).subscribe(res => {

      // Get latest RTP
      const rtp: Rtp[] = this.rtpsQuery.getAll({
        filterBy: entity => entity.period === `${this.month} ${this.year}`,
        sortBy: 'created_at',
        sortByOrder: Order.DESC,
        limitTo: 1
      });

      // Get Invoices in RTP
      if (rtp.length > 0) {
        const invoiceIds = JSON.parse(rtp[0].invoice_ids);
        invoiceIds.forEach(id => {
          const invoice = clone(this.invoicesQuery.getEntity(id));
          const receipt = this.receiptsQuery.getAll({
            filterBy: entity => entity.period === `${this.month} ${this.year}` && entity.freelancer === invoice.freelancer,
            sortBy: 'created_at',
            sortByOrder: Order.DESC,
            limitTo: 1
          });

          invoice.$acknowledged = receipt.length > 0;
          invoice.$receiptId = receipt.length > 0 ? receipt[0].id : null;
          this.invoices.push(invoice);
        });
      }
    });
  }

  download(id): void {
    this.receiptsService.download(id).subscribe(res => {
      window.open(res, '_self');
    });
  }

  openModal(): void {
    this.bsModalRef = this.modalService.show(UploadBirComponent);
  }

}
