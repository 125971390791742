export const YEARS_OF_EXPERIENCE = [
  {
    id: 0,
    name: 'Less than 1 year'
  },
  {
    id: 1,
    name: '1 to 3 years'
  },
  {
    id: 2,
    name: '4 to 7 years'
  },
  {
    id: 3,
    name: 'More than 7 years'
  }
];

export enum Experience {
  'Less than 1 year',
  '1 to 3 years',
  '4 to 7 years',
  'More than 7 years'
}
