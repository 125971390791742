<div class="card invoice">
  <div class="card-body">
    <header class="d-flex flex-row justify-content-between">
      <div class="user-details">
        <div>{{details.name}}</div>
        <div>{{details.contact}}</div>
        <div>{{details.email}}</div>
        <div>TIN No. {{details.tin}}</div>
      </div>
      <div class="title align-self-end">
        <h1>INVOICE</h1>
      </div>
    </header>

    <hr>


    <table class="table table-borderless">
      <tr>
        <td rowspan="2" class="font-weight-bold">Bill To:</td>
        <td rowspan="2">
          <div>CONCEPCION-CARRIER AIRCONDITIONING COMPANY</div>
          <div>Km20 East Service Road, South Superhighway Alabang, Muntinlupa City, Philippines 1700</div>
        </td>
        <td>Invoice No:</td>
        <td>{{details.number}}</td>
      </tr>
      <tr>
        <td>Date:</td>
        <td>{{today | date: 'mediumDate'}}</td>
      </tr>
    </table>


    <table class="table mt-3">
      <thead>
      <tr>
        <th scope="col" class="description">Description</th>
        <th scope="col">Quantity</th>
        <th scope="col" class="text-right">Rate</th>
        <th scope="col" class="text-right">Amount</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{details.description}} - {{details.monthName}} {{details.year}}</td>
        <td>{{details.totalHours | number: '1.0-2'}}</td>
        <td class="text-right">&#8369;{{details.rate | number: '1.2-2'}}</td>
        <td class="text-right">&#8369;{{details.amount | number: '1.2-2'}}</td>
      </tr>
      <tr class="total">
        <td colspan="2"></td>
        <td class="text-right">Total</td>
        <td class="text-right">
          &#8369;{{details.amount | number: '1.2-2'}}</td>
      </tr>
      <tr class="balance">
        <td colspan="3" class="text-right font-weight-bold">Balance Due</td>
        <td class="text-right font-weight-bold">&#8369;{{details.amount | number: '1.2-2'}}</td>
      </tr>
      </tbody>
    </table>

  </div>
</div>
