import { Component, Input, OnInit } from '@angular/core';
import { PrintInvoice } from '../../../shared/models/print-invoice.model';

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.scss']
})
export class PrintInvoiceComponent implements OnInit {
  @Input() details: PrintInvoice;
  today = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
