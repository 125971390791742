<div class="app-page-title">
  <div class="col-md-7 p-0 form-group">
    <label>Select Freelancer to Pay</label>
    <ng-select [items]="freelancers | async"
               bindLabel="search_string"
               bindValue="id"
               [(ngModel)]="id"
               placeholder="Select"
               (change)="showContract()">
      <ng-template ng-label-tmp let-item="item">
        {{item.first_name}} {{item.last_name}} | {{item.username}}
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        {{item.first_name}} {{item.last_name}} | {{item.username}}
      </ng-template>
    </ng-select>
  </div>

  <p *ngIf="freelancer">
    Milestone Project: <strong>{{freelancer.profile?.last_contract?.milestone ?
      freelancer.profile?.last_contract.project_name : 'none'}}</strong>
  </p>

  <div *ngIf="milestones">
    <div class="col-md-7 p-0 form-group d-inline-block">
      <label>Choose Milestone</label>
      <select class="custom-select" [(ngModel)]="milestone">
        <option *ngFor="let m of milestones;let i=index" [ngValue]="i">
          {{m.milestone}} - Php{{m.due}}
        </option>
      </select>
    </div>

    <div class="form-group d-inline-block">
      <button class="btn btn-success ml-2" (click)="add()">Add</button>
    </div>
  </div>
</div>

<div class="card">
  <div class="table-responsive">
    <table class="align-middle table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th>Freelancer</th>
        <th>Milestone</th>
        <th>Due</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let payee of payees; let i=index">
        <td>{{payee.name}}</td>
        <td>{{payee.milestoneDesc}}</td>
        <td>Php {{payee.due}}</td>
        <td>
          <button type="button" class="btn btn-default p-0" (click)="delete(i)">
            <img src="assets/img/delete.svg">
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-block card-footer">
    <button type="submit" #generateBtn class="btn btn-warning btn-lg" [disabled]="payees.length < 1"
            (click)="generate()">
      Generate Invoice(s) and RTP
    </button>
  </div>
</div>
